import { useQuery } from '@tanstack/react-query'
import { useChat, useCogfy, useWorkspaceSlug } from '../../hooks'
import { useParams } from 'react-router-dom'
import { UUID } from '@indigohive/cogfy-types'
import { ScheduledMessage, ScheduledMessagesPageNavBar } from './components'
import { Container, Skeleton } from '../../components'
import { useTranslation } from 'react-i18next'

export function ScheduledMessagesPage () {
  const cogfy = useCogfy()
  const params = useParams<{ chatId: UUID }>()
  const workspace = useWorkspaceSlug()

  const { t } = useTranslation()
  const getScheduledMessages = useQuery({
    queryKey: ['getScheduledMessages'],
    queryFn: async ({ signal }) => {
      const result = await cogfy.getScheduledMessages({ chatId: params.chatId! }, { signal })
      return result
    }
  })
  const getChat = useChat(params.chatId!)

  return (
    <>
      <ScheduledMessagesPageNavBar chat={getChat.data} workspace={workspace} loading={getChat.isLoading} />
      <Container maxWidth="md" className="py-4 flex flex-col gap-4">
        {getScheduledMessages.data?.data.map(message => (
          <ScheduledMessage
            key={message.id}
            message={message}
            collectionId={getChat.data?.collectionId}
            onDelete={() => { getScheduledMessages.refetch() }}
          />
        ))}
        {getScheduledMessages.isLoading && new Array(5).fill(0).map((_, index) => <Skeleton key={index} className="w-full h-28" />)}
        {getScheduledMessages.isError && <span className="text-center">{t('common:Unexpected error')}</span>}
        {getScheduledMessages.data?.data.length === 0 && <span className="text-center">{t('scheduledMessagesPage:No scheduled messages found')}</span>}
      </Container>
    </>
  )
}
