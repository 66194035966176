import { v4 as uuid } from 'uuid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, optionChipColorNames, OverlayContent, Toggle } from '../../../../components'
import { FieldConfigurationsProps } from '../../FieldConfigurations'
import { useSelectedFieldToUpdate } from '../../../../lib'
import { SelectField, UUID } from '@indigohive/cogfy-types'
import { PlusIcon } from 'lucide-react'
import { incrementSuffix } from '../../../../helpers'
import { EditOptionMenu, OptionsList } from './subcomponents'

export type SelectOption = {
  id: UUID
  label: string
  color: string
  order: number
}

export function SelectConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props

  const { t } = useTranslation()
  const selectedField = useSelectedFieldToUpdate(state) as SelectField
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [options, setOptions] = useState<SelectOption[]>(selectedField?.data?.select?.options ?? [])
  const [optionToEdit, setOptionToEdit] = useState<SelectOption | null>(null)
  const multiple = Boolean(selectedField?.data?.select?.multiple ?? false)

  useEffect(() => {
    setOptions(selectedField.data?.select?.options ?? [])
  }, [selectedField.data])

  return (
    <>
      <Toggle
        color="primary"
        label={t('selectConfig:Multiple options')}
        checked={multiple}
        onChange={({ target }) => {
          controller.onUpdateFieldData(selectedField.id, { select: { multiple: target.checked, options } })
        }}
      />
      <div className="divider h-1 my-2" />
      <div className="flex">
        <span className="font-bold grow text-sm pb-2 px-2">
          {t('selectConfig:Options')}
        </span>
        <Button
          size="xs"
          onClick={() => {
            const label = incrementSuffix(t('selectConfig:Option'), options.map(option => option.label))
            const color = optionChipColorNames[Math.floor(Math.random() * Object.keys(optionChipColorNames).length)]
            const newOption = { id: uuid() as UUID, label, color, order: options.length }
            controller.onUpdateFieldData(selectedField.id, { select: { options: [...options, newOption], multiple } })
          }}
        >
          <PlusIcon size={16} />
          {t('Add')}
        </Button>
      </div>
      <OptionsList
        options={options}
        onDeleteClick={optionId => {
          const newOptions = options.filter(({ id }) => id !== optionId)
          controller.onUpdateFieldData(selectedField.id, { select: { options: newOptions, multiple } })
        }}
        onDrop={updatedOptions => {
          setOptions(updatedOptions)
          controller.onUpdateFieldData(selectedField.id, { select: { options: updatedOptions, multiple } })
        }}
        onEditClick={(ref, option) => {
          if (ref && option) {
            setAnchorEl(ref)
            setOptionToEdit(option)
          }
        }}
      />
      <OverlayContent
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => {
          let labelChanged = false
          const newOptions = options.map(option => {
            if (option.id === optionToEdit?.id && option.label !== optionToEdit?.label) {
              labelChanged = true
              return { ...option, label: optionToEdit.label }
            }
            return option
          })

          if (labelChanged) {
            controller.onUpdateFieldData(selectedField.id, { select: { options: newOptions, multiple } })
          }

          setAnchorEl(null)
        }}
      >
        <EditOptionMenu
          option={optionToEdit}
          onColorClick={color => {
            const newOptions = options.map(option => {
              if (option.id === optionToEdit?.id) {
                setOptionToEdit({ ...option, color })
                return { ...option, color }
              }
              return option
            })
            controller.onUpdateFieldData(selectedField.id, { select: { options: newOptions, multiple } })
          }}
          onInputNameBlur={() => {
            const newOptions = options.map(option => {
              if (option.id === optionToEdit?.id) {
                return { ...option, label: optionToEdit?.label }
              }
              return option
            })
            controller.onUpdateFieldData(selectedField.id, { select: { options: newOptions, multiple } })
          }}
          onInputNameChange={value => {
            if (optionToEdit) {
              setOptionToEdit(() => ({ ...optionToEdit, label: value }))
            }
          }}
          onInputNameKeyDown={key => {
            if (key === 'Enter') {
              const newOptions = options.map(option => {
                if (option.id === optionToEdit?.id) {
                  return { ...option, label: optionToEdit?.label }
                }
                return option
              })
              controller.onUpdateFieldData(selectedField.id, { select: { options: newOptions, multiple } })
              setAnchorEl(null)
            }
          }}
        />
      </OverlayContent>
    </>
  )
}
