import { ChatMessageContentType, GetChatMessageFilesResultData } from '@indigohive/cogfy-types'
import { ClassValue } from 'clsx'
import { createElement } from 'react'
import { WhatsAppContentAudio } from './WhatsAppContentAudio'
import { WhatsAppContentContacts } from './WhatsAppContentContacts'
import { WhatsAppContentDocument } from './WhatsAppContentDocument'
import { WhatsAppContentImage } from './WhatsAppContentImage'
import { WhatsAppContentLocation } from './WhatsAppContentLocation'
import { WhatsAppContentText } from './WhatsAppContentText'
import { WhatsAppcontentInteractive } from './WhatsAppContentInteractive'
import { WhatsAppContentTemplate } from './WhatsAppContentTemplate'

type WhatsAppContentComponentType = typeof WhatsAppContentText

const WHATSAPP_CONTENT_BY_TYPE: Partial<Record<ChatMessageContentType, WhatsAppContentComponentType>> = {
  audio: WhatsAppContentAudio,
  contacts: WhatsAppContentContacts,
  document: WhatsAppContentDocument,
  image: WhatsAppContentImage,
  interactive: WhatsAppcontentInteractive,
  location: WhatsAppContentLocation,
  template: WhatsAppContentTemplate,
  text: WhatsAppContentText
}

export type WhatsAppContentProps = {
  className?: ClassValue
  content: string | null
  contentType: ChatMessageContentType | null
  contentData: any
  files: GetChatMessageFilesResultData[]
  fromAuthenticatedUser: boolean
}

export function WhatsAppContent (props: WhatsAppContentProps) {
  const { contentType } = props

  const whatsAppContentComponent = contentType
    ? WHATSAPP_CONTENT_BY_TYPE[contentType]
    : null

  return (
    <>
      {whatsAppContentComponent && createElement(whatsAppContentComponent, props)}
    </>
  )
}
