import { useState } from 'react'
import { ChatMiddlewareDataFormProps } from '../../ChatMiddlewareDataFormProps'
import { useTranslation } from 'react-i18next'
import { Select } from '../../../Select'
import { PlusIcon, TrashIcon } from 'lucide-react'
import { Button } from '../../../Button'
import { FieldType } from '@indigohive/cogfy-types'

const allowedFields: FieldType[] = ['boolean', 'date', 'file', 'number', 'select', 'text']

export function ToolFormUpdateCogfyRecord (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props

  const [toolFields, setToolFields] = useState<{ id: string, required?: boolean }[]>(data?.method?.config?.fields ?? [])
  const { t } = useTranslation()

  const fields = props.fields?.filter(field => allowedFields.includes(field.type)) ?? []

  return (
    <div className="my-2">
      <div className="flex items-center pb-1">
        <div className="grow text-sm">
          {t('Fields')}
        </div>
        <Button
          ghost
          square
          size="sm"
          disabled={fields.length === toolFields.length}
          onClick={() => {
            const field = fields.find(field => !toolFields.some(f => f.id === field.id))
            if (field) {
              setToolFields([...toolFields, { id: field.id }])
              onChange({ ...data, method: { ...data.method, config: { fields: [...toolFields, { id: field.id }] } } })
            }
          }}
        >
          <PlusIcon size={16} />
        </Button>
      </div>

      {toolFields.length === 0 && (
        <div className="text-sm opacity-40">
          {t('editFieldDrawer:No fields set')}
        </div>
      )}

      <div className="flex flex-col gap-2">
        {toolFields.map((field, index) => (
          <div
            key={`${field.id}-${index}`}
            className="flex gap-2"
          >
            <Select
              size="sm"
              value={field.id}
              options={[
                { label: t('Select a field'), value: '', disabled: true },
                ...fields.map(field => ({ label: field.name, value: field.id, disabled: toolFields.some(f => f.id === field.id) }))
              ]}
              onChange={event => {
                const newToolFields = [...toolFields]
                newToolFields[index] = { id: event.target.value }
                setToolFields(newToolFields)
                onChange({ ...data, method: { ...data.method, config: { fields: newToolFields } } })
              }}
            />
            <div className="flex gap-1">
              <Select
                size="sm"
                value={field.required ? 'required' : 'optional'}
                disabled={!field.id}
                options={[
                  { label: t('Optional'), value: 'optional' },
                  { label: t('Required'), value: 'required' }
                ]}
                onChange={event => {
                  const newToolFields = [...toolFields]
                  newToolFields[index] = { ...newToolFields[index], required: event.target.value === 'required' }
                  setToolFields(newToolFields)
                  onChange({ ...data, method: { ...data.method, config: { fields: newToolFields } } })
                }}
              />
              <Button
                type="button"
                square
                ghost
                size="sm"
                onClick={() => {
                  const newToolFields = [...toolFields]
                  newToolFields.splice(index, 1)
                  setToolFields(newToolFields)
                  onChange({ ...data, method: { ...data.method, config: { fields: newToolFields } } })
                }}
              >
                <TrashIcon size={16} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
