import { UUID } from '@indigohive/cogfy-types'
import { ClipboardIcon } from 'lucide-react'
import { useState } from 'react'

export type FieldIdLabelProps = {
  fieldId: UUID
}

export function FieldIdLabel (props: FieldIdLabelProps) {
  const { fieldId } = props

  const [recentlyCopiedFieldId, setRecentlyCopiedFieldId] = useState(false)

  const handleCopyFieldId = () => {
    setRecentlyCopiedFieldId(true)

    navigator.clipboard.writeText(fieldId)

    setTimeout(() => {
      setRecentlyCopiedFieldId(false)
    }, 2000)
  }

  return (
    <span className="flex items-center text-xs">
      <span className="opacity-70">
        Field Id: {fieldId}
      </span>
      <div
        className="tooltip"
        data-tip={recentlyCopiedFieldId ? 'Copied!' : 'Copy field id'}
      >
        <button
          className="btn btn-circle btn-ghost btn-xs ml-1 opacity-70"
          onClick={handleCopyFieldId}
        >
          <ClipboardIcon size={12} />
        </button>
      </div>
    </span>
  )
}
