import { GetRecordByIdResult } from '@indigohive/cogfy-types/endpoints/getRecordById'
import { GetCollectionFieldsResultData } from '@indigohive/cogfy-types/endpoints/getCollectionFields'
import { BooleanRecordProperty, UpdateRecordPropertiesOperationData, UUID } from '@indigohive/cogfy-types'
import { Checkbox } from '../../../components'

export type BooleanRecordPropertyInputProps = {
  collectionId: UUID
  field: Extract<GetCollectionFieldsResultData, { type: 'boolean' }>
  record: GetRecordByIdResult
  onUpdateRecordProperties?: (data: UpdateRecordPropertiesOperationData) => void
  canEdit?: boolean
}

export function BooleanRecordPropertyInput (props: BooleanRecordPropertyInputProps) {
  const { collectionId, field, record, canEdit } = props

  const property = record.properties[field.id] as BooleanRecordProperty | undefined

  return (
    <Checkbox
      size="sm"
      color="info"
      disabled={!canEdit}
      checked={property?.boolean?.value ?? false}
      onChange={event => {
        props.onUpdateRecordProperties?.({
          recordId: record.id,
          collectionId,
          properties: {
            [field.id]: {
              type: 'boolean',
              boolean: {
                value: event.target.checked
              }
            }
          }
        })
      }}
    />
  )
}
