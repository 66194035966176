import { useQuery } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'

export function useWorkspacesList () {
  const cogfy = useCogfy()

  const getWorkspacesList = useQuery({
    queryKey: ['getWorkspacesList'],
    queryFn: ({ signal }) => cogfy.getWorkspaces({ signal })
  })

  return getWorkspacesList
}
