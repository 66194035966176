import { FieldCellProps } from '../FieldCell'
import { Button } from '../../../components'
import { RefreshCw } from 'lucide-react'
import { useCogfy } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

export function StaleIndicator (props: FieldCellProps) {
  const { field, record } = props

  const cogfy = useCogfy()
  const { t } = useTranslation()

  const calculateRecord = useMutation({
    mutationFn: () => cogfy.calculateRecords({ fieldId: field.id, recordIds: [record.id] })
  })

  return (
    <div className="absolute right-0">
      <div className="tooltip tooltip-right group m-1" data-tip={t('Stale cell')}>
        <Button
          className="hidden group-hover:flex"
          square
          size="xs"
          color="warning"
          onClick={event => {
            calculateRecord.mutate()
            event.stopPropagation()
          }}
        >
          <RefreshCw size={16} />
        </Button>
        <div className="w-2 h-2 bg-warning rounded-full group-hover:hidden" />
      </div>
    </div>
  )
}
