import type { UUID } from '@indigohive/cogfy-types'
import { CreateFolderCommand } from '@indigohive/cogfy-types/endpoints/createFolder'
import { MoveCollectionCommand } from '@indigohive/cogfy-types/endpoints/moveCollection'
import { useMutation } from '@tanstack/react-query'
import { FolderIcon, PlusIcon, TableIcon } from 'lucide-react'
import { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCogfy, usePermissions, useToasts } from '../../../../hooks'
import { TreeView } from '../../../TreeView'
import { CollectionContextMenu } from '../../../CollectionContextMenu'
import { Input } from '../../../Input'
import { ConfirmDeleteDialog } from '../../../ConfirmDeleteDialog'
import { Button } from '../../../Button'
import { HoverPosition } from '../../../TreeView/types/HoverPosition'
import { OverlayContent } from '../../../OverlayContent'
import { GetCollectionsTreeResultData } from '@indigohive/cogfy-types/endpoints/getCollectionsTree'
import { UserEventCommand } from '@indigohive/cogfy-types/endpoints/userEvent'
import { DrawerCreateCollectionMenu } from '../DrawerCreateCollectionMenu'

export type DrawerCollectionsMenuProps = {
  workspace: string
  collections: GetCollectionsTreeResultData[]
  isLoading?: boolean
  searchCollectionName?: string
  onCollectionsChange?: () => void
}

type CollectionTreeItemData = {
  id: string
  collection: GetCollectionsTreeResultData
  label?: ReactNode
  icon?: ReactNode
  children?: CollectionTreeItemData[]
  canDropOver?: boolean
}

type CollectionContextMenuData = {
  collection: GetCollectionsTreeResultData
  click: { x: number, y: number }
  element: Element
}

type RenameCollectionData = {
  collection: GetCollectionsTreeResultData
  element: Element
}

function map (workspace: string, collections: GetCollectionsTreeResultData[]): CollectionTreeItemData[] {
  return collections.map(collection => ({
    id: collection.id,
    label: collection.name,
    icon: collection.type === 'database'
      ? <TableIcon size={16} />
      : <FolderIcon size={16} />,
    collection,
    children: collection.type === 'database'
      ? undefined
      : map(workspace, collection.children ?? []),
    to: `/${workspace}/${collection.id}`,
    canDropOver: collection.type === 'folder'
  }))
}

export function DrawerCollectionsMenu (props: DrawerCollectionsMenuProps) {
  const { workspace, collections } = props
  const cogfy = useCogfy()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const permissions = usePermissions()
  const toasts = useToasts()
  const canCreateCollection = permissions.isAdmin
  const [collectionContextMenu, setCollectionContextMenu] = useState<CollectionContextMenuData | null>(null)
  const [collectionToRename, setCollectionToRename] = useState<RenameCollectionData | null>(null)
  const [collectionToDelete, setCollectionToDelete] = useState<GetCollectionsTreeResultData | null>(null)
  const [createCollectionMenuEl, setCreateCollectionMenuEl] = useState<HTMLElement | null>(null)

  const moveCollection = useMutation({
    mutationFn: async (data: MoveCollectionCommand) => { await cogfy.moveCollection(data) },
    onSuccess: () => { props.onCollectionsChange?.() }
  })
  const registerCollectionSelected = useMutation({
    mutationFn: (data: UserEventCommand) => cogfy.userEvent(data)
  })
  const renameCollection = useMutation({
    mutationFn: async (data: { id: UUID, name: string }) => {
      await cogfy.transactions.create(
        { operations: [{ type: 'rename_collection', data: { collectionId: data.id, name: data.name || null } }] }
      )
    }
  })
  const createFolder = useMutation({
    mutationFn: (data: CreateFolderCommand) => cogfy.createFolder(data),
    onSuccess: (result) => {
      props.onCollectionsChange?.()
      navigate(`/${workspace}/${result.id}`)
    }
  })
  const deleteCollection = useMutation({
    mutationFn: async (collectionId: UUID) => {
      await cogfy.transactions.create({ operations: [{ type: 'soft_delete_collection', data: { collectionId } }] })
      setCollectionToDelete(null)
    },
    onError: () => toasts.error(t('Failed to delete collection'))
  })
  const duplicateCollection = useMutation({
    mutationFn: async (collectionId: UUID) => {
      await cogfy.duplicateCollection({ collectionId })
    }
  })

  const handleDrop = (drag: CollectionTreeItemData, drop: CollectionTreeItemData, hoverPosition: HoverPosition) => {
    if (hoverPosition === 'top') {
      moveCollection.mutate({
        collectionId: drag.collection.id,
        beforeId: drop.collection.id
      })
    } else if (hoverPosition === 'middle') {
      moveCollection.mutate({
        collectionId: drag.collection.id,
        parentId: drop.collection.id
      })
    } else if (hoverPosition === 'bottom') {
      moveCollection.mutate({
        collectionId: drag.collection.id,
        afterId: drop.collection.id
      })
    }
  }

  const treeData = useMemo(
    () => map(workspace, filterCollectionsByNameRecursive(collections, props.searchCollectionName)),
    [collections, props.searchCollectionName]
  )

  return (
    <div>
      <div className="flex items-center selection:mb-[-8px]">
        <span className="grow font-semibold">
          {t('Collections')}
        </span>
        {canCreateCollection && (
          <div>
            <Button
              ghost
              square
              size="sm"
              onClick={event => setCreateCollectionMenuEl(event.currentTarget)}
            >
              <PlusIcon size={20} />
            </Button>
            <OverlayContent
              open={Boolean(createCollectionMenuEl)}
              anchorEl={createCollectionMenuEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={() => setCreateCollectionMenuEl(null)}
            >
              <DrawerCreateCollectionMenu
                workspace={workspace}
                onCreateCollectionClick={() => {
                  setCreateCollectionMenuEl(null)
                }}
                onCreateFolderClick={() => {
                  setCreateCollectionMenuEl(null)
                  createFolder.mutate({ parentId: null })
                }}
              />
            </OverlayContent>
          </div>
        )}
      </div>

      {props.isLoading && (
        <ul className="menu px-0">
          {new Array(5).fill(0).map((_, index) => (
            <li key={index}>
              <a className="px-2">
                <div className="skeleton h-2 w-2" />
                <div className="skeleton h-2 w-40" />
              </a>
            </li>
          ))}
        </ul>
      )}

      {!props.isLoading && (
        <TreeView
          items={treeData}
          searchHighlight={props.searchCollectionName}
          placeholder={{
            icon: (!props.searchCollectionName && canCreateCollection) ? <PlusIcon className="w-4 h-4" /> : null,
            label: (!props.searchCollectionName && canCreateCollection) ? t('Create new collection') : t('No collections found'),
            onClick: (_, item) => {
              if (!props.searchCollectionName && canCreateCollection) {
                if (item?.collection) {
                  navigate(`/${workspace}/templates?folder=${item.collection.id}`)
                } else {
                  navigate(`/${workspace}/templates`)
                }
              }
            }
          }}
          onItemClick={item => {
            registerCollectionSelected.mutate({
              type: 'collection_selected',
              data: {
                collectionId: item.collection.id,
                type: item.collection.type,
                name: item.collection.name,
                from: 'drawer'
              }
            })
          }}
          onContextMenu={(event, item) => {
            event.stopPropagation()
            setCollectionContextMenu({
              collection: item.collection,
              click: { x: event.clientX, y: event.clientY },
              element: event.currentTarget
            })
          }}
          onDrop={handleDrop}
        />
      )}

      {collectionContextMenu && (
        <OverlayContent
          open={Boolean(collectionContextMenu.element)}
          onClose={() => setCollectionContextMenu(null)}
          anchorPosition={{
            top: collectionContextMenu.click.y - 4,
            left: collectionContextMenu.click.x - 4
          }}
        >
          <CollectionContextMenu
            collection={collectionContextMenu.collection}
            onOpenClick={() => {
              setCollectionContextMenu(null)
            }}
            onCreateDatabaseClick={collection => {
              navigate(`/${workspace}/templates?folder=${collection.id}`)
              setCollectionContextMenu(null)
            }}
            onCreateFolderClick={collection => {
              createFolder.mutate({ parentId: collection.id })
              setCollectionContextMenu(null)
            }}
            onDeleteClick={collection => {
              setCollectionContextMenu(null)
              setCollectionToDelete(collection)
            }}
            onDuplicateCollectionClick={collection => {
              duplicateCollection.mutate(collection.id)
              setCollectionContextMenu(null)
            }}
            onRenameClick={collection => {
              setCollectionToRename({ collection, element: collectionContextMenu.element })
              setCollectionContextMenu(null)
            }}
          />
        </OverlayContent>
      )}

      {collectionToRename && (
        <OverlayContent
          open={Boolean(collectionToRename)}
          anchorEl={collectionToRename.element}
          anchorPosition={{
            top: collectionToRename.element.getBoundingClientRect().top + 2,
            left: collectionToRename.element.getBoundingClientRect().left + 27
          }}
        >
          <Input
            className="w-64"
            size="sm"
            autoFocus
            defaultValue={collectionToRename.collection.name ?? ''}
            onBlur={event => {
              renameCollection.mutate({
                id: collectionToRename.collection.id,
                name: event.target.value
              })
              setCollectionToRename(null)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                (event.target as HTMLInputElement).blur()
              }
            }}
          />
        </OverlayContent>
      )}

      {collectionToDelete && (
        <ConfirmDeleteDialog
          title={collectionToDelete.type === 'database'
            ? `${t('Delete collection')}${collectionToDelete?.name ? ` ${collectionToDelete.name}` : ''}?`
            : `${t('Delete folder')}${collectionToDelete?.name ? ` ${collectionToDelete.name}` : ''}?`
          }
          message={collectionToDelete.children && collectionToDelete.children.length > 0 ? t('drawerCollectionsMenu:All collections belonging to this folder will be deleted.') : undefined}
          open={Boolean(collectionToDelete)}
          onClose={() => setCollectionToDelete(null)}
          onConfirm={() => deleteCollection.mutate(collectionToDelete.id)}
        />
      )}
    </div>
  )
}

function filterCollectionsByNameRecursive (
  collections: GetCollectionsTreeResultData[],
  searchName?: string
): GetCollectionsTreeResultData[] {
  if (!searchName) return collections

  const results: GetCollectionsTreeResultData[] = []

  for (const collection of collections) {
    const matches = collection.name?.toLowerCase().includes(searchName.toLowerCase())
    const isFolder = collection.type === 'folder'

    if (isFolder && matches && collection.children) {
      results.push(collection)
    } else if (isFolder && collection.children) {
      const filteredChildren = filterCollectionsByNameRecursive(collection.children, searchName)
      if (filteredChildren.length > 0) {
        results.push({ ...collection, children: filteredChildren })
      }
    } else if (matches) {
      results.push(collection)
    }
  }

  return results
}
