import { GetRecordByIdResult } from '@indigohive/cogfy-types/endpoints/getRecordById'
import { GetCollectionFieldsResultData } from '@indigohive/cogfy-types/endpoints/getCollectionFields'
import type {
  JsonRecordProperty,
  UpdateRecordPropertiesOperationData,
  UUID
} from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export type JsonRecordPropertyInputProps = {
  collectionId: UUID
  field: Extract<GetCollectionFieldsResultData, { type: 'json' }>
  record: GetRecordByIdResult
  onUpdateRecordProperties?: (data: UpdateRecordPropertiesOperationData) => void
  canEdit?: boolean
}

export function JsonRecordPropertyInput (props: JsonRecordPropertyInputProps) {
  const { collectionId, field, record, canEdit } = props

  const property = record.properties[field.id] as JsonRecordProperty | undefined

  const [value, setValue] = useState(
    property?.json?.invalidValue ?? JSON.stringify(property?.json?.value) ?? ''
  )

  const onChange = useDebouncedCallback(
    (value: string) => {
      props.onUpdateRecordProperties?.({
        recordId: record.id,
        collectionId,
        properties: {
          [field.id]: getJsonProperty(value)
        }
      })
    },
    300
  )

  const invalid = property?.json?.invalidValue !== undefined

  return (
    <div>
      <textarea
        disabled={!canEdit}
        className={clsx('textarea', 'textarea-bordered', 'w-full', invalid && 'textarea-error')}
        value={value}
        onChange={event => {
          setValue(event.target.value)
          onChange(event.target.value)
        }}
      />
    </div>
  )
}

function getJsonProperty (rawValue: string): JsonRecordProperty {
  try {
    return {
      type: 'json',
      json: {
        value: JSON.parse(rawValue)
      }
    }
  } catch {
    return {
      type: 'json',
      json: {
        value: null,
        invalidValue: rawValue
      }
    }
  }
}
