import { UUID } from '@indigohive/cogfy-types'
import { GetWebhooksPageResultData } from '@indigohive/cogfy-types/endpoints/getWebhooksPage'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, FormattedDate, Skeleton } from '../../../components'
import { useWorkspaceSlug } from '../../../hooks'
import { Link } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { Calendar, ChevronRight, Copy, Database, Link as LinkIcon, Rss, Tag, Trash2 } from 'lucide-react'

const headers = [
  {
    title: 'Name',
    icon: Tag
  },
  {
    title: 'URL',
    icon: LinkIcon
  },
  {
    title: 'Collection',
    icon: Database
  },
  {
    title: 'Event',
    icon: Rss
  },
  {
    title: 'Created date',
    icon: Calendar
  }
]

export type WebhooksTableProps = {
  webhooks: GetWebhooksPageResultData[]
  selectedWebhooks: Record<UUID, GetWebhooksPageResultData>
  loading: boolean
  error: boolean
  disabledNextPage: boolean
  disabledPreviousPage: boolean
  onDeleteRow: (webhook: GetWebhooksPageResultData) => void
  onHeadCheckboxChange: () => void
  onCheckboxChange: (webhook: GetWebhooksPageResultData) => void
  onNextPageClick: () => void
  onPreviousPageClick: () => void
  onRefetch: () => void
}

export function WebhooksTable (props: WebhooksTableProps) {
  const { webhooks, selectedWebhooks, loading, error, onCheckboxChange, onDeleteRow, onHeadCheckboxChange, onRefetch } = props

  const selectedWebhookLength = Object.keys(selectedWebhooks).length

  const { t } = useTranslation()
  const workspace = useWorkspaceSlug()
  const ref = useRef<HTMLInputElement>(null)

  const indeterminate = Object.keys(selectedWebhooks).length > 0 && Object.keys(selectedWebhooks).length < webhooks.length

  useEffect(() => {
    if (ref.current) {
      if (indeterminate) {
        ref.current.indeterminate = true
      } else {
        ref.current.indeterminate = false
      }
    }
  }, [indeterminate])

  return (
    <div className="overflow-x-auto rounded-lg my-4">
      <table
        className="table"
        style={{ borderCollapse: 'separate', borderSpacing: 0 }}
      >
        <thead className='bg-background-default'>
          <tr>
            <th>
              <label className="settings-table-header-element justify-center">
                <Checkbox
                  ref={ref}
                  checked={webhooks.length > 0 && Object.keys(selectedWebhooks).length === webhooks.length}
                  disabled={loading || webhooks.length === 0}
                  onChange={onHeadCheckboxChange}
                  size='sm'
                />
              </label>
            </th>
            {headers.map(header => (
              <th key={header.title} className="text-left">
                <div className='settings-table-header-element'>
                  <header.icon size={16} />
                  {t(header.title)}
                </div>
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {loading && new Array(5).fill(0).map((_, index) => (
            <tr key={`table-row-loading-${index}`}>
              <>
                <td>
                  <label className="h-full flex items-center justify-center">
                    <Checkbox
                      disabled={true}
                      size='sm'
                    />
                  </label>
                </td>
                {new Array(headers.length).fill(0).map((_, index) => (
                  <td
                    key={`table-row-column-loading-${index}`}
                    className="w-32 px-2"
                  >
                    <Skeleton className="h-2 w-16" />
                  </td>
                ))}
              </>
            </tr>
          ))}
          {!loading && error && (
            <tr>
              <td colSpan={headers.length + 1}>
                {t('Error loading webhooks')}
                <Button size="xs" className="ml-2" onClick={onRefetch}>
                  {t('Retry')}
                </Button>
              </td>
            </tr>
          )}
          {!loading && !error && webhooks.length === 0 && (
            <tr>
              <td colSpan={headers.length + 1} className="text-gray-400">
                {t('No webhooks found')}
              </td>
            </tr>
          )}
          {!loading && webhooks.map(webhook => (
            <tr key={webhook.id} className="h-16 border">
              <td>
                <label className="h-full flex items-center justify-center">
                  <Checkbox
                    checked={Boolean(selectedWebhooks[webhook.id])}
                    disabled={loading}
                    onChange={() => onCheckboxChange(webhook)}
                    size='sm'
                  />
                </label>
              </td>
              <td
                className='max-w-96 truncate'
              >
                {webhook.name}
              </td>
              <td>
                <div className='flex items-center gap-2'>
                  <div className='tooltip' data-tip={webhook.url}>
                    <Link
                      to={webhook.url}
                      target='_blank'
                      className='link-hover underline'
                    >
                      Link
                    </Link>
                  </div>
                  <button
                    className='hover:border hover:rounded-lg hover:bg-background-default w-7 h-7 flex items-center justify-center'
                    onClick={() => {
                      navigator.clipboard.writeText(webhook.url)
                    }}
                  >
                    <Copy size={16} />
                  </button>
                </div>
              </td>
              <td>
                <Link
                  to={`/${workspace}/${webhook.collectionId}`}
                  className='flex items-center gap-2'
                >
                  {webhook.collectionName ?? t('Untitled')}
                  <ChevronRight size={16} />
                </Link>
              </td>
              <td>{t(`webhooks:${webhook.event}`)}</td>
              <td>
                <FormattedDate date={webhook.createDate} format="Pp" />
              </td>
              <td className="w-[80px]">
                <button
                  className={`${!(selectedWebhookLength > 1)
                    ? 'hover:border hover:rounded-lg hover:bg-background-default'
                    : 'cursor-not-allowed'}
                    w-7 h-7 flex items-center justify-center`}
                  disabled={selectedWebhookLength > 1}
                  onClick={() => onDeleteRow(webhook)}
                >
                  <Trash2 size={16} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
