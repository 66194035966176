import { ProviderName, providers } from '@indigohive/cogfy-providers'
import { FieldOperationConfig } from '@indigohive/cogfy-types'
import { BooleanFormInput } from './components/BooleanFormInput'
import { ChatFormInput } from './components/ChatFormInput'
import { CollectionsFormInput } from './components/CollectionsFormInput'
import { ConnectionFormInput } from './components/ConnectionFormInput'
import { DateFormInput } from './components/DateFormInput'
import { FieldFormInput } from './components/FieldFormInput'
import { FileFormInput } from './components/FileFormInput'
import { JsonFormInput } from './components/JsonFormInput'
import { NumberFormInput } from './components/NumberFormInput'
import { TextFormInput } from './components/TextFormInput'
import { VectorFormInput } from './components/VectorFormInput'
import { FormInputProps, FormInputSchema } from './types'

export type AutogeneratedFormProps = {
  value: FieldOperationConfig
  onChange: (value: FieldOperationConfig) => void
  schema: Record<string, FormInputSchema>
  connections: FormInputProps['connections']
  collections: FormInputProps['collections']
  fields: FormInputProps['fields']
}

const INPUTS_BY_TYPE: Record<string, (props: FormInputProps) => JSX.Element> = {
  boolean: BooleanFormInput,
  chat: ChatFormInput,
  collection: CollectionsFormInput,
  date: DateFormInput,
  file: FileFormInput,
  field: FieldFormInput,
  number: NumberFormInput,
  json: JsonFormInput,
  text: TextFormInput,
  vector: VectorFormInput
}

const PROVIDER_NAMES = new Set(providers.map(provider => provider.name))

export function AutogeneratedForm (props: AutogeneratedFormProps) {
  const { schema, value, onChange } = props

  const inputs = Object.entries(schema).map(([key, value]) => ({ name: key, ...value }))

  return (
    <div className="flex flex-col gap-4">
      {inputs.map(input => (
        <div key={input.name} className="form-control">
          <label className="label">
            <span className="label-text">
              {input.displayName ?? input.name}
              {input.required && <span className="text-error"> *</span>}
              {input.type !== 'boolean' && (
                <span className="text-xs opacity-40 ml-2">
                  {input.type}
                </span>
              )}
            </span>
          </label>
          {getComponentByType(input.type)?.({
            value: value[input.name],
            onChange: newValue => {
              if (newValue === undefined) {
                const nextValue = { ...value }
                delete nextValue[input.name]
                onChange(nextValue)
              } else {
                onChange({ ...value, [input.name]: newValue })
              }
            },
            fields: props.fields,
            connections: props.connections,
            collections: props.collections,
            schema: input
          })}
        </div>
      ))}
    </div>
  )
}

function getComponentByType (type: string): ((props: FormInputProps) => JSX.Element) | undefined {
  if (PROVIDER_NAMES.has(type as ProviderName)) {
    return ConnectionFormInput
  }

  return INPUTS_BY_TYPE[type]
}
