import { FormInputProps } from '../types'

export function BooleanFormInput (props: FormInputProps) {
  return (
    <input
      type="checkbox"
      className="toggle toggle-primary"
      checked={props.value?.type === 'boolean' && props.value.boolean.value}
      onChange={event => props.onChange({ type: 'boolean', boolean: { value: event.target.checked } })}
    />
  )
}
