import { ChatMiddlewareType } from '@indigohive/cogfy-types'
import { ChatMiddlewareDataFormProps } from './ChatMiddlewareDataFormProps'
import { ActionForm } from './subcomponents/ActionForm'
import { RetrievalForm } from './subcomponents/RetrievalForm'
import { ToolForm } from './subcomponents/ToolForm'

const COMPONENTS_BY_MIDDLEWARE_TYPE: Partial<Record<ChatMiddlewareType, typeof ActionForm>> = {
  action: ActionForm,
  retrieval: RetrievalForm,
  tool: ToolForm
}

export function ChatMiddlewareDataForm (props: ChatMiddlewareDataFormProps) {
  const { type } = props

  const CustomComponent = COMPONENTS_BY_MIDDLEWARE_TYPE[type]

  return (
    <>
      {CustomComponent && <CustomComponent {...props} />}
    </>
  )
}
