import { ChatMessageRecordProperty } from '@indigohive/cogfy-types'
import {
  CheckIcon,
  CircleUserIcon,
  ClockIcon,
  FileIcon,
  ImageIcon,
  ListIcon,
  MapPinIcon,
  MessageSquareShare,
  MicIcon,
  StickerIcon,
  VideoIcon,
  XIcon
} from 'lucide-react'
import { FieldCellProps } from '../../FieldCell'
import clsx from 'clsx'
import { createElement } from 'react'
import { FormattedDate } from '../../../../components'

export type ChatMessageCellProps = Pick<FieldCellProps<ChatMessageRecordProperty>, 'property' | 'textWrap'>

export function ChatMessageCell (props: ChatMessageCellProps) {
  const { property, textWrap } = props

  const content = property?.chatMessage?.value?.content
  const contentType = property?.chatMessage?.value?.contentType
  const status = property?.chatMessage?.value?.status
  const sendDate = property?.chatMessage?.value?.sendDate
  const scheduleDate = property?.chatMessage?.value?.scheduleDate
  const preview = contentType ? MESSAGE_PREVIEW_BY_CONTENT_TYPE[contentType] : null

  return (
    <span
      className={clsx(
        'px-2 py-1',
        'w-full',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      <div>
        {preview && (
          <div className="flex items-center gap-2">
            {createElement(preview.icon, { size: 16 })}
            <span>{preview.content}</span>
          </div>
        )}
        {!preview && contentType === 'text' && (
          <>
            {content && <span>{content}</span>}
            {!content && <span className="opacity-40 italic">No content</span>}
          </>
        )}
      </div>
      <div className="flex items-center gap-1 opacity-70 justify-end text-xs">
        <FormattedDate date={status === 'scheduled' ? scheduleDate : sendDate} format="Pp" />
        {status && status !== 'scheduled' && status !== 'failed' && (
          <>
            <CheckIcon
              size={16}
              className={clsx(
                status === 'read' && 'text-sky-400'
              )}
            />
            <CheckIcon
              size={16}
              className={clsx(
                'ml-[-16px]',
                status === 'sent' && 'invisible',
                status === 'read' && 'text-sky-400'
              )}
            />
          </>
        )}
        {status === 'failed' && <XIcon size={16} className="text-red-700 mr-1" />}
        {status === 'scheduled' && <ClockIcon size={16} />}
      </div>
    </span>
  )
}

const MESSAGE_PREVIEW_BY_CONTENT_TYPE: Record<string, { icon: typeof MicIcon, content: string }> = {
  audio: { icon: MicIcon, content: 'Audio' },
  contacts: { icon: CircleUserIcon, content: 'Contact' },
  document: { icon: FileIcon, content: 'Document' },
  image: { icon: ImageIcon, content: 'Image' },
  interactive: { icon: ListIcon, content: 'Interactive' },
  location: { icon: MapPinIcon, content: 'Location' },
  sticker: { icon: StickerIcon, content: 'Sticker' },
  template: { icon: MessageSquareShare, content: 'Template' },
  video: { icon: VideoIcon, content: 'Video' }
}
