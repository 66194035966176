import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useCogfy, useCollections } from '../../../../../../hooks'
import { UpdateChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/updateChatMiddleware'
import { FieldType, UUID } from '@indigohive/cogfy-types'
import { GetChatMiddlewaresResultData } from '@indigohive/cogfy-types/endpoints/getChatMiddlewares'
import { ChatMiddlewareDataForm } from '../../../../../../components/ChatMiddlewareDataForm'
import { useTranslation } from 'react-i18next'
import { GetCollectionsResultData } from '@indigohive/cogfy-types/endpoints/getCollections'
import { ChatMiddlewareConditionForm } from '../../../../../../components/ChatMiddlewareConditionForm'
import { AutogeneratedForm } from '../../../../../../components/AutogeneratedForm'

export type EditChatMiddlewareFormProps = {
  collectionId: UUID
  fieldId: UUID
  middleware: GetChatMiddlewaresResultData
  fields?: {
    id: UUID
    name: string
    type: FieldType
  }[]
  collections?: GetCollectionsResultData[]
}

export function EditChatMiddlewareForm (props: EditChatMiddlewareFormProps) {
  const { fieldId, middleware, collectionId } = props
  const [name, setName] = useState(middleware.name)
  const [data, setData] = useState(middleware.data)
  const [condition, setCondition] = useState(middleware.condition)

  const { t } = useTranslation()
  const client = useCogfy()
  const queryClient = useQueryClient()
  const updateMiddleware = useMutation({
    mutationFn: (data: UpdateChatMiddlewareCommand) => client.updateChatMiddleware(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getChatMiddlewares', { fieldId }]
      })
    }
  })
  const getConnections = useQuery({
    queryKey: ['getConnectionsList', {}] as const,
    queryFn: ({ queryKey, signal }) => client.getConnectionsList(queryKey[1], { signal })
  })
  const getCollections = useCollections({ type: 'database' })
  const connections = getConnections.data?.data ?? []
  const collections = getCollections.data?.data ?? []
  const getChatMiddlewareSchemas = useQuery({
    queryKey: ['getChatMiddlewareSchemas'],
    queryFn: ({ signal }) => client.getChatMiddlewareSchemas({ signal })
  })
  const update = useDebouncedCallback(() => {
    updateMiddleware.mutate({
      chatMiddlewareId: middleware.id,
      name,
      data,
      condition
    })
  }, 300)

  const middlewareSchema = useMemo(
    () => getChatMiddlewareSchemas.data?.data
      .find(schema => schema.name === middleware.type),
    [getChatMiddlewareSchemas.data, middleware.type]
  )

  const autogeneratedForm =
    Object.entries(data).length === 0 ||
    data._version === 2

  return (
    <div>
      <label className="form-control w-full my-4">
        <div className="label">
          <span className="label-text">{t('Name')}</span>
        </div>
        <input
          className="input input-bordered w-full input-sm"
          value={name}
          onBlur={() => {
            update()
          }}
          onChange={event => {
            setName(event.target.value)
            update()
          }}
        />
      </label>

      <ChatMiddlewareConditionForm
        fields={props.fields}
        value={condition}
        onChange={newCondition => {
          setCondition(newCondition)
          update()
        }}
      />

      {middlewareSchema && autogeneratedForm && (
        <AutogeneratedForm
          value={data}
          onChange={newData => {
            setData({ _version: 2, ...newData })
            update()
          }}
          schema={middlewareSchema.schema.inputs}
          fields={props.fields}
          connections={connections}
          collections={collections}
        />
      )}

      {(!middlewareSchema || !autogeneratedForm) && (
        <ChatMiddlewareDataForm
          type={middleware.type}
          collectionId={collectionId}
          data={data}
          onChange={newData => {
            setData(newData)
            update()
          }}
          schemas={getChatMiddlewareSchemas.data?.data}
          fields={props.fields}
          collections={props.collections}
        />
      )}
    </div>
  )
}
