import { NuvemFiscalNfseRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../../FieldCell'
import { OptionChip, OptionChipColorName } from '../../../../components'
import { useTranslation } from 'react-i18next'

export type NuvemFiscalNfseCellProps = Pick<FieldCellProps<NuvemFiscalNfseRecordProperty>, 'property'>

const STATUS_TO_COLOR: Record<string, OptionChipColorName> = {
  autorizada: 'green',
  cancelada: 'red',
  erro: 'red',
  negada: 'red',
  processando: 'yellow',
  substituida: 'yellow'
}

export function NuvemFiscalNfseCell (props: NuvemFiscalNfseCellProps) {
  const { property } = props

  const { t } = useTranslation('nuvemFiscalNfseCell')
  const value = property?.['nuvemFiscal.nfse']?.value
  const serviceValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value?.serviceValue ?? 0)

  return (
    <>
      {value && (
        <div className="flex gap-8 items-center w-full px-2 py-1">
          <div className="flex flex-col text-sm gap-3">
            <span>
              {t('Buyer')}: {value.serviceBuyerCpf ?? value.serviceBuyerCnpj}
            </span>
            <span>
              {t('Provider')}: {value.serviceProviderCnpj ?? value.serviceProviderCpf}
            </span>
          </div>
          <div className="flex flex-col justify-around text-sm gap-2">
            {value.status && (
              <OptionChip
                color={STATUS_TO_COLOR[value.status]}
                label={t(value.status)}
              />
            )}
            <span>
              {serviceValue}
            </span>
          </div>
        </div>
      )}
    </>
  )
}
