import { v4 as uuid } from 'uuid'
import { FieldData, RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateField, CollectionStateViewField } from '../collection-state'
import { incrementSuffix } from '../../helpers'

export type DuplicateFieldCommandData = {
  fieldToDuplicateId: UUID
}

export class DuplicateFieldCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _fieldToDuplicateId: UUID
  private readonly _duplicatedField: CollectionStateField
  private readonly _duplicatedViewField: CollectionStateViewField

  constructor (
    state: CollectionState,
    data: DuplicateFieldCommandData
  ) {
    this._state = state
    this._fieldToDuplicateId = data.fieldToDuplicateId

    const fieldToDuplicate = state.fields!.find(field => field.id === data.fieldToDuplicateId)!
    const viewFieldToDuplicate = state.viewFields!.find(viewField => (
      viewField.viewId === this._state.activeViewId && viewField.fieldId === data.fieldToDuplicateId
    ))!
    const hasWhatsAppConfig = Boolean(((fieldToDuplicate.data) as any)?.chat?.whatsApp)

    this._duplicatedField = {
      ...fieldToDuplicate,
      id: uuid() as UUID,
      name: incrementSuffix(fieldToDuplicate.name.replace(/[0-9]+$/g, '').trim(), this._state.fields?.map(field => field.name) ?? []),
      data: hasWhatsAppConfig ? this.handleRemoveWhatsAppConfig(fieldToDuplicate.data!) : fieldToDuplicate.data
    }
    this._duplicatedViewField = {
      ...viewFieldToDuplicate,
      id: uuid() as UUID,
      fieldId: this._duplicatedField.id,
      order: this._state.viewFields?.length ?? 0
    }
  }

  private handleRemoveWhatsAppConfig (fieldData: FieldData): object {
    const { chat, ...rest } = fieldData as any
    const { whatsApp, ...restChat } = chat

    return {
      ...rest,
      chat: restChat
    }
  }

  run (): RunTransactionCommand {
    const currentFields = this._state.fields
    const currentViewFields = this._state.viewFields
    const newFields = currentFields ? [...currentFields, this._duplicatedField] : [this._duplicatedField]
    const newViewFields = currentViewFields ? [...currentViewFields, this._duplicatedViewField] : [this._duplicatedViewField]

    this._state.setFields(newFields)
    this._state.setViewFields(newViewFields)
    this._state.setSelectedField(null)
    this._state.setSelectedFieldToUpdate(this._duplicatedField)

    return {
      operations: [
        {
          type: 'duplicate_field',
          data: {
            collectionId: this._state.id,
            fieldId: this._duplicatedField.id,
            fieldName: this._duplicatedField.name,
            fieldToDuplicateId: this._fieldToDuplicateId
          }
        },
        {
          type: 'create_view_field',
          data: {
            collectionId: this._state.id,
            viewFieldId: this._duplicatedViewField.id,
            viewId: this._duplicatedViewField.viewId,
            fieldId: this._duplicatedViewField.fieldId,
            order: null,
            config: this._duplicatedViewField.config
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newFields = this._state.fields?.filter(field => field.id !== this._duplicatedField.id) ?? []
    const newViewFields = this._state.viewFields?.filter(viewField => viewField.id !== this._duplicatedViewField.id) ?? []

    this._state.setFields(newFields)
    this._state.setViewFields(newViewFields)
    this._state.setSelectedField(null)
    this._state.setSelectedFieldToUpdate(null)

    return {
      operations: [
        {
          type: 'hard_delete_field',
          data: {
            collectionId: this._state.id,
            fieldId: this._duplicatedField.id
          }
        }
      ]
    }
  }
}
