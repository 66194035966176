import { GetWorkspaceBySlugResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceBySlug'
import { useTranslation } from 'react-i18next'
import { useCogfy, usePermissions, useToasts } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { Toggle } from '../../../components'
import { useState } from 'react'

export type WorkspaceRequire2FAProps = {
  workspace: GetWorkspaceBySlugResult
}

export function WorkspaceRequire2FA (props: WorkspaceRequire2FAProps) {
  const { workspace } = props

  const [value, setValue] = useState(workspace.require2FA)
  const { t } = useTranslation('workspaceSettingsPage')
  const toasts = useToasts()
  const cogfy = useCogfy()
  const permissions = usePermissions()

  const isAdmin = permissions.isAdmin

  const updateRequire2FA = useMutation({
    mutationFn: async (require2FA: boolean) => {
      try {
        await cogfy.updateWorkspace2FA({ require2FA })
        setValue(!value)
      } catch (error) {
        toasts.error(t('Failed to update require Two-Factor Authentication'))
      }
    }
  })

  return (
    <>
      <div className="px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg">
        <Toggle
          color="primary"
          inputSize="md"
          label={t('Require Two-Factor Authentication (2FA)')}
          disabled={!isAdmin}
          checked={value}
          onChange={event => updateRequire2FA.mutate(event.target.checked)}
        />
      </div>
    </>
  )
}
