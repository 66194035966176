import { GetChatMessagesPageResultData, UUID } from '@indigohive/cogfy-types'
import { useCogfy, useWorkspaceSlug } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { ChatMessageSource } from './ChatMessageSource'
import { ChatRetrievalSource } from '@indigohive/cogfy-types/src/chat-messages/chat-retrieval-source'

export type ChatSourcesBubbleContentProps = {
  message?: GetChatMessagesPageResultData
}

export function ChatSourcesBubbleContent (props: ChatSourcesBubbleContentProps) {
  const { message } = props

  const workspaceSlug = useWorkspaceSlug()
  const cogfy = useCogfy()
  const showFile = useMutation({
    mutationFn: async (data: { fileId: string, pageNumber: number }) => {
      const { fileId, pageNumber } = data

      const file = await cogfy.getFileById({ fileId: fileId as UUID })
      const documentPage = pageNumber ? `#page=${pageNumber}` : ''

      window.open(`${file.url}${documentPage}`, '_blank')
    }
  })

  const middlewaresSources: ChatRetrievalSource[] = [...(message?.data as any)?.completion?.sources ?? [], ...(message?.data as any)?.tool?.sources ?? []]

  return (
    <>
      {middlewaresSources.map((source, index) => (
        <ChatMessageSource
          key={index}
          source={source}
          order={index + 1}
          showFile={showFile.mutate}
          workspace={workspaceSlug}
        />
      ))}
    </>
  )
}
