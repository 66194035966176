import { PlusIcon, TrashIcon } from 'lucide-react'
import { Button } from '../../../Button'
import { FormInputProps } from '../../types'
import { FieldPropertyMention, FieldPropertyMentionValue } from '../../../FieldPropertyMention'

export function MultipleTextFormInput (props: FormInputProps) {
  const values = props.value?.type === 'templates' && props.value.template.length > 0
    ? props.value.template
    : [{ strings: [], args: [] }]

  const handleAddClick = () => {
    props.onChange({
      type: 'templates',
      template: [
        ...values,
        { strings: [], args: [] }
      ]
    })
  }

  const handleDeleteClick = (index: number) => {
    const newValues = [...values]
    newValues.splice(index, 1)
    props.onChange({ type: 'templates', template: newValues })
  }

  const handleChange = (value: FieldPropertyMentionValue, index: number) => {
    const newValues = [...values]
    newValues[index] = value

    props.onChange({ type: 'templates', template: newValues })
  }

  return (
    <div className="flex flex-col gap-1">
      {values.map((template, index) => (
        <div key={index} className="flex gap-1">
          <FieldPropertyMention
            value={template}
            onChange={value => handleChange(value, index)}
            fields={props.fields ?? []}
          />
          <Button size="sm" square ghost onClick={() => handleDeleteClick(index)}>
            <TrashIcon size={16} />
          </Button>
        </div>
      ))}
      <div className="text-right mt-1">
        <Button size="xs" onClick={handleAddClick}>
          <PlusIcon size={16} />
          Add
        </Button>
      </div>
    </div>
  )
}
