import { useTranslation } from 'react-i18next'
import { CollectionState, useActiveViewId, useCountRecords, useFieldsById, useSelectedRows, useViews } from '../../../../lib'
import { CollectionPageController } from '../../collection-page-controller'
import { Button, Skeleton } from '../../../../components'
import { TrashIcon } from 'lucide-react'
import { AndFilter, OrFilter, RecordFilter, UUID } from '@indigohive/cogfy-types'
import { OrderByChip } from './subcomponents/OrderByChip'
import { AddFilterButton } from './subcomponents'
import { FieldFilterChip } from '../../../../field-types'
import { GetUserCollectionPermissionsResult } from '@indigohive/cogfy-types/endpoints/getUserCollectionPermissions'

export type CollectionToolbarProps = {
  state: CollectionState
  controller: CollectionPageController
  permissions?: GetUserCollectionPermissionsResult
  loading?: boolean
}

export function CollectionToolbar (props: CollectionToolbarProps) {
  const { state, controller, permissions, loading } = props

  const { t } = useTranslation()
  const selectedRows = useSelectedRows(state)
  const activeViewId = useActiveViewId(state)
  const view = useViews(state)?.find(view => view.id === activeViewId)
  const countRecords = useCountRecords(state)

  const viewFilter: AndFilter = view?.filter as AndFilter ?? { and: [] }
  const countSelectedRows = Object.keys(selectedRows).length
  const fieldsById = useFieldsById(state)

  return (
    <div className="px-4 py-2 flex justify-between items-center bg-base-100 border-b z-30 min-h-8">
      {loading && <span className="text-sm"><Skeleton className="h-2 w-32" /></span>}
      {!loading && (
        <span className="text-sm whitespace-nowrap mr-2">
          {countSelectedRows > 0 && t('Records selected', { count: countSelectedRows })}
          {countSelectedRows === 0 && t('Records found', { count: countRecords ?? 0 })}
        </span>
      )}
      {!loading && countSelectedRows === 0 && (
        <div className="flex items-center gap-1 overflow-x-auto overflow-y-hidden" style={{ scrollbarWidth: 'thin' }}>
          {view?.orderBy && (
            <>
              {view?.orderBy?.map(order => (
                <OrderByChip
                  key={order.fieldId}
                  state={state}
                  controller={controller}
                  recordOrder={order}
                />
              ))}
            </>
          )}
          {view?.orderBy && view.orderBy.length > 0 && <div className="border h-6 border-base-200 mr-2 ml-2" />}
          {fieldsById && (
            <>
              {viewFilter.and
                .filter(filter => fieldsById[(filter as any)?.property])
                .map((filter, index) => (
                  <FieldFilterChip
                    key={index}
                    field={fieldsById[(filter as Exclude<RecordFilter, AndFilter | OrFilter>)?.property]}
                    filter={filter as Exclude<RecordFilter, AndFilter | OrFilter>}
                    viewFilter={viewFilter}
                    controller={controller}
                  />
                ))
              }
              <AddFilterButton state={state} controller={controller} />
            </>
          )}
        </div>
      )}
      {countSelectedRows > 0 && (permissions?.type === 'full_access' || permissions?.type === 'editor') && (
        <Button
          className="hover:text-error"
          size="xs"
          onClick={() => controller.onDeleteRowsClick(Object.keys(selectedRows) as UUID[])}
        >
          <TrashIcon size={14} />
          {t('Delete')}
        </Button>
      )}
    </div>
  )
}
