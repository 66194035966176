import { FieldType } from '@indigohive/cogfy-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu } from '../../../../components'
import { FieldsMenu } from './subcomponents/FieldsMenu'
import { SearchInput } from '../../components/SearchInput'

export type CreateFieldMenuProps = {
  onClick?: (fieldType: FieldType) => void
}

const FIELD_TYPES_BY_CATEGORY: FieldType[][] = [
  [
    'text',
    'number',
    'boolean',
    'select',
    'date',
    'file',
    'reference'
  ],
  [
    'chat',
    'chatMessage',
    'connection'
  ],
  [
    'author',
    'createDate',
    'updateDate'
  ],
  [
    'json',
    'vector',
    'httpResponse',
    'schedule'
  ],
  [
    'nuvemFiscal.nfse',
    'sendgrid.inboundEmail',
    'stripe.customer',
    'vindi.bill',
    'vindi.customer',
    'vindi.subscription'
  ]
]

export function CreateFieldMenu (props: CreateFieldMenuProps) {
  const [searchFieldName, setSearchFieldName] = useState('')
  const { t } = useTranslation()

  return (
    <Menu className="max-h-screen">
      <li className="menu-title">
        {t('New field')}
      </li>
      <SearchInput
        searchName={searchFieldName}
        onNameChange={setSearchFieldName}
      />
      <FieldsMenu
        onClick={props.onClick}
        FIELD_TYPES_BY_CATEGORY={FIELD_TYPES_BY_CATEGORY}
        searchFieldName={searchFieldName}
      />
    </Menu>
  )
}
