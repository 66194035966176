import { useQuery } from '@tanstack/react-query'
import { TransactionEvent } from '@indigohive/cogfy-types/events/transaction'
import { UUID } from '@indigohive/cogfy-types'
import { useCogfy } from './use-cogfy'
import { useEffect } from 'react'

export function useChatMiddlewares (
  collectionId: UUID,
  fieldId: UUID
) {
  const cogfy = useCogfy()

  const getChatMiddlewares = useQuery({
    queryKey: ['getChatMiddlewares', { fieldId }],
    queryFn: () => cogfy.getChatMiddlewares({ fieldId })
  })

  const handleTransaction = (event: TransactionEvent) => {
    if (event.type === 'chat_middleware_created' && event.data.fieldId === fieldId) {
      getChatMiddlewares.refetch()
    }
  }

  useEffect(() => {
    cogfy.transactions.onTransaction(collectionId, handleTransaction)

    return () => {
      cogfy.transactions.offTransaction(collectionId, handleTransaction)
    }
  }, [fieldId])

  return getChatMiddlewares
}
