import { useQuery, useQueryClient } from '@tanstack/react-query'
import { GetCollectionByIdResult, GetCollectionByIdResultParent } from '@indigohive/cogfy-types/endpoints/getCollectionById'
import { CollectionRenamedEvent } from '@indigohive/cogfy-types/events/transaction'
import { UUID } from '@indigohive/cogfy-types'
import { useEffect } from 'react'
import { useCogfy } from './use-cogfy'
import { CollectionState } from '../lib'

export function useCollection (
  collectionId?: UUID | null,
  state?: CollectionState
) {
  const cogfy = useCogfy()

  const getCollection = useQuery({
    queryKey: ['getCollection', collectionId, state?.id],
    queryFn: async ({ signal }) => {
      const result = await cogfy.getCollectionById({ collectionId: collectionId! }, { signal })

      if (state) {
        state.setName(result.name)
        state.setLocked(result.locked)
        state.setSelectedCollectionTitleFieldId(result.titleFieldId)
      }

      return result
    },
    enabled: Boolean(collectionId)
  })
  const queryClient = useQueryClient()

  useEffect(() => {
    const onCollectionNameUpdated = (event: CollectionRenamedEvent) => {
      queryClient.setQueryData<GetCollectionByIdResult>(
        ['getCollection', collectionId, state?.id],
        (oldData) => {
          if (event.data.collectionId === oldData?.id) {
            return {
              ...oldData,
              name: event.data.name
            }
          }

          if (oldData?.parent) {
            return {
              ...oldData,
              parent: updateParentCollectionNameRecursive(oldData.parent, event.data.collectionId, event.data.name)
            }
          }

          return oldData
        }
      )
    }
    const onCollectionParentUpdated = () => {
      getCollection.refetch()
    }

    cogfy.collections.onCollectionParentUpdated.add(onCollectionParentUpdated)
    cogfy.transactions.onCollectionRenamed.add(onCollectionNameUpdated)

    return () => {
      cogfy.collections.onCollectionParentUpdated.remove(onCollectionParentUpdated)
      cogfy.transactions.onCollectionRenamed.remove(onCollectionNameUpdated)
    }
  }, [queryClient, collectionId, state])

  return getCollection
}

const updateParentCollectionNameRecursive = (
  parent: GetCollectionByIdResultParent,
  id: string,
  name: string | null
): GetCollectionByIdResultParent => {
  if (parent.id === id) {
    return {
      ...parent,
      name
    }
  }

  if (parent.parent) {
    return {
      ...parent,
      parent: updateParentCollectionNameRecursive(parent.parent, id, name)
    }
  }

  return parent
}
