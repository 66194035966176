import { GetCollectionFieldsResultData } from '@indigohive/cogfy-types/endpoints/getCollectionFields'
import type { FieldVisibility, RunTransactionCommandOperation, TextRecordProperty, UUID } from '@indigohive/cogfy-types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useCogfy, useCollection, useWorkspaceSlug } from '../../hooks'
import { RecordPageBreadcrumb } from './components/RecordPageBreadcrumb'
import { Button, Navbar, OverlayContent } from '../../components'
import clsx from 'clsx'
import { FIELD_ICONS_BY_TYPE, RecordPropertyContent } from '../../field-types'
import { createElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RecordPageFieldMenu } from './components/RecordPageFieldMenu'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'
import { RecordPageNavbarMenu } from './components/RecordPageNavbarMenu'

type ClickedField = {
  element: HTMLElement
  field: GetCollectionFieldsResultData
}

export function RecordPage () {
  const params = useParams<{ collectionId: UUID, recordId: UUID }>()
  const collectionId = params.collectionId!
  const recordId = params.recordId!
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const workspace = useWorkspaceSlug()
  const getCollection = useCollection(collectionId)
  const getRecord = useQuery({
    queryKey: ['getRecordById', recordId],
    queryFn: ({ signal }) => cogfy.getRecordById({ recordId }, { signal })
  })
  const getFields = useQuery({
    queryKey: ['getFieldsByCollectionId', collectionId],
    queryFn: ({ signal }) => cogfy.getCollectionFields({ collectionId }, { signal })
  })
  const getUserCollectionPermissions = useQuery({
    queryKey: ['getUserCollectionPermissions', { collectionId }] as const,
    queryFn: ({ queryKey, signal }) => cogfy[queryKey[0]](queryKey[1], { signal })
  })
  const [clickedField, setClickedField] = useState<ClickedField | null>(null)
  const [showHiddenFields, setShowHiddenFields] = useState(false)

  const collection = getCollection.data
  const record = getRecord.data
  const fields = getFields.data?.data
  const title = collection?.titleFieldId && (record?.properties[collection.titleFieldId] as TextRecordProperty | undefined)?.text?.value

  const visibleFields = fields?.filter(field => field.visibility === 'visible')
  const hiddenFields = fields?.filter(field => field.visibility === 'hidden')
  const maxVisibleFieldOrder = Math.max(...[0, ...(visibleFields?.map(field => field.order) ?? [])])

  const runTransaction = useMutation({
    mutationFn: async (data: RunTransactionCommandOperation) => {
      await cogfy.transactions.create({ operations: [data] })
      await getRecord.refetch()
    }
  })
  const updateFieldVisibility = useMutation({
    mutationFn: async (data: { fieldId: UUID, visibility: FieldVisibility }) => {
      await cogfy.transactions.create({
        operations: [
          {
            type: 'update_field_visibility',
            data: {
              collectionId: getCollection.data!.id,
              fieldId: data.fieldId,
              visibility: data.visibility
            }
          }
        ]
      })
      await getFields.refetch()
    }
  })
  const updateFieldOrder = useMutation({
    mutationFn: async (data: { fieldId: UUID, delta: -1 | 1 }) => {
      const index = visibleFields!.findIndex(field => field.id === data.fieldId)

      let order = -1

      if (data.delta === -1 && index > 0) {
        order = visibleFields![index - 1].order
      } else if (data.delta === 1 && visibleFields![index + 1]) {
        order = visibleFields![index + 1].order
      }

      if (order !== -1) {
        await cogfy.transactions.create({
          operations: [
            {
              type: 'reorder_field',
              data: {
                collectionId: getCollection.data!.id,
                fieldId: data.fieldId,
                order
              }
            }
          ]
        })
        await getFields.refetch()
      }
    }
  })

  const hasFullAccess = getUserCollectionPermissions.data?.type === 'full_access'
  const canEdit = hasFullAccess || getUserCollectionPermissions.data?.type === 'editor'

  return (
    <div className="bg-base-100 min-h-full">
      <Navbar>
        <div className="flex justify-between grow">
          <RecordPageBreadcrumb
            collection={getCollection.data}
            record={getRecord.data}
          />
          <RecordPageNavbarMenu
            workspace={workspace}
            collectionId={collectionId}
            recordId={recordId}
          />
        </div>
      </Navbar>
      <h1
        className={clsx(
          'text-2xl',
          'font-bold',
          'mx-2',
          'px-4',
          'py-2',
          'my-1',
          !title && 'opacity-40'
        )}
      >
        {title ?? t('Untitled')}
      </h1>
      <div className="max-w-screen-lg px-4 pb-8">
        <div className="flex flex-col gap-6 max-w-full">
          {record && visibleFields?.map(field => (
            <div key={field.id} className="max-w-full">
              <button
                className="btn btn-xs btn-ghost"
                onClick={event => {
                  setClickedField({
                    element: event.currentTarget,
                    field
                  })
                }}
              >
                {createElement(FIELD_ICONS_BY_TYPE[field.type], { size: 16 })}
                {field.name}
              </button>
              <div className="px-2">
                <RecordPropertyContent
                  canEdit={canEdit}
                  collectionId={collectionId}
                  field={field}
                  record={record}
                  onUpdateRecordProperties={data => {
                    runTransaction.mutate({
                      type: 'update_record_properties',
                      data
                    })
                  }}
                />
              </div>
            </div>
          ))}
          {hiddenFields && hiddenFields.length > 0 && (
            <div>
              <Button size="xs" ghost onClick={() => setShowHiddenFields(prev => !prev)}>
                {t('RecordPage:Hidden fields')} ({hiddenFields.length})
                {showHiddenFields
                  ? <ChevronUpIcon size={16} />
                  : <ChevronDownIcon size={16} />}
              </Button>
              <div className="flex flex-col gap-1.5 py-2">
                {showHiddenFields && hiddenFields?.map(field => (
                  <div key={field.id}>
                    <Button
                      size="xs"
                      ghost
                      onClick={event => {
                        setClickedField({
                          element: event.currentTarget,
                          field
                        })
                      }}
                    >
                      {createElement(FIELD_ICONS_BY_TYPE[field.type], { className: 'w-4 h-4' })}
                      {field.name}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {clickedField && (
        <OverlayContent
          open={Boolean(clickedField)}
          anchorEl={clickedField.element}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={() => setClickedField(null)}
        >
          <RecordPageFieldMenu
            field={clickedField.field}
            hasFullAccess={hasFullAccess}
            onShowClick={() => {
              updateFieldVisibility.mutate({ fieldId: clickedField.field.id, visibility: 'visible' })
              setClickedField(null)
            }}
            onHideClick={() => {
              updateFieldVisibility.mutate({ fieldId: clickedField.field.id, visibility: 'hidden' })
              setClickedField(null)
            }}
            onMoveUpClick={() => {
              updateFieldOrder.mutate({ fieldId: clickedField.field.id, delta: -1 })
              setClickedField(null)
            }}
            onMoveDownClick={() => {
              updateFieldOrder.mutate({ fieldId: clickedField.field.id, delta: 1 })
              setClickedField(null)
            }}
            moveUpDisabled={clickedField.field.order === 0 || clickedField.field.visibility === 'hidden'}
            moveDownDisabled={clickedField.field.order === maxVisibleFieldOrder || clickedField.field.visibility === 'hidden'}
          />
        </OverlayContent>
      )}
    </div>
  )
}
