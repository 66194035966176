import { GetMembersPageResultData } from '@indigohive/cogfy-types/endpoints/getMembersPage'
import { MemberRole, UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { Button, ConfirmDeleteDialog, Container, Input, SettingsPagesHeader } from '../../components'
import { useAuthentication, useCogfy, useMembersPage, usePermissions, useWorkspace, useTitle } from '../../hooks'
import { MembersTableView } from '../../components/MembersTableView'
import { AddMemberDialog, MembersSelectionBar } from './components'
import { PlusIcon, Search, User } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { MemberRoleSelect } from './components/MemberRoleSelect'

export function WorkspaceMembersPage () {
  const params = useParams<{ workspace: string }>()
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [role, setRole] = useState<MemberRole | undefined>(undefined)
  const [addMemberDialogOpen, setAddMemberDialogOpen] = useState(false)
  const [selections, setSelections] = useState<UUID[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const workspaceSlug = params.workspace!
  const getMembers = useMembersPage({ email, role })
  const getWorkspace = useWorkspace(workspaceSlug)
  const cogfy = useCogfy()
  const user = useAuthentication().authenticatedUser
  const permissions = usePermissions()
  const memberIsAdmin = permissions.isAdmin
  const selectionsLength = selections.length

  const debouncedSearchByEmail = useDebouncedCallback(
    async (value: string) => {
      setEmail(value === '' ? undefined : value)
    },
    500
  )
  const deleteMembers = useMutation({
    mutationFn: async () => {
      await cogfy.deleteWorkspaceMembers({ userIds: selections })
      setSelections([])
      getMembers.refetch()
    }
  })
  const updateMemberRole = useMutation({
    mutationFn: async (data: { userId: UUID, role: MemberRole }) => {
      await cogfy.updateMemberRole({ userId: data.userId, role: data.role })
      await getMembers.refetch()
    }
  })

  const handleDeleteRow = (member: GetMembersPageResultData) => {
    setSelections([member.userId])
    setDialogOpen(true)
  }

  const { t } = useTranslation()

  useTitle({
    title: t('Members'),
    loading: getMembers.isLoading,
    error: getMembers.isError
  })

  return (
    <>
      <SettingsPagesHeader
        title={t('Members')}
        description={t('SettingsPages:Manage permissions and add new members')}
      >
        <User size={46} />
      </SettingsPagesHeader>
      <Container>
        {selections?.length === 0 &&
          <div className="flex mt-8 mb-2 gap-2 items-center">
            <Search size={20} />
            <Input
              placeholder="user@email.com"
              size="sm"
              className={clsx('join-item', 'w-full', 'border-none', 'focus:outline-none', 'focus:bg-transparent')}
              onChange={(event) => { debouncedSearchByEmail(event.target.value) }}
            />
            <div>
              <MemberRoleSelect
                onChange={setRole}
                selectedRole={role}
              />
            </div>
            {memberIsAdmin && (
              <Button
                size="sm"
                color='primary'
                disabled={getMembers.isPending}
                onClick={() => setAddMemberDialogOpen(true)}
              >
                <PlusIcon size={16} />
                {t('Add')}
              </Button>
            )}
          </div>}
        {selections?.length === 0 && getWorkspace.data && (
          <AddMemberDialog
            onClose={() => setAddMemberDialogOpen(false)}
            open={addMemberDialogOpen}
            workspace={getWorkspace.data}
            onSuccess={() => { getMembers.refetch() }}
          />
        )}
        {selections?.length > 0 && (
          <div className="flex mt-8 mb-2 gap-2 h-8">
            <MembersSelectionBar
              count={selectionsLength}
              setDialogOpen={setDialogOpen}
            />
          </div>
        )}
        <MembersTableView
          getMembers={getMembers.data}
          loading={getMembers.isLoading}
          selections={selections}
          user={user}
          memberIsAdmin={memberIsAdmin}
          setSelections={setSelections}
          onDeleteRow={handleDeleteRow}
          onUpdateMemberRole={(userId: UUID, role: MemberRole) => updateMemberRole.mutate({ userId, role })}
        />
      </Container>
      <ConfirmDeleteDialog
        title={t('membersPage:Delete all selected members?')}
        open={dialogOpen}
        onConfirm={() => {
          deleteMembers.mutate()
          setDialogOpen(false)
        }}
        onClose={() => {
          setDialogOpen(false)
        }}
      />
    </>
  )
}
