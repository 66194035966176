import { useQuery } from '@tanstack/react-query'
import { UUID } from '@indigohive/cogfy-types'
import { useCogfy } from './use-cogfy'

export function useChat (chatId: string) {
  const cogfy = useCogfy()

  const getChat = useQuery({
    queryKey: ['getChatById', chatId],
    queryFn: ({ signal }) => cogfy.getChatById({ chatId: chatId as UUID }, { signal })
  })

  return getChat
}
