import { GetMembersPageQuery } from '@indigohive/cogfy-types/endpoints/getMembersPage'
import { useQuery } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'

export function useMembersPage (
  params: GetMembersPageQuery = {}
) {
  const cogfy = useCogfy()

  const getMembersPage = useQuery({
    queryKey: ['getMembersPage', params],
    queryFn: ({ signal }) => cogfy.getMembersPage(params, { signal })
  })

  return getMembersPage
}
