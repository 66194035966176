import { UUID } from '@indigohive/cogfy-types'
import { GetUserGroupsPageResultData } from '@indigohive/cogfy-types/endpoints/getUserGroupsPage'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Textarea } from '../../../../components'
import { Plus } from 'lucide-react'
import { useCogfy, usePermissions, useUserGroupMembers } from '../../../../hooks'
import { AddMemberToGroupDialog } from '../AddMemberToGroupDialog'
import { useMutation } from '@tanstack/react-query'

type EditUserGroupModalProps = {
  open: boolean
  group: GetUserGroupsPageResultData | null
  onClose: () => void
}

export function EditUserGroupModal (props: EditUserGroupModalProps) {
  const { open, onClose, group } = props

  const [name, setName] = useState<string | null>()
  const [description, setDescription] = useState<string | null>()
  const [addMemberDialogOpen, setAddMemberDialogOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDialogElement>(null)
  const cogfy = useCogfy()
  const permissions = usePermissions()

  const isAdmin = permissions.isAdmin
  const getUserGroupMembers = useUserGroupMembers(group?.id ?? '')
  const members = getUserGroupMembers.data?.data

  const { t } = useTranslation()

  useEffect(() => {
    if (group) {
      setName(group.name)
      setDescription(group.description)
    }
  }, [group])

  useEffect(() => {
    if (open) {
      ref.current?.showModal()
    } else {
      ref.current?.close()
    }
  }, [open])

  useEffect(() => {
    const dialog = ref.current

    dialog?.addEventListener('close', onClose)

    return () => {
      dialog?.removeEventListener('close', onClose)
    }
  }, [])

  const onUpdateName = async () => {
    await cogfy.updateUserGroupName({ userGroupId: group!.id, name: name! })
  }

  const onUpdateDescription = async () => {
    await cogfy.updateUserGroupDescription({ userGroupId: group!.id, description: description! })
  }

  const handleInputBlur = (field: 'name' | 'description') => {
    field === 'name' ? onUpdateName() : onUpdateDescription()
  }

  const deleteUserGroupMember = useMutation({
    mutationFn: async (userId: UUID) => {
      await cogfy.deleteUserGroupMembers({ userGroupId: group!.id, userIds: [userId] })
      getUserGroupMembers.refetch()
    }
  })

  return (
    <>
      <dialog ref={ref} className="modal">
        <div className="modal-box w-1/3 max-w-5xl">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>
          <div>
            <h2 className="text-1xl font-bold mb-4 truncate mr-4">{t('Group')} {group?.name}</h2>
            <div className='flex flex-col gap-8 mt-8'>
              <Input
                className="w-full border rounded-lg p-3 text-sm border-gray-300 bg-background-default"
                label={t('Name')}
                value={name ?? ''}
                onChange={(event) => setName(event.target.value)}
                onBlur={() => handleInputBlur('name')}
              />
              <Textarea
                className="w-full border rounded-lg p-3 text-sm border-gray-300 bg-background-default"
                label={t('Description')}
                value={description ?? ''}
                onChange={(event) => setDescription(event.target.value)}
                onBlur={() => handleInputBlur('description')}
              />
              <div className='w-full bg-background-default rounded-lg py-6'>
                <div className='flex justify-between mx-5 mb-6'>
                  <span className='font-semibold'>{t('Members')}</span>
                  <Button
                    size='sm'
                    color='primary'
                    onClick={() => setAddMemberDialogOpen(true)}
                  >
                    <Plus size={16} />
                    {t('Add member')}
                  </Button>
                </div>
                <div className='flex flex-col gap-2'>
                  {(!members || members?.length === 0) && (<span className='pl-5'>{t('No members found')}</span>)}
                  {members && members.length > 0 && members.map(member => (
                    <div
                      key={member.id}
                      className='flex justify-between items-center px-4 h-16 mx-6 rounded-lg border border-border-default bg-white'
                    >
                      <div className="flex items-center">
                        <div className="pr-2 avatar placeholder">
                          <div className="bg-gray-500 text-neutral-content rounded-full w-8">
                            <span className="text-xs">{member.name[0]}</span>
                          </div>
                        </div>
                        <span className="text-sm">{member.name}</span>
                      </div>
                      <Button
                        size='sm'
                        onClick={() => deleteUserGroupMember.mutate(member.userId)}
                      >
                        {t('userGroupsPage:Remove from group')}
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </dialog>
      {addMemberDialogOpen && group && isAdmin && (
        <AddMemberToGroupDialog
          open={addMemberDialogOpen}
          group={group}
          onClose={() => {
            setAddMemberDialogOpen(false)
          }}
          onSuccess={() => {
            getUserGroupMembers.refetch()
            setAddMemberDialogOpen(false)
          }}
        />
      )}
    </>
  )
}
