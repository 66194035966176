import { UUID } from '@indigohive/cogfy-types'
import { PlusIcon, TrashIcon } from 'lucide-react'
import { useMemo } from 'react'
import { Button } from '../../Button'
import { Select } from '../../Select'
import { FormInputProps } from '../types'

export function FileFormInput (props: FormInputProps) {
  const multiple = props.schema.multiple ?? false

  const options = useMemo(
    () => {
      return [
        { label: 'Select a field', value: '' },
        ...(props.fields ?? [])
          .filter(field => field.type === 'file')
          .map(field => ({ label: field.name, value: field.id }))
      ]
    },
    [props.fields]
  )

  const values = props.value?.type === 'fields'
    ? props.value.fields.value
    : [{}]

  const handleAddClick = () => {
    props.onChange({
      type: 'fields',
      fields: {
        value: [...values, {}]
      }
    })
  }

  const handleDeleteClick = (index: number) => {
    const newValues = [...values]
    newValues.splice(index, 1)
    props.onChange({
      type: 'fields',
      fields: { value: newValues }
    })
  }

  return (
    <div className="flex flex-col gap-1">
      {values.map((value, index) => (
        <div key={index} className="flex gap-1">
          <Select
            size="sm"
            value={value.id ?? ''}
            onChange={event => {
              const newValues = [...values]
              newValues[index] = event.target.value
                ? { id: event.target.value as UUID }
                : {}

              props.onChange({
                type: 'fields',
                fields: { value: newValues }
              })
            }}
            options={options}
          />
          {multiple && (
            <Button size="sm" square ghost onClick={() => handleDeleteClick(index)}>
              <TrashIcon size={16} />
            </Button>
          )}
        </div>
      ))}
      {multiple && (
        <div className="text-right">
          <Button className="mt-1" size="xs" onClick={handleAddClick}>
            <PlusIcon size={16} />
            Add field
          </Button>
        </div>
      )}
    </div>
  )
}
