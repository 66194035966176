import { CogfyClient } from '@indigohive/cogfy-api-client'
import { RunTransactionCommand } from '@indigohive/cogfy-types'
import { TransactionQueue } from '../transaction-queue'
import { CollectionState } from '../collection-state'
import { Command, CommandStack } from '../command-stack'
import {
  CreateFieldCommand,
  CreateFieldCommandData,
  CreateRecordCommand,
  CreateRecordReferenceCommand,
  CreateRecordReferenceCommandData,
  CreateViewCommand,
  CreateViewFieldCommand,
  CreateViewFieldCommandData,
  DeleteFieldCommand,
  DeleteFieldCommandData,
  DeleteRecordReferenceCommand,
  DeleteRecordReferenceCommandData,
  DeleteRecordsCommand,
  DeleteRecordsCommandData,
  DeleteViewCommand,
  DeleteViewCommandData,
  DeleteViewFieldCommand,
  DeleteViewFieldCommandData,
  DuplicateFieldCommand,
  DuplicateFieldCommandData,
  DuplicateViewCommand,
  DuplicateViewCommandData,
  LockCollectionCommand,
  PasteRecordsCommand,
  RenameCollectionCommand,
  RenameCollectionCommandData,
  RenameFieldCommand,
  RenameFieldCommandData,
  RenameViewCommand,
  RenameViewCommandData,
  ReorderViewFieldAfterCommand,
  ReorderViewFieldAfterCommandData,
  ReorderViewFieldBeforeCommand,
  ReorderViewFieldBeforeCommandData,
  UnlockCollectionCommand,
  UpdateCollectionTitleFieldCommand,
  UpdateCollectionTitleFieldCommandData,
  UpdateFieldDataCommand,
  UpdateFieldDataCommandData,
  UpdateFieldOperationCommand,
  UpdateFieldOperationCommandData,
  UpdateRecordPropertiesCommand,
  UpdateRecordPropertiesCommandData,
  UpdateRecordReferenceCommand,
  UpdateRecordReferenceCommandData,
  UpdateSearchRecordsCommand,
  UpdateSearchRecordsCommandData,
  UpdateViewFieldConfigCommand,
  UpdateViewFieldConfigCommandData,
  UpdateViewFilterCommand,
  UpdateViewFilterCommandData,
  UpdateViewOrderByCommand,
  UpdateViewOrderByCommandData
} from '../commands'
import { ReorderViewAfterCommand, ReorderViewAfterCommandData } from '../commands/reorder-view-after-command'
import { ReorderViewBeforeCommand, ReorderViewBeforeCommandData } from '../commands/reorder-view-before-command'

export type AppManagerOptions = {
  cogfyApiClient: CogfyClient
  debug?: boolean
}

export class AppManager {
  private readonly _cogfyApiClient: CogfyClient
  private readonly _commandStack: CommandStack<RunTransactionCommand | null>
  private readonly _transactionQueue: TransactionQueue

  constructor (options: AppManagerOptions) {
    this._cogfyApiClient = options.cogfyApiClient
    this._commandStack = new CommandStack()
    this._transactionQueue = new TransactionQueue()

    if (options.debug) {
      this._setupDebug()
    }
  }

  get hasUnsavedChanges () {
    return this._transactionQueue.length > 0
  }

  clearUndoRedo () {
    this._commandStack.clear()
  }

  createField (state: CollectionState, data: CreateFieldCommandData) {
    this._run(new CreateFieldCommand(state, data))
  }

  createRecord (state: CollectionState) {
    this._run(new CreateRecordCommand(state))
  }

  createRecordReference (state: CollectionState, data: CreateRecordReferenceCommandData) {
    this._run(new CreateRecordReferenceCommand(state, data))
  }

  createView (state: CollectionState) {
    this._run(new CreateViewCommand(state))
  }

  createViewField (state: CollectionState, data: CreateViewFieldCommandData) {
    this._run(new CreateViewFieldCommand(state, data))
  }

  deleteField (state: CollectionState, data: DeleteFieldCommandData) {
    if (state.fields?.length !== 1) {
      this._run(new DeleteFieldCommand(state, data))
    }
  }

  deleteRecord (state: CollectionState, data: DeleteRecordsCommandData) {
    this._run(new DeleteRecordsCommand(state, data))
  }

  deleteRecordReference (state: CollectionState, data: DeleteRecordReferenceCommandData) {
    this._run(new DeleteRecordReferenceCommand(state, data))
  }

  deleteView (state: CollectionState, data: DeleteViewCommandData) {
    if (state.views?.length !== 1) {
      this._run(new DeleteViewCommand(state, data))
    }
  }

  deleteViewField (state: CollectionState, data: DeleteViewFieldCommandData) {
    this._run(new DeleteViewFieldCommand(state, data))
  }

  duplicateField (state: CollectionState, data: DuplicateFieldCommandData) {
    this._run(new DuplicateFieldCommand(state, data))
  }

  duplicateView (state: CollectionState, data: DuplicateViewCommandData) {
    this._run(new DuplicateViewCommand(state, data))
  }

  lockCollection (state: CollectionState) {
    this._run(new LockCollectionCommand(state))
  }

  paste (state: CollectionState, clipboardData: DataTransfer) {
    if (state.selectedCell) {
      this._run(new PasteRecordsCommand(state, clipboardData))
    }
  }

  redo () {
    const result = this._commandStack.redo()

    if (result) {
      this._transactionQueue.run(
        () => this._cogfyApiClient.transactions.create(result)
      )
    }
  }

  renameCollection (state: CollectionState, data: RenameCollectionCommandData) {
    this._run(new RenameCollectionCommand(state, data))
  }

  renameField (state: CollectionState, data: RenameFieldCommandData) {
    this._run(new RenameFieldCommand(state, data))
  }

  renameView (state: CollectionState, data: RenameViewCommandData) {
    this._run(new RenameViewCommand(state, data))
  }

  reorderViewAfter (state: CollectionState, data: ReorderViewAfterCommandData) {
    this._run(new ReorderViewAfterCommand(state, data))
  }

  reorderViewBefore (state: CollectionState, data: ReorderViewBeforeCommandData) {
    this._run(new ReorderViewBeforeCommand(state, data))
  }

  reorderViewFieldAfter (state: CollectionState, data: ReorderViewFieldAfterCommandData) {
    this._run(new ReorderViewFieldAfterCommand(state, data))
  }

  reorderViewFieldBefore (state: CollectionState, data: ReorderViewFieldBeforeCommandData) {
    this._run(new ReorderViewFieldBeforeCommand(state, data))
  }

  undo () {
    const result = this._commandStack.undo()

    if (result) {
      this._transactionQueue.run(
        () => this._cogfyApiClient.transactions.create(result)
      )
    }
  }

  unlockCollection (state: CollectionState) {
    this._run(new UnlockCollectionCommand(state))
  }

  updateCollectionTitleField (state: CollectionState, data: UpdateCollectionTitleFieldCommandData) {
    this._run(new UpdateCollectionTitleFieldCommand(state, data))
  }

  updateFieldData (state: CollectionState, data: UpdateFieldDataCommandData) {
    this._run(new UpdateFieldDataCommand(state, data))
  }

  updateFieldOperation (state: CollectionState, data: UpdateFieldOperationCommandData) {
    this._run(new UpdateFieldOperationCommand(state, data))
  }

  updateRecordProperties (state: CollectionState, data: UpdateRecordPropertiesCommandData) {
    this._run(new UpdateRecordPropertiesCommand(state, data))
  }

  updateRecordRefence (state: CollectionState, data: UpdateRecordReferenceCommandData) {
    this._run(new UpdateRecordReferenceCommand(state, data))
  }

  updateSearchRecords (state: CollectionState, data: UpdateSearchRecordsCommandData) {
    this._run(new UpdateSearchRecordsCommand(state, data))
  }

  updateViewFieldConfig (state: CollectionState, data: UpdateViewFieldConfigCommandData) {
    this._run(new UpdateViewFieldConfigCommand(state, data))
  }

  updateViewFilter (state: CollectionState, data: UpdateViewFilterCommandData) {
    this._run(new UpdateViewFilterCommand(state, data))
  }

  updateViewOrderBy (state: CollectionState, data: UpdateViewOrderByCommandData) {
    this._run(new UpdateViewOrderByCommand(state, data))
  }

  private _run (command: Command<RunTransactionCommand | null>) {
    const result = this._commandStack.run(command)

    if (result) {
      this._transactionQueue.run(
        () => this._cogfyApiClient.transactions.create(result)
      )
    }
  }

  private _setupDebug () {
    this._commandStack.addEventListener('run', event => {
      // eslint-disable-next-line no-console
      console.log('[CommandStack]: run', event.command, event.result)
    })

    this._commandStack.addEventListener('undo', event => {
      // eslint-disable-next-line no-console
      console.log('[CommandStack]: undo', event.command, event.result)
    })

    this._commandStack.addEventListener('redo', event => {
      // eslint-disable-next-line no-console
      console.log('[CommandStack]: redo', event.command, event.result)
    })

    this._commandStack.addEventListener('clear', () => {
      // eslint-disable-next-line no-console
      console.log('[CommandStack]: clear')
    })
  }
}
