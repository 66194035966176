import axios from 'axios'
import qs from 'qs'
import { Socket, io } from 'socket.io-client'
import {
  AuthenticationClient,
  BaseClientOptions,
  CollectionsClient,
  TransactionsClient,
  WorkspacesClient
} from './clients'
import { EndpointsClient } from './endpoints-client'

export type CogfyClientOptions = {
  baseURL?: string
  getWorkspace?: () => string | null
  devMode?: {
    delay?: number
    errorRate?: number
  }
  socket?: {
    opts?: Parameters<typeof io>[1]
  }
}

const defaultBaseURL = 'http://localhost:3000'

export class CogfyClient extends EndpointsClient {
  private readonly socket: Socket

  getWorkspace: (() => string | null) | null

  authentication: AuthenticationClient
  collections: CollectionsClient
  transactions: TransactionsClient
  workspaces: WorkspacesClient

  constructor (options: CogfyClientOptions = {}) {
    super(
      axios.create({
        baseURL: options.baseURL ?? defaultBaseURL,
        withCredentials: true,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        timeout: 10000
      })
    )
    this.getWorkspace = options.getWorkspace ?? null

    this.socket = io(options.baseURL ?? defaultBaseURL, {
      autoConnect: false,
      withCredentials: true,
      transports: ['websocket'],
      ...options.socket?.opts
    })

    this.axios.interceptors.request.use(
      request => {
        const workspace = this.getWorkspace?.()

        if (workspace) {
          request.headers.workspace = workspace
        }

        return request
      }
    )
    this.setupDevMode(options)

    const clientOptions: BaseClientOptions = {
      axios: this.axios,
      socket: this.socket
    }

    this.authentication = new AuthenticationClient(clientOptions)
    this.collections = new CollectionsClient(clientOptions)
    this.transactions = new TransactionsClient(clientOptions)
    this.workspaces = new WorkspacesClient(clientOptions)
  }

  // Error handling methods
  isBadRequest (error: unknown): boolean {
    return axios.isAxiosError(error) && error.response?.status === 400
  }

  isUnauthorized (error: unknown): boolean {
    return axios.isAxiosError(error) && error.response?.status === 401
  }

  isNotFound (error: unknown): boolean {
    return axios.isAxiosError(error) && error.response?.status === 404
  }

  isConflict (error: unknown): boolean {
    return axios.isAxiosError(error) && error.response?.status === 409
  }

  // Socket methods
  connect (): void {
    this.socket.connect()
  }

  connected (): boolean {
    return this.socket.connected
  }

  disconnect (): void {
    this.socket.disconnect()
  }

  disconnected (): boolean {
    return this.socket.disconnected
  }

  join (room: string): void {
    this.socket.emit('join', room)
  }

  leave (room: string): void {
    this.socket.emit('leave', room)
  }

  private setupDevMode (options: CogfyClientOptions = {}) {
    if (options.devMode?.delay) {
      const delay = options.devMode?.delay

      this.axios.interceptors.request.use(
        request => {
          return new Promise(resolve => {
            if (delay !== Infinity) {
              setTimeout(() => resolve(request), delay)
            }
          })
        }
      )
    }

    if (options.devMode?.errorRate) {
      const errorRate = options.devMode?.errorRate

      this.axios.interceptors.request.use(
        request => {
          return new Promise((resolve, reject) => {
            if (Math.random() < errorRate) {
              reject(new Error('Dev mode error'))
            } else {
              resolve(request)
            }
          })
        }
      )
    }
  }
}
