import { MicIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Button } from '../../../../components'
import { FileCellProps } from './FileCell'
import { useUpload } from './hooks/useUpload'
import { useCogfy } from '../../../../hooks'

export function FileCellMicVariant (props: FileCellProps) {
  const { property } = props

  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)

  const handleStart = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    const recorder = new MediaRecorder(stream)
    recorder.start()
    setStartDate(new Date())
    recorder.ondataavailable = (event) => {
      const mediaType = recorder.mimeType || event.data.type

      const extension = mediaType.split(';')[0].split('/')[1]
      const blob = new Blob([event.data])
      const file = new File([blob], `audio.${extension}`, { type: mediaType })
      upload.mutate(file)
    }
    setMediaStream(stream)
    setMediaRecorder(recorder)
  }

  const handleStop = () => {
    mediaStream?.getTracks()[0].stop()
    mediaRecorder?.stop()
    setStartDate(null)
    setMediaStream(null)
    setMediaRecorder(null)
  }

  const cogfy = useCogfy()
  const upload = useUpload(cogfy, {
    data: props.field.data,
    collectionId: props.collectionId,
    fieldId: props.field.id,
    recordId: props.record.id,
    onProgress: (_progress) => {
      // TO-DO
    }
  })

  const isRecording = Boolean(mediaStream && mediaRecorder)
  const value = property?.file?.value

  return (
    <>
      {value?.uploadStatus === 'sent' && value.url && (
        <audio
          controls
          src={value.url}
        />
      )}
      {!value && isRecording && (
        <div className="flex gap-2 items-center">
          <Button
            ghost
            size="sm"
            onClick={handleStop}
          >
            <MicIcon size={20} />
            Stop
          </Button>
          <Duration startDate={startDate} />
        </div>
      )}
      {!value && !isRecording && (
        <Button
          ghost
          size="sm"
          onClick={() => { handleStart() }}
        >
          <MicIcon size={20} />
          Start
        </Button>
      )}
    </>
  )
}

function Duration (props: { startDate: Date | null }) {
  const [formatted, setFormatted] = useState<string | null>(null)

  useEffect(() => {
    if (props.startDate !== null) {
      setFormatted('0:00')
      const interval = setInterval(() => {
        const now = new Date()
        const diff = now.getTime() - props.startDate!.getTime()
        const seconds = Math.floor(diff / 1000)
        const minutes = Math.floor(seconds / 60)
        const secondsLeft = seconds % 60
        setFormatted(`${minutes}:${secondsLeft.toString().padStart(2, '0')}`)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [props.startDate])

  return (
    <>
      <div className="bg-red-500 w-2 h-2 rounded-full" />
      {formatted}
    </>
  )
}
