import { FieldType } from '@indigohive/cogfy-types'
import { createElement } from 'react'
import { CollectionState, CollectionStateField, CollectionStateRecord, CollectionStateViewField } from '../../lib'
import { CollectionPageController } from '../../pages/CollectionPageV2/collection-page-controller'
import { ChatMessageOverlay, DateOverlay, JsonOverlay, NumberOverlay, ReferenceOverlay, SelectOverlay, TextOverlay } from './components'
import { ConnectionOverlay } from './components/ConnectionOverlay'
import { ScheduleOverlay } from './components/ScheduleOverlay'

export type FieldOverlayType = typeof TextOverlay | typeof ChatMessageOverlay

export const FIELD_OVERLAY_BY_TYPE: Partial<Record<FieldType, FieldOverlayType>> = {
  chatMessage: ChatMessageOverlay,
  connection: ConnectionOverlay,
  date: DateOverlay,
  json: JsonOverlay,
  number: NumberOverlay,
  reference: ReferenceOverlay,
  schedule: ScheduleOverlay,
  select: SelectOverlay,
  text: TextOverlay
}

export type FieldOverlayProps = {
  state: CollectionState
  record: CollectionStateRecord
  field: CollectionStateField
  viewField: CollectionStateViewField
  element: Element
  controller: CollectionPageController
  onClose: (reason: 'Enter' | 'Escape' | 'Tab' | 'blur' | null) => void
}

export function FieldOverlay (props: FieldOverlayProps) {
  const { field, record } = props

  const overlayComponent = FIELD_OVERLAY_BY_TYPE[field.type]
  const isLoading = record.properties?.[field.id]?.pending

  return (
    <>
      {!isLoading && overlayComponent && createElement(overlayComponent, props)}
    </>
  )
}
