import { ProviderName, providersByName } from '@indigohive/cogfy-providers'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '../Input'

export type ProviderValuesInputsProps<T extends ProviderName> = {
  providerName: T
  values: Record<string, string>
  onChange: (values: Record<string, string>) => void
}

const LABEL_MAP: Record<string, string> = {
  accessKeyId: 'Access Key ID',
  accessToken: 'Access Token',
  apiKey: 'API Key',
  apiVersion: 'API Version',
  baseUrl: 'Base URL',
  clientId: 'Client ID',
  clientSecret: 'Client Secret',
  cloudApiAccessToken: 'Cloud API Access Token',
  expiryDate: 'Expiry Date',
  host: 'Host',
  redirectUri: 'Redirect URI',
  refreshToken: 'Refresh Token',
  region: 'Region',
  scope: 'Scope',
  secretAccessKey: 'Secret Access Key',
  tokenType: 'Token Type',
  waBusinessAccountId: 'WhatsApp Business Account ID',
  waBusinnessAccountId: 'WhatsApp Business Account ID'
}

export function ProviderValuesInputs<T extends ProviderName> (
  props: ProviderValuesInputsProps<T>
) {
  const { providerName, values, onChange } = props
  const provider = providersByName[providerName]
  const { t } = useTranslation()
  const [touched, setTouched] = useState<Record<string, boolean>>({})

  return (
    <>
      {Object.entries(provider.values).map(([key, value]) => (
        <Input
          className="my-2"
          type={value.encrypted ? 'password' : 'text'}
          key={key}
          name={key}
          label={LABEL_MAP[key] ?? key}
          value={values[key] ?? ''}
          onBlur={() => setTouched({ ...touched, [key]: true })}
          onChange={event => {
            const value = event.target.value
            onChange({ ...values, [key]: value })
          }}
          error={Boolean(
            touched[key] &&
            !('optional' in value && value.optional) &&
            !values[key]
          )}
          helperText={
            touched[key] &&
            !('optional' in value && value.optional) &&
            !values[key] &&
            t('Required field')
          }
        />
      ))}
    </>
  )
}
