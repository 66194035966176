import { VindiCustomerRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../../FieldCell'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { OptionChip, OptionChipColorName } from '../../../../components'

const STATUS_TO_COLOR: Record<string, OptionChipColorName> = {
  active: 'green',
  inactive: 'yellow',
  delinquent: 'red'
}

export type VindiCustomerCellProps = Pick<FieldCellProps<VindiCustomerRecordProperty>, 'property' | 'textWrap'>

export function VindiCustomerCell (props: VindiCustomerCellProps) {
  const { property, textWrap } = props
  const { t } = useTranslation('VindiCustomerCell')

  const { name, externalId, status } = property?.['vindi.customer'] ?? {}

  return (
    <>
      {property?.['vindi.customer'] && (
        <Link
          to={`https://app.vindi.com.br/admin/customers/${externalId}`}
          target='_blank'
          className={clsx(
            'h-full',
            'w-full',
            'px-2',
            'py-1',
            'hover:bg-base-200',
            'active:bg-base-300',
            'text-gray-600',
            textWrap && 'whitespace-pre-wrap',
            !textWrap && 'truncate'
          )}
        >
          <div className='flex'>
            {name} (#{externalId})&nbsp;
            <OptionChip
              color={STATUS_TO_COLOR[status!]}
              label={t(status!)}
            />
          </div>
        </Link>
      )}
    </>
  )
}
