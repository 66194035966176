import { SearchIcon } from 'lucide-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

export type SearchInputProps = {
  searchName: string
  onNameChange?: (fieldName: string) => void
}

export function SearchInput (props: SearchInputProps) {
  const { searchName, onNameChange } = props
  const { t } = useTranslation()

  return (
    <label
      className={clsx(
        'input',
        'input-bordered',
        'input-sm',
        'flex',
        'items-center',
        'gap-2',
        'mx-2',
        'font-semibold',
        'text-sm',
        searchName ? 'drawer-search-collections-input-active' : 'drawer-search-collections-input-inactive'
      )}
      style={{ outline: 'unset' }}
    >
      <SearchIcon size={14} className="text-[#1C569A]" />
      <input
        autoFocus
        type="text"
        placeholder={t('Search')}
        value={searchName}
        onChange={event => onNameChange?.(event.target.value)}
      />
    </label>
  )
}
