import { Select } from '../../Select'
import { FormInputProps } from '../types'

export function FieldFormInput (props: FormInputProps) {
  const fieldOptions = (props.fields ?? [])
    .map(field => ({
      label: field.name,
      value: field.id
    }))

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const fieldId = event.target.value
    const field = props.fields?.find(field => field.id === fieldId)

    if (field) {
      props.onChange({
        type: 'field',
        field: { value: { id: field.id } }
      })
    } else {
      props.onChange(undefined)
    }
  }

  return (
    <Select
      size="sm"
      value={(props.value as any)?.field?.value?.id ?? ''}
      onChange={handleChange}
      options={[
        { label: 'Select a field', value: '' },
        ...fieldOptions
      ]}
    />
  )
}
