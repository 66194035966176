import { HttpResponseRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../../FieldCell'
import { OptionChip, OptionChipColorName } from '../../../../components'
import clsx from 'clsx'

const getStatusColor = (status: string): OptionChipColorName => {
  if (status.startsWith('2')) {
    return 'green'
  } else if (status.startsWith('4')) {
    return 'red'
  }
  return 'red'
}

export type HttpResponseCellProps = Pick<FieldCellProps<HttpResponseRecordProperty>, 'property' | 'textWrap'>

export function HttpResponseCell (props: HttpResponseCellProps) {
  const { property, textWrap } = props

  const value = property?.httpResponse?.value

  const statusColor = value?.status ? getStatusColor(value.status.toString()) : 'red'

  const body = value?.body
  const status = value?.status ?? ''
  const duration = value?.duration?.toFixed(0) ?? 0
  const error = value?.error ? JSON.stringify(value?.error) : null

  return (
    <>
      {error && (
        <div
          className={clsx(
            'h-full',
            'w-full',
            'px-2',
            'py-1',
            'hover:bg-base-200',
            'active:bg-base-300',
            'text-red-600',
            textWrap && 'whitespace-pre-wrap',
            !textWrap && 'truncate'
          )}
        >
          <div className={clsx('flex gap-1', textWrap ? 'flex-wrap' : 'flex-nowrap', 'items-center')}>
            <span>{error}</span>
          </div>
        </div>)}
      {(property?.httpResponse && !error) && (
        <div
          className={clsx(
            'h-full',
            'w-full',
            'px-2',
            'py-1',
            'hover:bg-base-200',
            'active:bg-base-300',
            'text-gray-600',
            textWrap && 'whitespace-pre-wrap',
            !textWrap && 'truncate'
          )}
        >
          <div className={clsx('flex gap-1', textWrap ? 'flex-wrap' : 'flex-nowrap', 'items-center')}>
            <OptionChip color={statusColor} label={status.toString()} />
            <span className='opacity-70 text-xs'>{duration}ms</span>
            {!textWrap && <div>|</div>}
            <span>{body}</span>
          </div>
        </div>
      )}
    </>
  )
}
