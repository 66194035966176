import { FormInputProps } from '../types'
import { FieldPropertyMention } from '../../FieldPropertyMention'

export function NumberFormInput (props: FormInputProps) {
  return (
    <FieldPropertyMention
      value={props.value?.type === 'template' ? props.value.template : undefined}
      onChange={value => props.onChange({ type: 'template', template: value })}
      fields={props.fields ?? []}
    />
  )
}
