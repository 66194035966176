import { FormInputProps } from '../../types'
import { Select } from '../../../Select'

export function OptionsTextFormInput (props: FormInputProps) {
  return (
    <>
      {props.schema.options && (
        <Select
          size="sm"
          value={
            (props.value?.type === 'text' && !Array.isArray(props.value.text.value) && props.value.text.value) || ''
          }
          onChange={event => {
            props.onChange(
              event.target.value
                ? { type: 'text', text: { value: event.target.value } }
                : undefined
            )
          }}
          options={[
            { label: 'Select an option', value: '' },
            ...props.schema.options
          ]}
        />
      )}
    </>
  )
}
