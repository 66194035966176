import { v4 as uuid } from 'uuid'
import { RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateView, CollectionStateViewField } from '../collection-state'
import { incrementSuffix } from '../../helpers'

export type DuplicateViewCommandData = {
  viewToDuplicateId: UUID
}

export class DuplicateViewCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _viewToDuplicateId: UUID
  private readonly _duplicatedView: CollectionStateView
  private readonly _duplicatedViewFields: CollectionStateViewField[]

  constructor (
    state: CollectionState,
    data: DuplicateViewCommandData
  ) {
    this._state = state
    this._viewToDuplicateId = data.viewToDuplicateId

    const viewToDuplicate = state.views!.find(view => view.id === data.viewToDuplicateId)!
    const viewFieldsToDuplicate = state.viewFields!.filter(viewField => viewField.viewId === data.viewToDuplicateId)

    const existingViewNames = state.views!.map(view => view.name).filter((name): name is string => name !== null)
    const newViewName = incrementSuffix(viewToDuplicate.name!, existingViewNames)

    this._duplicatedView = {
      ...viewToDuplicate,
      id: uuid() as UUID,
      name: newViewName
    }
    this._duplicatedViewFields = viewFieldsToDuplicate.map(viewField => ({
      ...viewField,
      id: uuid() as UUID,
      viewId: this._duplicatedView.id
    }))
  }

  run (): RunTransactionCommand {
    const currentViews = this._state.views
    const currentViewFields = this._state.viewFields
    const newViews = currentViews ? [...currentViews, this._duplicatedView] : [this._duplicatedView]
    const newViewFields = currentViewFields ? [...currentViewFields, ...this._duplicatedViewFields] : [...this._duplicatedViewFields]

    this._state.setViews(newViews)
    this._state.setViewFields(newViewFields)

    return {
      operations: [
        {
          type: 'duplicate_view',
          data: {
            collectionId: this._state.id,
            viewId: this._duplicatedView.id,
            viewToDuplicateId: this._viewToDuplicateId,
            name: this._duplicatedView.name
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newViews = this._state.views?.filter(view => view.id !== this._duplicatedView.id) ?? []
    const newViewFields = this._state.viewFields?.filter(viewField => viewField.viewId !== this._duplicatedView.id) ?? []

    this._state.setViews(newViews)
    this._state.setViewFields(newViewFields)

    return {
      operations: [
        {
          type: 'hard_delete_view',
          data: {
            collectionId: this._state.id,
            viewId: this._duplicatedView.id
          }
        }
      ]
    }
  }
}
