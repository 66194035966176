import { DateField } from '@indigohive/cogfy-types'
import { Select } from '../../../components'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useSelectedFieldToUpdate } from '../../../lib'
import { FieldConfigurationsProps } from '../FieldConfigurations'

export function DateConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props

  const { t } = useTranslation()
  const field = useSelectedFieldToUpdate(state) as DateField
  const [dateFormat, setDateFormat] = useState(getDate(field.data?.date?.format))
  const [hourFormat, setHourFormat] = useState(getHour(field.data?.date?.format))

  useEffect(() => {
    setDateFormat(getDate(field.data?.date?.format))
    setHourFormat(getHour(field.data?.date?.format))
  }, [field.data])

  return (
    <>
      <Select
        label={t('editFieldDrawer:Date format')}
        size="sm"
        value={dateFormat}
        onChange={event => {
          controller.onUpdateFieldData(
            field.id,
            { date: { ...field.data?.date, format: `${event.target.value}${hourFormat}` } }
          )
          setDateFormat(event.target.value)
        }}
        options={[
          { label: t('editFieldDrawer:Month/Day/Year'), value: 'P' },
          { label: t('editFieldDrawer:Month Day, Year'), value: 'PPP' },
          { label: t('editFieldDrawer:Full date'), value: 'PPPP' }
        ]}
      />
      <Select
        label={t('editFieldDrawer:Hour format')}
        size="sm"
        value={hourFormat}
        onChange={event => {
          controller.onUpdateFieldData(
            field.id,
            { date: { ...field.data?.date, format: `${dateFormat}${event.target.value}` } }
          )
          setHourFormat(event.target.value)
        }}
        options={[
          { label: t('Hidden'), value: '' },
          { label: t('editFieldDrawer:Hours.Minutes'), value: 'p' },
          { label: t('editFieldDrawer:Hours.Minutes.Seconds'), value: 'pp' }
        ]}
      />
    </>
  )
}

const getDate = (format?: string) => format?.match(/P+/) ? format?.match(/P+/)![0] : 'P'
const getHour = (format?: string) => format?.match(/p+/) ? format?.match(/p+/)![0] : ''
