import { CircleAlert, RefreshCw } from 'lucide-react'
import clsx from 'clsx'
import { Button } from '../../../components'
import { FieldCellProps } from '../FieldCell'
import { useCogfy } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { UUID } from '@indigohive/cogfy-types'

export function ErrorCell (props: FieldCellProps) {
  const { field, record } = props
  const cogfy = useCogfy()

  const errorMessage = record.properties[field.id]?.error?.message

  const calculateRecords = useMutation({
    mutationFn: (data: { fieldId: UUID, recordId: UUID }) => cogfy.calculateRecords({ fieldId: data.fieldId, recordIds: [data.recordId] })
  })

  return (
    <div className="flex items-center justify-between bg-error bg-opacity-10 px-2 py-1 w-full h-full">
      <em>Error</em>
      <div className="flex gap-1 items-center">
        <span className="tooltip tooltip-left" data-tip="Recalculate">
          <Button
            onClick={event => {
              event.stopPropagation()
              calculateRecords.mutate({ fieldId: field.id, recordId: record.id })
            }}
            size="xs"
            ghost
          >
            <RefreshCw size={16} />
          </Button>
        </span>
        <span className={clsx(errorMessage && 'tooltip tooltip-left')} data-tip={errorMessage}>
          <CircleAlert size={16} className="text-error" />
        </span>
      </div>
    </div>
  )
}
