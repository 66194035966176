import { Link } from 'react-router-dom'
import { Breadcrumbs, FormattedDate, Navbar, SimpleIcon, Skeleton } from '../../../../components'
import { CalendarClock, ChevronLeftIcon } from 'lucide-react'
import { GetChatByIdResult } from '@indigohive/cogfy-types/endpoints/getChatById'
import { siWhatsapp } from 'simple-icons'
import clsx from 'clsx'
import { formatPhoneNumber } from '../../../../helpers'
import { useTranslation } from 'react-i18next'

export type ScheduledMessagesPageNavBarProps = {
  chat?: GetChatByIdResult
  workspace?: string
  loading?: boolean
}

export function ScheduledMessagesPageNavBar (props: ScheduledMessagesPageNavBarProps) {
  const { chat, workspace, loading } = props

  const { t } = useTranslation()

  return (
    <Navbar>
      <div>
        <Link
          to={`/${workspace}/${chat?.collectionId}`}
        >
          <ChevronLeftIcon size={20} />
        </Link>
      </div>
      <div
        className={clsx(
          'mx-2',
          'rounded-full',
          'w-8',
          'h-8',
          'flex',
          'justify-center',
          'items-center',
          'bg-[#E5FBE5]'
        )}
      >
        <SimpleIcon icon={siWhatsapp} size={16} />
      </div>
      <Breadcrumbs
        items={[
          {
            label: loading ? <Skeleton className="w-32 h-4" /> : <Breadcrumb {...props} />,
            to: `${`/${workspace}/chats/${chat?.id}`}`
          },
          {
            icon: <CalendarClock size={16} />,
            label: t('scheduledMessagesPage:Schedule messages')
          }
        ]}
      />
    </Navbar>
  )
}

const Breadcrumb = (props: ScheduledMessagesPageNavBarProps) => {
  const whatsApp = props.chat?.data.whatsApp

  return (
    <>
      <span className="font-semibold">{whatsApp?.contactName}: {formatPhoneNumber(whatsApp?.contactPhoneNumber ?? '')}</span>
      <span className="text-gray-600"><FormattedDate date={props.chat?.createDate} format="Ppp" /></span>
    </>
  )
}
