import { CollectionDeletedEvent, CollectionRenamedEvent, TransactionEvent } from '@indigohive/cogfy-types/events/transaction'
import { RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { BaseClient, BaseClientOptions } from './base-client'
import { SocketEvent } from '../socket'

export class TransactionsClient extends BaseClient {
  onCollectionDeleted: SocketEvent<CollectionDeletedEvent>
  onCollectionRenamed: SocketEvent<CollectionRenamedEvent>

  constructor (options: BaseClientOptions) {
    super(options)

    this.onCollectionDeleted = new SocketEvent(this.socket, 'collection_deleted')
    this.onCollectionRenamed = new SocketEvent(this.socket, 'collection_name_updated')
  }

  onTransaction (collectionId: UUID, listener: (event: TransactionEvent) => void) {
    this.socket.emit('join', `collection:${collectionId}`)
    this.socket.on('transactions', listener)
  }

  offTransaction (collectionId: UUID, listener: (event: TransactionEvent) => void) {
    this.socket.emit('leave', `collection:${collectionId}`)
    this.socket.off('transactions', listener)
  }

  async create (
    data: RunTransactionCommand,
    signal?: AbortSignal
  ): Promise<void> {
    await this.axios.post('/transactions', data, { signal })
  }
}
