import React, { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export type FilteredMenuProps = {
  onChange: (event: React.ChangeEvent<HTMLSelectElement> | { target: { value: string } }) => void
  options: { value: string, label: ReactNode, disabled?: boolean }[]
  setSelectEl: (el: HTMLDivElement | null) => void
  searchName: string
}

export function FilteredMenu (props: FilteredMenuProps) {
  const { onChange, options, setSelectEl } = props
  const { t } = useTranslation()

  const filteredOptions = options.filter(option => option?.label?.toString().toLowerCase().includes(props.searchName.toLowerCase()))

  return (
    <Fragment>
      {filteredOptions.length === 0 && (
        <li>
          <a>
            {t('No options found')}
          </a>
        </li>
      )}
      {filteredOptions.map(option => (
        <li
          key={option.value}
          value={option.value}
          onClick={() => {
            onChange({ target: { value: option.value } })
            setSelectEl(null)
          }}
        >
          <a>
            {option.label}
          </a>
        </li>
      ))}
    </Fragment>
  )
}
