import clsx, { ClassValue } from 'clsx'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import { useEffect, useRef, useState, ReactNode } from 'react'
import { Button } from '../Button'
import { Skeleton } from '../Skeleton'
import { Tab } from './components/Tab'
import { CollectionStateView } from '../../lib'
import { CollectionPageController } from '../../pages/CollectionPageV2/collection-page-controller'

export type TabType<T> = {
  value?: T
  label: ReactNode
  disabled?: boolean
}

export type TabsProps<T> = {
  tabs: TabType<T>[]
  activeTab: T
  onTabClick?: (tab: T) => void
  variant?: 'default' | 'bordered' | 'lifted' | 'boxed'
  size?: 'xs' | 'sm' | 'md' | 'lg'
  className?: ClassValue
  onContextMenu?: (event: React.MouseEvent, tab: TabType<T>) => void
  loading?: boolean
  controller: CollectionPageController
}

export type DraggedView = {
  view: CollectionStateView
  change: number
}

export function Tabs<T extends string> (props: TabsProps<T>) {
  const { tabs, activeTab, className, loading, controller } = props
  const variant = props.variant ?? 'default'
  const size = props.size ?? 'md'
  const ref = useRef<HTMLDivElement>(null)
  const [isOverflowed, setIsOverflowed] = useState(false)

  useEffect(() => {
    if (ref.current) {
      const { scrollWidth, clientWidth } = ref.current
      setIsOverflowed(scrollWidth > clientWidth)
    }
  }, [tabs])

  return (
    <>
      {isOverflowed && (
        <Button
          ghost
          square
          size="xs"
          className="mx-1"
          onClick={() => ref.current?.scrollBy({ left: -200, behavior: 'smooth' })}
        >
          <ChevronLeftIcon size={16} />
        </Button>
      )}
      <div
        ref={ref}
        role="tablist"
        className={clsx(
          'tabs',
          variant === 'bordered' && 'tabs-bordered',
          variant === 'lifted' && 'tabs-lifted',
          variant === 'boxed' && 'tabs-boxed',
          size === 'xs' && 'tabs-xs',
          size === 'sm' && 'tabs-sm',
          size === 'md' && 'tabs-md',
          size === 'lg' && 'tabs-lg',
          'overflow-x-auto',
          'no-scrollbar',
          className
        )}
      >
        {loading && new Array(3).fill(0).map((_, index) => (
          <a
            role="tab"
            key={`tab-loading-${index}`}
            className="tab"
            style={{ borderBottomWidth: 0 }}
          >
            <Skeleton className="h-2 w-12" key={index} />
          </a>
        ))}
        {!loading && tabs.map(tab => (
          <Tab<T>
            key={tab.value}
            tab={tab}
            activeTab={activeTab}
            onTabClick={tab => props.onTabClick?.(tab)}
            onContextMenu={props.onContextMenu}
            canDrag={true}
            controller={controller}
          />
        ))}
      </div>
      {isOverflowed && (
        <Button
          ghost
          square
          size="xs"
          className="mx-1"
          onClick={() => ref.current?.scrollBy({ left: 200, behavior: 'smooth' })}
        >
          <ChevronRightIcon size={16} />
        </Button>
      )}
    </>
  )
}
