import { Provider, ProviderName, providers } from '@indigohive/cogfy-providers'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useCogfy, useTitle, useToasts, useWorkspace } from '../../hooks'
import { Button, ConfirmDeleteDialog, Container, SettingsPagesHeader } from '../../components'
import { ConnectionSection } from './components'
import { WandSparkles } from 'lucide-react'
import { CreateConnectionDialog } from './components/CreateConnectionDialog'
import { GetConnectionsListResultData } from '@indigohive/cogfy-types/endpoints/getConnectionsList'
import { UUID } from '@indigohive/cogfy-types'

const AI_PROVIDERS = new Set<ProviderName>([
  'anthropic',
  'aws',
  'azure',
  'googleAIStudio',
  'grok',
  'groq',
  'huggingface',
  'openai'
])

const USE_CUSTOM_DIALOG = new Set<ProviderName>([
  'aws',
  'googleCalendar',
  'http',
  'whatsapp'
])

export function WorkspaceConnectionsPage () {
  const params = useParams<{ workspace: string }>()
  const cogfy = useCogfy()
  const getWorkspace = useWorkspace(params.workspace!)
  const { t } = useTranslation()
  const location = useLocation()
  const toasts = useToasts()
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [selectedConnection, setSelectedConnection] = useState<UUID | null>(null)
  const [providerToAdd, setProviderToAdd] = useState<Provider | null>(null)
  const [selectedPage, setSelectedPage] = useState<'AI' | 'services'>('AI')

  const deleteConnection = useMutation({
    mutationFn: async () => {
      if (selectedConnection) {
        await cogfy.deleteConnection({ connectionId: selectedConnection })
      }
    },
    onSuccess: () => {
      toasts.info(t('WorkspaceConnectionsPage:Delete connection successfully'))
      getConnections.refetch()
      setOpenDeleteDialog(false)
    },
    onError: () => {
      toasts.error(t('WorkspaceConnectionsPage:Error to delete connection'))
    }
  })

  const getConnections = useQuery({
    queryKey: ['getConnections'],
    queryFn: ({ signal }) => cogfy.getConnectionsList({}, { signal })
  })

  const connectionsByProvider = getConnections.data?.data
    .reduce<Partial<Record<string, GetConnectionsListResultData[]>>>(
    (acc, connection) => {
      if (!acc[connection.provider]) {
        acc[connection.provider] = []
      }

      acc[connection.provider]?.push(connection)

      return acc
    }, {}) ?? {}

  useTitle({
    title: t('Connections'),
    loading: getWorkspace.isLoading,
    error: getWorkspace.isError
  })

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash)

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location.hash])

  return (
    <div className="max-h-screen">
      <SettingsPagesHeader
        title={t('Connections')}
        description={t('WorkspaceConnectionsPage:Configure and customize connections with external services')}
      >
        <WandSparkles size={46} />
      </SettingsPagesHeader>
      <Container className="py-4">
        <div className='pl-14 flex gap-2'>
          <Button
            ghost
            className={`${selectedPage === 'AI' && 'bg-[#D9E7FE] text-[#2477D1]'}`}
            onClick={() => setSelectedPage('AI')}
          >
            <span className=''>{t('WorkspaceConnectionsPage:AI Providers')}</span>
          </Button>
          <Button
            ghost
            className={`${selectedPage === 'services' && 'bg-[#D9E7FE] text-[#2477D1]'}`}
            onClick={() => setSelectedPage('services')}
          >
            <span>{t('WorkspaceConnectionsPage:Service Providers')}</span>
          </Button>
        </div>
        <CreateConnectionDialog
          provider={providerToAdd}
          open={Boolean(providerToAdd)}
          onClose={() => setProviderToAdd(null)}
          onSuccess={() => {
            setProviderToAdd(null)
            getConnections.refetch()
          }}
        />
        <div className="max-w-screen-sm flex flex-col items-center space-y-12 my-12 mx-auto">
          {!getConnections.data && !getConnections.isLoading && (
            <span className='self-start pl-16'>{t('Error when searching connections')}</span>
          )}
          {getConnections.isLoading && <progress className="progress w-1/3" />}
          {getConnections.data &&
            providers
              .filter(provider => selectedPage === 'AI'
                ? AI_PROVIDERS.has(provider.name)
                : !AI_PROVIDERS.has(provider.name))
              .map(provider => (
                <ConnectionSection
                  key={provider.name}
                  onConnectClick={USE_CUSTOM_DIALOG.has(provider.name)
                    ? undefined
                    : provider => { setProviderToAdd(provider) }
                  }
                  onDeleteClick={(id) => {
                    setSelectedConnection(id)
                    setOpenDeleteDialog(true)
                  }}
                  disableDelete={deleteConnection.isPending}
                  provider={provider}
                  connections={connectionsByProvider[provider.name] ?? []}
                  onRefetch={() => { getConnections.refetch() }}
                />
              ))
          }
        </div>
      </Container>
      <ConfirmDeleteDialog
        title={t('WorkspaceConnectionsPage:Delete selected connection?')}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={deleteConnection.mutate}
      />
    </div>
  )
}
