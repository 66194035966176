import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { useFormik } from 'formik'
import { GlobeIcon } from 'lucide-react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, CogfyLogo, Input, Select } from '../../components'
import { useAuthentication, useCogfy, useTitle, useToasts } from '../../hooks'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import * as yup from 'yup'
import i18n from 'i18next'
import { SignInGoogleCommand, SignInResult } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'

const localeOptions = [
  { value: 'pt-BR', label: 'Português (Brasil)' },
  { value: 'en', label: 'English' }
]

export function SignInPage () {
  const cogfy = useCogfy()
  const navigate = useNavigate()
  const authentication = useAuthentication()
  const [locale, setLocale] = useState('en')
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const lastWorkspaceVisited = window.localStorage.getItem('last_workspace_visited')

  const handleSignInGoogle = useMutation({
    mutationFn: (data: SignInGoogleCommand) => cogfy.authentication.signInGoogle(data),
    onSuccess: async (data) => {
      await handleAfterSignIn(data)
    },
    onError: (error) => {
      if (cogfy.isNotFound(error)) {
        toasts.error(t('User not found'))
      }
    }
  })

  const handleAfterSignIn = async (authenticatedUser: SignInResult) => {
    if (authenticatedUser.type === 'totp' && authenticatedUser.totpAccessToken) {
      if (authenticatedUser.totpEnabled) {
        navigate('/totp-sign-in', {
          state: {
            accessToken: authenticatedUser.totpAccessToken
          }
        })
        return
      } else if (authenticatedUser.totpRequired) {
        navigate('/totp-setup', {
          state: {
            accessToken: authenticatedUser.totpAccessToken
          }
        })
        return
      }
    }

    authentication.onAuthenticatedUserChange?.(authenticatedUser)

    const workspaces = await cogfy.getWorkspaces()

    const redirectToParam = searchParams.get('redirectTo')
    const workspaceSlug = redirectToParam?.match(/^\/([^/]*)\/(.*)$/)?.[1] ?? ''

    const hasAccessToWorkspace = workspaces.data.some(workspace => workspace.slug === workspaceSlug)
    const hasAccessTolastWorkspaceVisited = workspaces.data.some(workspace => workspace.slug === lastWorkspaceVisited)

    if (redirectToParam && hasAccessToWorkspace) {
      navigate(redirectToParam)
    } else if (lastWorkspaceVisited && hasAccessTolastWorkspaceVisited) {
      navigate('/' + lastWorkspaceVisited)
    } else if (workspaces.data.length === 1) {
      navigate('/' + workspaces.data[0].slug)
    } else {
      navigate('/')
    }
  }

  useEffect(() => {
    if (authentication.authenticatedUser) {
      navigate('/', { replace: true })
    }
  }, [authentication])

  const toasts = useToasts()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: yup.object().shape({
      email: yup.string().email(t('Invalid email')).required(t('Required field')),
      password: yup.string().required(t('Required field'))
    }),
    onSubmit: async values => {
      try {
        const authenticatedUser = await cogfy.authentication.signIn(values)

        await handleAfterSignIn(authenticatedUser)
      } catch (error) {
        if (cogfy.isUnauthorized(error) || cogfy.isNotFound(error)) {
          toasts.error('Invalid credentials')
        } else {
          toasts.error('Unexpected error')
        }
      }
    }
  })

  useEffect(() => {
    formik.validateForm()
  }, [i18n.language])

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale])

  useTitle({ title: t('Sign in') })

  return (
    <GoogleOAuthProvider
      clientId={import.meta.env.VITE_COGFY_GOOGLE_CLIENT_ID}
    >
      <div className="w-full flex flex-col items-center">
        <div className="navbar max-w-screen-xl">
          <CogfyLogo size={48} />
          <div className='pl-4'>
            <GlobeIcon size={20} />
            <Select
              options={localeOptions}
              size="sm"
              value={locale}
              onChange={event => setLocale(event.target.value)}
              className={'border-none pl-2'}
            />
          </div>
        </div>
        <div className="w-96 p-6 mt-[8vh] mb-[16vh]">
          <div className="w-96 p-6 mt-[8vh] mb-[16vh]">
            <form onSubmit={formik.handleSubmit}>
              <h1 className="text-2xl font-semibold mb-2">
                {t('SignIn:Welcome back')}
              </h1>
              <p className="text-xl font-semibold text-gray-400 mb-8">
                <Trans
                  i18nKey={t('SignIn:Log in to your Cogfy account')}
                  components={{
                    cogfy: (
                      <span className="bg-gradient-to-r from-[#FF85E3] to-[#FBC97F] text-transparent bg-clip-text" />
                    )
                  }}
                />
              </p>
              <Input
                disabled={formik.isSubmitting}
                label="Email"
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={(formik.touched.email && formik.errors.email) ?? ''}
                {...formik.getFieldProps('email')}
              />
              <Input
                type="password"
                disabled={formik.isSubmitting}
                label={t('Password')}
                error={Boolean(formik.touched.password && formik.errors.password)}
                helperText={(formik.touched.password && formik.errors.password) ?? ''}
                {...formik.getFieldProps('password')}
              />
              <Button
                type="submit"
                color="primary"
                className="w-full mt-4"
                disabled={formik.isSubmitting}
              >
                {t('Sign in')}
              </Button>
            </form>
            <div className="text-right pt-4">
              <Link to="/forgot-password" className="link">
                {t('Forgot password')}
              </Link>
            </div>
            <div className="divider" />
            <div className="flex justify-center">
              <GoogleLogin
                onSuccess={credentialResponse => {
                  handleSignInGoogle.mutate(credentialResponse)
                }}
                onError={() => {
                  toasts.error('Google sign in failed')
                }}
                locale={locale}
              />
            </div>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  )
}
