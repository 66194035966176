import { SettingsIcon } from 'lucide-react'
import { Menu } from '../../../Menu'
import { DrawerAuthenticatedUserView } from '../DrawerAuthenticatedUserView'
import { DrawerWorkspacesPicker } from '../DrawerWorkspacesPicker'
import { useTranslation } from 'react-i18next'
import { GetWorkspaceBySlugResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceBySlug'
import { GetWorkspacesResult } from '@indigohive/cogfy-types/endpoints/getWorkspaces'

export type DrawerWorkspacesMenuProps = {
  drawerWidth?: number
  workspaces?: GetWorkspacesResult
  currentWorkspace?: GetWorkspaceBySlugResult
  onSettingsClick?: () => void
  onClosePicker?: () => void
}

export function DrawerWorkspacesMenu (props: DrawerWorkspacesMenuProps) {
  const { drawerWidth, workspaces, currentWorkspace } = props

  const { t } = useTranslation()

  return (
    <Menu
      className="p-0 pb-1 overflow-y-hidden max-w-full"
      style={{ width: (drawerWidth ?? 0) - 20, minWidth: 288 }}
    >
      <DrawerWorkspacesPicker
        workspaces={workspaces}
        currentWorkspace={currentWorkspace}
        onClose={() => props.onClosePicker?.()}
      />
      <DrawerAuthenticatedUserView />
      <ul className="menu p-0">
        <li className="px-1">
          <a
            onClick={() => { props.onSettingsClick?.() }}
          >
            <SettingsIcon size={16} />
            {t('Settings')}
          </a>
        </li>
      </ul>
    </Menu>
  )
}
