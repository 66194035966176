import {
  BookmarkIcon,
  CopyIcon,
  EyeOffIcon,
  TrashIcon,
  PencilIcon,
  RefreshCwIcon,
  UploadIcon
} from 'lucide-react'
import { FieldType, UUID } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Menu } from '../../../../components'
import { FieldMenuSort } from '../../../../field-types'

export type EditFieldMenuProps = {
  field?: {
    id: UUID
    type: FieldType
  }
  onUploadFiles?: (files: File[], fieldId: UUID) => void
  onDeleteClick?: (fieldId: UUID) => void
  onDuplicateClick?: (fieldId: UUID) => void
  onEditClick?: (fieldId: UUID) => void
  onHideClick?: (fieldId: UUID) => void
  onRecalculateClick?: (fieldId: UUID) => void
  onSetAsTitleClick?: (fieldId: UUID) => void
  onSortClick?: (fieldId: UUID, direction: 'asc' | 'desc') => void
}

export function EditFieldMenu (props: EditFieldMenuProps) {
  const {
    field,
    onUploadFiles,
    onDeleteClick,
    onDuplicateClick,
    onEditClick,
    onHideClick,
    onRecalculateClick,
    onSetAsTitleClick,
    onSortClick
  } = props

  const { t } = useTranslation()

  return (
    <Menu>
      {field?.type === 'file' && (
        <li>
          <label>
            <input
              type="file"
              className="hidden"
              multiple
              onChange={({ target }) => {
                if (target.files) {
                  onUploadFiles?.(Array.from(target.files), field.id)
                }
              }}
            />
            <UploadIcon size={16} />
            {t('collectionFieldMenu:Upload files')}
          </label>
        </li>
      )}

      {onSetAsTitleClick && field?.type === 'text' && (
        <li>
          <a onClick={() => onSetAsTitleClick(field.id)}>
            <BookmarkIcon size={16} />
            {t('collectionFieldMenu:Set as title field')}
          </a>
        </li>
      )}

      {onEditClick && field && (
        <li>
          <a onClick={() => onEditClick(field.id)}>
            <PencilIcon size={16} />
            {t('common:Edit field')}
          </a>
        </li>
      )}

      {onHideClick && field && (
        <li>
          <a onClick={() => onHideClick(field.id)}>
            <EyeOffIcon size={16} />
            {t('collectionFieldMenu:Hide field')}
          </a>
        </li>
      )}

      {onSortClick && field && (
        <FieldMenuSort
          field={field}
          onSortClick={(fieldId, direction) => onSortClick(fieldId, direction)}
        />
      )}

      <li className={clsx(!onRecalculateClick && 'disabled')}>
        <a onClick={() => field && onRecalculateClick?.(field.id)}>
          <RefreshCwIcon size={16} />
          {t('collectionFieldMenu:Recalculate selected records')}
        </a>
      </li>

      {onDuplicateClick && field && (
        <li>
          <a onClick={() => onDuplicateClick(field.id)}>
            <CopyIcon size={16} />
            {t('common:Duplicate')}
          </a>
        </li>
      )}

      {onDeleteClick && field && (
        <li className="hover:text-red-400">
          <a onClick={() => onDeleteClick(field.id)}>
            <TrashIcon size={16} />
            {t('common:Delete')}
          </a>
        </li>
      )}
    </Menu>
  )
}
