import { GetRecordByIdResult } from '@indigohive/cogfy-types/endpoints/getRecordById'
import { GetCollectionFieldsResultData } from '@indigohive/cogfy-types/endpoints/getCollectionFields'
import type {
  AuthorRecordProperty,
  ChatRecordProperty,
  CreateDateRecordProperty,
  DateRecordProperty,
  FileRecordProperty,
  ReferenceRecordProperty,
  UpdateDateRecordProperty,
  UpdateRecordPropertiesOperationData,
  UUID,
  VectorRecordProperty
} from '@indigohive/cogfy-types'
import { FormattedDate } from '../../components'
import { Link } from 'react-router-dom'
import { useWorkspaceSlug } from '../../hooks'
import { useTranslation } from 'react-i18next'
import {
  BooleanRecordPropertyInput,
  NumberRecordPropertyInput,
  JsonRecordPropertyInput,
  TextRecordPropertyInput,
  SelectRecordPropertyInput
} from './components'

export type RecordPropertyContentProps = {
  canEdit: boolean
  collectionId: UUID
  field: GetCollectionFieldsResultData
  record: GetRecordByIdResult
  onUpdateRecordProperties?: (data: UpdateRecordPropertiesOperationData) => void
}

function EmptyFallback () {
  const { t } = useTranslation()

  return (
    <span className="opacity-40">
      {t('RecordPage:Empty')}
    </span>
  )
}

export function RecordPropertyContent (props: RecordPropertyContentProps) {
  const { canEdit, collectionId, field, record } = props

  const workspaceSlug = useWorkspaceSlug()

  const property = record.properties[field.id]

  if (!property) {
    return (
      <div className="my-1 text-sm">
        <EmptyFallback />
      </div>
    )
  }

  return (
    <div className="my-1 text-sm max-w-full">
      {field.type === 'text' && (
        <TextRecordPropertyInput
          field={field}
          record={record}
          collectionId={collectionId}
          onUpdateRecordProperties={props.onUpdateRecordProperties}
          canEdit={canEdit}
        />
      )}

      {field.type === 'number' && (
        <NumberRecordPropertyInput
          field={field}
          record={record}
          collectionId={collectionId}
          onUpdateRecordProperties={props.onUpdateRecordProperties}
          canEdit={canEdit}
        />
      )}

      {field.type === 'boolean' && (
        <BooleanRecordPropertyInput
          field={field}
          record={record}
          collectionId={collectionId}
          onUpdateRecordProperties={props.onUpdateRecordProperties}
          canEdit={canEdit}
        />
      )}

      {field.type === 'json' && (
        <JsonRecordPropertyInput
          field={field}
          record={record}
          collectionId={collectionId}
          onUpdateRecordProperties={props.onUpdateRecordProperties}
          canEdit={canEdit}
        />
      )}

      {field.type === 'date' && (
        <>
          {!(property as DateRecordProperty).date?.value && <EmptyFallback />}
          <FormattedDate
            date={(property as DateRecordProperty).date?.value}
            format={field.data.date?.format ?? 'Pp'}
          />
        </>
      )}

      {field.type === 'select' && (
        <SelectRecordPropertyInput
          field={field}
          record={record}
          collectionId={collectionId}
          onUpdateRecordProperties={props.onUpdateRecordProperties}
          canEdit={canEdit}
        />
      )}

      {field.type === 'file' && (
        <div>
          {(property as FileRecordProperty)?.file?.value?.name}
        </div>
      )}

      {field.type === 'reference' && (
        <div>
          {(property as ReferenceRecordProperty)?.reference?.value
            .map(reference => (
              <div key={reference.id} className="py-0.5">
                <Link
                  to={`/${workspaceSlug}/${field.data.reference?.collectionId}/${reference.referencedRecordId}`}
                  className="link link-primary"
                >
                  {reference.title}
                </Link>
              </div>
            ))}
        </div>
      )}

      {field.type === 'author' && (
        (property as AuthorRecordProperty).author?.value?.name
      )}

      {field.type === 'createDate' && (
        <FormattedDate
          date={(property as CreateDateRecordProperty).createDate?.value}
          format={field.data.createDate?.format ?? 'Pp'}
        />
      )}

      {field.type === 'updateDate' && (
        <FormattedDate
          date={(property as UpdateDateRecordProperty).updateDate?.value}
          format={field.data.updateDate?.format ?? 'Pp'}
        />
      )}

      {field.type === 'vector' && (
        (property as VectorRecordProperty).vector?.value?.count ?? <EmptyFallback />
      )}

      {field.type === 'chat' && (property as ChatRecordProperty).chat?.value?.chatId && (
        <Link
          to={`/${workspaceSlug}/chats/${(property as ChatRecordProperty).chat?.value?.chatId}`}
          className="link link-primary"
        >
          {(property as ChatRecordProperty).chat.value?.lastMessage?.content}
        </Link>
      )}
    </div>
  )
}
