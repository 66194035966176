import { ChatMessageRecordProperty } from '@indigohive/cogfy-types'
import { DEFAULT_FIELD_WIDTH } from '../../../../pages/CollectionPageV2/components'
import { FieldOverlayProps } from '../../FieldOverlay'
import { useMutation } from '@tanstack/react-query'
import { useCogfy } from '../../../../hooks'
import { TrashIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ConfirmDeleteDialog } from '../../../../components'
import { useState } from 'react'

const MIN_WIDTH = 200

export function ChatMessageOverlay (props: FieldOverlayProps) {
  const { state, viewField, record, field, onClose } = props

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const cogfy = useCogfy()
  const { t } = useTranslation('chatMessageOverlay')
  const property = record.properties?.[field.id] as ChatMessageRecordProperty | undefined
  const messageId = property?.chatMessage?.value?.id
  const messageStatus = property?.chatMessage?.value?.status
  const width = Math.max(viewField.config?.ui?.width ?? DEFAULT_FIELD_WIDTH, MIN_WIDTH)

  const deleteScheduledMessage = useMutation({
    mutationFn: async () => {
      if (messageId) {
        await cogfy.deleteScheduledMessage({ messageId, recordId: record.id, fieldId: field.id, collectionId: state.id })
      }
    },
    onSuccess: () => {
      setDeleteDialogOpen(false)
      onClose(null)
    }
  })

  if (!messageId || messageStatus !== 'scheduled') {
    return
  }

  return (
    <>
      <ConfirmDeleteDialog
        title={t('Delete scheduled message')}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => deleteScheduledMessage.mutate()}
      />
      <div
        className="menu bg-base-100 rounded-btn border shadow-lg over max-w-full"
        style={{ width }}
      >
        <li>
          <a onClick={() => setDeleteDialogOpen(true)}>
            <TrashIcon size={16} />
            {t('Delete scheduled message')}
          </a>
        </li>
      </div>
    </>

  )
}
