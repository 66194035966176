import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { Button, Input } from '../../components'
import { useCogfy, useTitle, useToasts } from '../../hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function ForgotPasswordPage () {
  const [sent, setSent] = useState(false)
  const cogfy = useCogfy()
  const toasts = useToasts()
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: async values => {
      try {
        await cogfy.forgotPassword(values)
        setSent(true)
      } catch (error) {
        if (cogfy.isNotFound(error)) {
          toasts.error('User not found')
        }
      }
    }
  })

  useTitle({
    title: t('Forgot password')
  })

  return (
    <div
      className="h-screen w-screen flex flex-col justify-center items-center bg-gray-100"
    >
      <div className="text-center w-96 border rounded-lg p-6 shadow-md bg-white">
        <h1 className="text-2xl font-bold text-primary">{t('Forgot password')}</h1>
        {!sent && (
          <form
            onSubmit={formik.handleSubmit}
            className="text-center"
          >
            <Input
              label="Email"
              disabled={formik.isSubmitting}
              {...formik.getFieldProps('email')}
            />
            <Button
              type="submit"
              color="primary"
              className="w-full mt-6"
              disabled={formik.isSubmitting}
            >
              {t('Confirm')}
            </Button>
          </form>
        )}
        {sent && (
          <div className="text-center py-4">
            <p className="text-gray-500">
              {t("forgotPasswordPage:We've sent you an email with instructions to reset your password")}.
            </p>
          </div>
        )}
        <div className="text-left pt-4">
          <Link className="link" to="/sign-in">
            {t('forgotPasswordPage:Back to sign in')}
          </Link>
        </div>
      </div>
    </div>
  )
}
