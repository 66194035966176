import { FieldType, UUID } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { MoveDownIcon, MoveUpIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export type FieldMenuSortType = typeof FieldMenuSort

export const fields = new Set<Partial<FieldType>>([
  'boolean',
  'chat',
  'createDate',
  'date',
  'file',
  'httpResponse',
  'number',
  'schedule',
  'text',
  'updateDate',
  'vindi.customer'
])

export type FieldMenuSortProps = {
  field: {
    id: UUID
    type: FieldType
  }
  onSortClick: (fieldId: UUID, direction: 'asc' | 'desc') => void
}

export function FieldMenuSort (props: FieldMenuSortProps) {
  const { field, onSortClick } = props

  const { t } = useTranslation()

  const fieldMenuSortComponent = fields.has(field.type)
  const disabled = !fieldMenuSortComponent

  return (
    <>
      <li className={clsx(disabled && 'disabled')}>
        <a
          onClick={() => !disabled && onSortClick?.(field.id, 'asc')}
        >
          <MoveUpIcon size={16} />
          {t('collectionFieldMenu:Sort ascending')}
        </a>
      </li>
      <li className={clsx(disabled && 'disabled')}>
        <a
          onClick={() => !disabled && onSortClick?.(field.id, 'desc')}
        >
          <MoveDownIcon className="w-4 h-4" />
          {t('collectionFieldMenu:Sort descending')}
        </a>
      </li>
    </>
  )
}
