import { FormInputProps } from '../../types'
import { MultiSelect } from '../../../MultiSelect'

export function MultipleOptionsTextFormInput (props: FormInputProps) {
  const values = props.value?.type === 'text' && Array.isArray(props.value.text.value)
    ? props.value.text.value
    : []

  const handleChange = (value: string) => {
    const newValues = values.includes(value)
      ? values.filter(v => v !== value)
      : [...values, value]

    props.onChange({ type: 'text', text: { value: newValues } })
  }

  const handleClear = () => {
    props.onChange({ type: 'text', text: { value: [] } })
  }

  const handleSelectAll = () => {
    props.onChange({
      type: 'text',
      text: { value: props.schema.options?.map(option => option.value) ?? [] }
    })
  }

  return (
    <MultiSelect
      labels={{
        selectItems: 'Select options',
        allItems: 'All selected',
        noResultsFound: 'No results found'
      }}
      options={props.schema.options ?? []}
      selected={values}
      onChange={value => { handleChange(value) }}
      onClearSelections={handleClear}
      onSelectAll={handleSelectAll}
    />
  )
}
