import { GetRecordByIdResult } from '@indigohive/cogfy-types/endpoints/getRecordById'
import { GetCollectionByIdResult, GetCollectionByIdResultParent } from '@indigohive/cogfy-types/endpoints/getCollectionById'
import type { TextRecordProperty } from '@indigohive/cogfy-types'
import { BreadcrumbItem, Breadcrumbs } from '../../../components'
import { FolderIcon, HomeIcon, TableIcon } from 'lucide-react'
import { useWorkspaceSlug } from '../../../hooks'
import { useTranslation } from 'react-i18next'

export type RecordPageBreadcrumbProps = {
  record?: GetRecordByIdResult
  collection?: GetCollectionByIdResult
}

export function RecordPageBreadcrumb (props: RecordPageBreadcrumbProps) {
  const { record, collection } = props
  const workspaceSlug = useWorkspaceSlug()
  const items: BreadcrumbItem[] = []
  const { t } = useTranslation()

  if (collection) {
    let curr: GetCollectionByIdResultParent | GetCollectionByIdResult | null = collection

    while (curr) {
      items.unshift({
        label: curr.name || <span className="opacity-40">{t('Untitled')}</span>,
        to: `/${workspaceSlug}/${curr.id}`,
        icon: curr.type === 'folder' ? <FolderIcon className="w-4 h-4" /> : <TableIcon className="w-4 h-4" />
      })

      curr = curr.parent
    }
  }

  const title = collection?.titleFieldId && (record?.properties[collection.titleFieldId] as TextRecordProperty | undefined)?.text?.value

  return (
    <Breadcrumbs
      items={[
        { label: t('Home'), to: `/${workspaceSlug}`, icon: <HomeIcon className="w-4 h-4" /> },
        ...items,
        { label: title || <span className="opacity-40">{t('Untitled')}</span> }
      ]}
    />
  )
}
