import { VindiSubscriptionRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../../FieldCell'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { OptionChip, OptionChipColorName } from '../../../../components'

const STATUS_TO_COLOR: Record<string, OptionChipColorName> = {
  active: 'green',
  inactive: 'yellow',
  delinquent: 'red'
}

export type VindiSubscriptionCellProps = Pick<FieldCellProps<VindiSubscriptionRecordProperty>, 'property' | 'textWrap'>

export function VindiSubscriptionCell (props: VindiSubscriptionCellProps) {
  const { property, textWrap } = props
  const { t } = useTranslation('VindiSubscriptionCell')

  const { planId, status, paymentMethod, externalId } = property?.['vindi.subscription'] ?? {}

  return (
    <>
      {property?.['vindi.subscription'] && (
        <Link
          to={`https://app.vindi.com.br/admin/subscriptions/${externalId}`}
          target='_blank'
          className={clsx(
            'h-full',
            'w-full',
            'px-2',
            'py-1',
            'hover:bg-base-200',
            'active:bg-base-300',
            'text-gray-600',
            textWrap && 'whitespace-pre-wrap',
            !textWrap && 'truncate'
          )}
        >
          <div className='flex flex-col gap-2'>
            <div className='flex items-center'>
              (#{externalId})&nbsp;
              <OptionChip
                color={STATUS_TO_COLOR[status!]}
                label={t(status!)}
              />
            </div>
            <div>
              {planId} - {t(paymentMethod!)}
            </div>
          </div>
        </Link>
      )}
    </>
  )
}
