import { TransactionEvent } from '@indigohive/cogfy-types/events/transaction'
import { FieldType, UUID } from '@indigohive/cogfy-types'
import { GetViewRecordsQuery } from '@indigohive/cogfy-types/endpoints/getViewRecords'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'
import { CollectionState } from '../lib'
import { useEffect } from 'react'

const FIELD_TYPES_REQUIRING_REFETCH: FieldType[] = [
  'chat',
  'chatMessage',
  'file'
]

export function useViewRecords (
  state: CollectionState,
  viewId: UUID | null,
  params: Omit<GetViewRecordsQuery, 'viewId'> = {}
) {
  const cogfy = useCogfy()

  const getViewRecords = useQuery({
    queryKey: ['queryViewRecords', viewId],
    queryFn: async ({ signal }) => {
      const result = await cogfy.getViewRecords(
        {
          ...params,
          viewId: viewId!,
          search: state.searchRecords
        },
        { signal }
      )
      state.setRecords(result.data)
      state.setCountRecords(result.totalSize)
      return result
    },
    enabled: Boolean(viewId)
  })
  const queryClient = useQueryClient()

  useEffect(() => {
    if (viewId) {
      getViewRecords.refetch()
    }
  }, [params.pageNumber])

  useEffect(() => {
    const collectionId = state.id

    const handleTransaction = (event: TransactionEvent) => {
      if ((event.type === 'view_order_by_updated' || event.type === 'view_filter_updated') && state.activeViewId === event.data.viewId) {
        getViewRecords.refetch()
      }

      if (event.type === 'field_created') {
        const fieldType = event.data.type

        if (fieldType === 'author' || fieldType === 'createDate' || fieldType === 'updateDate') {
          getViewRecords.refetch()
        }
      }

      if (event.type === 'record_properties_updated') {
        const updatedProperties = Object.values(event.data.properties ?? {})

        const shouldRefetch = updatedProperties.some(property =>
          FIELD_TYPES_REQUIRING_REFETCH.includes(property.type) &&
          !property.pending
        )

        if (shouldRefetch) {
          getViewRecords.refetch()
        } else {
          state.updateRecord(event.data.id, event.data.properties)
        }
      }

      if (
        event.type === 'view_field_created' ||
        event.type === 'chat_created'
      ) {
        getViewRecords.refetch()
      }

      if (event.type === 'record_created' || event.type === 'record_referenced_created' || event.type === 'record_referenced_deleted') {
        const requiresRefetch = state.fields?.some(field =>
          field.type === 'author' ||
          field.type === 'createDate' ||
          field.type === 'updateDate'
        )

        if (requiresRefetch) {
          getViewRecords.refetch()
        }
      }

      if (event.type === 'scheduled_chat_message_deleted') {
        getViewRecords.refetch()
      }
    }

    const handleSearchRecordsChanged = () => {
      getViewRecords.refetch()
    }

    cogfy.transactions.onTransaction(collectionId, handleTransaction)
    state.addEventListener('searchRecordsChanged', handleSearchRecordsChanged)

    return () => {
      cogfy.transactions.offTransaction(collectionId, handleTransaction)
      state.removeEventListener('searchRecordsChanged', handleSearchRecordsChanged)
    }
  }, [queryClient, state])

  return getViewRecords
}
