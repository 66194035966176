import clsx, { ClassValue } from 'clsx'
import { memo, MutableRefObject, ReactNode, useRef } from 'react'
import { Link } from 'react-router-dom'
import { CollectionStateField, CollectionStateRecord, CollectionStateViewField } from '../../../../../lib'
import { CollectionPageController } from '../../../collection-page-controller'

export type CollectionTableCellProps = {
  className?: ClassValue
  record?: CollectionStateRecord
  viewField?: CollectionStateViewField
  field?: CollectionStateField
  row?: number
  col?: number
  active?: boolean
  selected?: boolean
  defaultWidth?: number
  controller?: CollectionPageController
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    record: CollectionStateRecord,
    field: CollectionStateField,
    viewField: CollectionStateViewField,
    rowIndex: number,
    colIndex: number
  ) => void
  onContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ref: MutableRefObject<HTMLDivElement | null>,
  ) => void
  render?: (
    record: CollectionStateRecord,
    field: CollectionStateField,
    viewField: CollectionStateViewField,
    controller: CollectionPageController,
    row: number,
    col: number,
    options?: { canEdit?: boolean }
  ) => ReactNode
  isOver?: boolean
  href?: string
  canEdit?: boolean
}

function _CollectionTableCell (props: CollectionTableCellProps) {
  const { className, record, viewField, field, row, col, active, selected, defaultWidth, onClick, onContextMenu, isOver, href, canEdit = false, controller } = props
  const width = (viewField?.config?.ui?.width ?? defaultWidth) + 'px'
  const cellRef = useRef<HTMLDivElement | null>(null)

  return (
    <div
      ref={cellRef}
      className={clsx(
        'flex',
        'items-start',
        'content-stretch',
        'overflow-x-hidden',
        'has-[.tooltip]:overflow-visible',
        'relative',
        canEdit && onClick && 'cursor-pointer',
        className
      )}
      style={{ width, minWidth: width }}
      data-row-index={row}
      data-col-index={col}
      onClick={event => canEdit && record && viewField && onClick?.(event, record, field!, viewField, row ?? -1, col ?? -1)}
      onContextMenu={e => onContextMenu?.(e, cellRef)}
    >
      {selected && (
        <div
          className={clsx(
            'w-full',
            'h-full',
            'bg-info',
            'absolute',
            'bg-opacity-10',
            active && 'border border-info'
          )}
        />
      )}
      {props.render?.(record!, field!, viewField!, controller!, row ?? -1, col ?? -1, { canEdit })}
      {isOver && href && (
        <Link
          className="absolute right-1 top-1 btn btn-xs bg-base-100"
          to={href}
        >
          Open
        </Link>
      )}
    </div>
  )
}

export const CollectionTableCell = memo(_CollectionTableCell)
