import { RunTransactionCommand } from '@indigohive/cogfy-types'
import { CollectionState, CollectionStateView } from '../collection-state'
import { Command } from '../command-stack'

export type ReorderViewAfterCommandData = {
  view: CollectionStateView
  after: CollectionStateView
}

export class ReorderViewAfterCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: ReorderViewAfterCommandData
  private readonly _previousIndex: number

  constructor (
    state: CollectionState,
    data: ReorderViewAfterCommandData
  ) {
    this._state = state
    this._data = data
    this._previousIndex = state.views!.findIndex(
      view => view.id === data.view.id
    )
  }

  run (): RunTransactionCommand {
    const newViews = [...this._state.views!]
    newViews.splice(this._previousIndex, 1)

    const newIndex = newViews.findIndex(view => view.id === this._data.after.id)
    newViews.splice(newIndex + 1, 0, this._data.view)
    this._state.setViews(newViews)

    return {
      operations: [
        {
          type: 'reorder_view_after',
          data: {
            collectionId: this._state.id,
            viewId: this._data.view.id,
            afterId: this._data.after.id
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newViews = [...this._state.views!]
    const currentIndex = newViews.findIndex(view => view.id === this._data.view.id)
    newViews.splice(currentIndex, 1)
    newViews.splice(this._previousIndex, 0, this._data.view)
    this._state.setViews(newViews)

    if (this._previousIndex === 0) {
      return {
        operations: [
          {
            type: 'reorder_view_before',
            data: {
              collectionId: this._state.id,
              viewId: this._data.view.id,
              beforeId: this._data.after.id
            }
          }
        ]
      }
    }

    return {
      operations: [
        {
          type: 'reorder_view_after',
          data: {
            collectionId: this._state.id,
            viewId: this._data.view.id,
            afterId: newViews[this._previousIndex - 1].id
          }
        }
      ]
    }
  }
}
