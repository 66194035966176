import { StripeCustomerRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../../FieldCell'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

export type StripeCustomerCellProps = Pick<FieldCellProps<StripeCustomerRecordProperty>, 'property' | 'textWrap'>

export function StripeCustomerCell (props: StripeCustomerCellProps) {
  const { property, textWrap } = props

  const { name, externalId } = property?.['stripe.customer'] ?? {}

  return (
    <>
      {property?.['stripe.customer'] && (
        <Link
          to={`https://dashboard.stripe.com/customers/${externalId}`}
          target='_blank'
          className={clsx(
            'h-full',
            'w-full',
            'px-2',
            'py-1',
            'hover:bg-base-200',
            'active:bg-base-300',
            'text-gray-600',
            textWrap && 'whitespace-pre-wrap',
            !textWrap && 'truncate'
          )}
        >
          <div className='flex'>
            {name} (#{externalId})
          </div>
        </Link>
      )}
    </>
  )
}
