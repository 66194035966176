import clsx from 'clsx'
import { Mention, MentionsInput } from 'react-mentions'

export type MentionsOption = {
  id: string
  name: string
}

export type MentionsValue = {
  parts: string[]
  options: MentionsOption[]
}

export type MentionsProps = {
  value: MentionsValue
  onChange: (value: MentionsValue) => void
  options: MentionsOption[]
  placeholder?: string
  error?: boolean
}

const defaultStyle = {
  control: {
    display: 'grid',
    padding: '0.25rem 0.75rem',
    position: 'relative'
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14
    },
    item: {
      padding: '4px 12px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5'
      }
    }
  }
} as const

const previousDefaultProps = Mention.defaultProps

Mention.defaultProps = undefined

function encode (value: MentionsValue, options: MentionsOption[]): string {
  let result = ''

  for (let i = 0; i < value.parts.length - 1; i++) {
    const part = value.parts[i]
    const option = value.options[i]

    result += part

    if (options.some(o => o.id === option.id)) {
      result += `@[${option.name}](id:${option.id})`
    } else {
      result += `@{${option.name}}(id:${option.id})`
    }
  }

  result += value.parts[value.parts.length - 1] || ''

  return result
}

function decode (value: string): MentionsValue {
  const result: MentionsValue = {
    parts: [],
    options: []
  }

  const match = value.matchAll(/@(\[([^\]]+)\]\(id:([^)]+)\)|\{([^}]+)\}\(id:([^)]+)\))/g)

  let index = 0

  for (const m of match) {
    const part = value.slice(index, m.index)
    const name = m[2] || m[4]
    const id = m[3] || m[5]

    result.parts.push(part)
    result.options.push({ id, name })

    index = m.index + m[0].length
  }

  result.parts.push(value.slice(index))

  return result
}

// First part
// (?:^|\s|.)
//   ?: non-capturing group
//   ^ start of the string
//   \s whitespace
//   . any character - This differs from the original implementation of the package
//                     https://github.com/signavio/react-mentions/blob/master/src/MentionsInput.js#L37C12-L41C6
// Second part
// (@([^@]*))$
//   @ the trigger character
//   ([^@]*) any character except the trigger character
//   $ end of the string
const trigger = /(?:^|\s|.)(@([^@]*))$/

export function Mentions (props: MentionsProps) {
  const { value, onChange, options, placeholder, error } = props

  return (
    <MentionsInput
      style={defaultStyle}
      placeholder={placeholder}
      className={clsx(
        'w-full',
        'text-sm',
        '[&_textarea]:py-1',
        '[&_textarea]:px-3',
        '[&_textarea]:border',
        '[&_textarea]:rounded-lg',
        '[&_textarea]:outline-offset-4',
        '[&_textarea]:outline-neutral-content',
        !error && '[&_textarea]:border-neutral-content',
        error && '[&_textarea]:border-error'
      )}
      value={encode(value, options)}
      onChange={event => {
        onChange(decode(event.target.value))
      }}
    >
      <Mention
        {...previousDefaultProps}
        trigger={trigger}
        markup="@[__display__](id:__id__)"
        className="bg-info bg-opacity-20 mt-[-40px]"
        data={options.map(option => ({
          id: option.id,
          display: option.name
        }))}
        displayTransform={(_id, display) => `@${display}`}
        renderSuggestion={(_suggestion, _search, highlightedDisplay) => (
          <div>
            {highlightedDisplay}
          </div>
        )}
      />
      <Mention
        {...previousDefaultProps}
        trigger={trigger}
        markup="@{__display__}(id:__id__)"
        className="bg-error bg-opacity-20 mt-[-40px]"
        data={[]}
        displayTransform={(_id, display) => `@${display}`}
      />
    </MentionsInput>
  )
}
