import { ChatRecordProperty } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { t } from 'i18next'
import { MessageSquareIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { useCogfy, useWorkspaceSlug } from '../../../hooks'
import { FieldCellProps } from '../FieldCell'
import { PreviewChatContent } from './PreviewChatContent'

export function ChatCell (props: FieldCellProps<ChatRecordProperty>) {
  const { collectionId, record, field, textWrap, property } = props
  const cogfy = useCogfy()
  const navigate = useNavigate()
  const workspace = useWorkspaceSlug()

  const lastMessage = property?.chat?.value?.lastMessage
  const date = lastMessage?.sendDate

  const handleCreateClick = async () => {
    await cogfy.createChat({ recordId: record.id, fieldId: field.id, collectionId }).then(newChatId => {
      navigate(`/${workspace}/chats/${newChatId.id}`)
    })
  }

  return (
    <>
      {lastMessage && (
        <PreviewChatContent
          to={`/${workspace}/chats/${property.chat.value?.chatId}`}
          date={date}
          lastMessage={lastMessage}
          textWrap={textWrap}
          chatProvider={property.chat.value?.provider}
        />
      )}
      {!lastMessage && (
        <div
          onClick={() => { handleCreateClick() }}
          className={clsx(
            'h-full',
            'w-full',
            'px-2',
            'py-1',
            'flex',
            'items-start',
            'hover:bg-base-200',
            'active:bg-base-300',
            'gap-1',
            'text-gray-600',
            !textWrap && 'truncate'
          )}
        >
          <div className="grow line-clamp-2">
            <span className="font-medium text-gray-600 inline-flex gap-1.5 items-center mr-1">
              <MessageSquareIcon size={12} />
              {t('Start chat')}
            </span>
          </div>
        </div>
      )}
    </>
  )
}
