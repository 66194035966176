import {
  BracesIcon,
  CalendarClockIcon,
  CalendarFoldIcon,
  CalendarIcon,
  CalendarPlusIcon,
  ChevronsLeftRightEllipsisIcon,
  CircleUserIcon,
  HashIcon,
  ListIcon,
  MailsIcon,
  MessageCircleIcon,
  MessagesSquareIcon,
  MoveUpRightIcon,
  PaperclipIcon,
  Share2Icon,
  SquareCheckIcon,
  TextIcon,
  TimerIcon,
  UserPlus,
  CircleDollarSign,
  ReceiptIcon,
  Globe,
  ReceiptTextIcon
} from 'lucide-react'
import { FieldType } from '@indigohive/cogfy-types'

type IconComponentType = typeof CircleUserIcon

export const FIELD_ICONS_BY_TYPE: Record<FieldType, IconComponentType> = {
  author: CircleUserIcon,
  boolean: SquareCheckIcon,
  chat: MessagesSquareIcon,
  chatMessage: MessageCircleIcon,
  connection: Share2Icon,
  createDate: CalendarPlusIcon,
  date: CalendarIcon,
  file: PaperclipIcon,
  httpResponse: Globe,
  json: BracesIcon,
  number: HashIcon,
  'nuvemFiscal.nfse': ReceiptTextIcon,
  reference: MoveUpRightIcon,
  schedule: CalendarClockIcon,
  select: ListIcon,
  'sendgrid.inboundEmail': MailsIcon,
  'stripe.customer': UserPlus,
  stopwatch: TimerIcon,
  text: TextIcon,
  updateDate: CalendarFoldIcon,
  vector: ChevronsLeftRightEllipsisIcon,
  'vindi.bill': ReceiptIcon,
  'vindi.customer': UserPlus,
  'vindi.subscription': CircleDollarSign
}
