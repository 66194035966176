import { FolderIcon, HomeIcon, PlusIcon, TableIcon } from 'lucide-react'
import { Link, useParams } from 'react-router-dom'
import { useCollections, usePermissions, useTitle, useUserEvent } from '../../hooks'
import { BreadcrumbItem, Breadcrumbs, Card, Container } from '../../components'
import { Navbar } from '../../components/Navbar'
import { useTranslation } from 'react-i18next'

export function CollectionsIndexPage () {
  const params = useParams<{ workspace: string }>()
  const workspace = params.workspace!
  const getCollectionsPage = useCollections({ parentId: null })
  const { t } = useTranslation()
  const permissions = usePermissions()
  const canCreateCollection = permissions.isAdmin
  const registerUserEvent = useUserEvent()

  useTitle({
    title: t('Collections'),
    loading: getCollectionsPage.isLoading,
    error: getCollectionsPage.isError
  })

  const breadcrumbItems: BreadcrumbItem[] = [
    {
      label: t('Home'),
      icon: <HomeIcon className="w-4 h-4" />
    }
  ]

  return (
    <>
      <Navbar>
        <Breadcrumbs items={breadcrumbItems} />
      </Navbar>
      <h1 className="text-2xl font-bold px-4 py-2 my-1 mx-2">{t('Collections')}</h1>
      <Container>
        {getCollectionsPage.isLoading && `${t('Loading')}...`}
        {getCollectionsPage.error && t('Error')}
        <Card>
          {getCollectionsPage.data?.data.length === 0 && (
            <ul className="menu">
              <li>
                <Link to={`/${workspace}/templates`}>
                  <PlusIcon className="w-5 h-5" />
                  {canCreateCollection ? t('Create new collection') : t('No collections found')}
                </Link>
              </li>
            </ul>
          )}
          {getCollectionsPage.data && getCollectionsPage.data.data.length > 0 && (
            <ul className="menu">
              {getCollectionsPage.data.data.map(collection => (
                <li key={collection.id}>
                  <Link
                    to={`/${workspace}/${collection.id}`}
                    onClick={() => {
                      registerUserEvent.mutate({
                        type: 'collection_selected',
                        data: {
                          collectionId: collection.id,
                          name: collection.name,
                          type: collection.type,
                          from: 'list'
                        }
                      })
                    }}
                  >
                    {collection.type === 'database'
                      ? <TableIcon size={16} />
                      : <FolderIcon size={16} />}
                    {collection.name ?? t('Untitled')}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </Card>
      </Container>
    </>
  )
}
