import { WEBHOOK_EVENTS } from '@indigohive/cogfy-common'
import { UUID, WebhookEvent } from '@indigohive/cogfy-types'
import { useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useBeforeUnload, useBlocker, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { Breadcrumbs, Button, Card, Container, Heading, Input, Navbar, Select } from '../../components'
import { useCogfy, useToasts, useWorkspaceSlug } from '../../hooks'

const warningMessage = 'You have unsaved changes. Are you sure you want to leave?'

export function CreateWebhookPage () {
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const workspace = useWorkspaceSlug()
  const navigate = useNavigate()
  const toasts = useToasts()

  const eventOptions = [
    { value: '', label: t('Select an event'), disabled: true },
    ...WEBHOOK_EVENTS.map(event => ({
      value: event,
      label: t(`webhooks:${event}`)
    }))
  ]

  const collectionOptions = useQuery({
    queryKey: ['getCollectionOptions', { type: 'database', pageSize: 100 }],
    queryFn: async () => {
      const getCollectionsPage = await cogfy.getCollections({ type: 'database' })
      return getCollectionsPage.data.map(collection => ({
        value: collection.id,
        label: collection.name ?? t('Untitled')
      }))
    }
  })

  const formik = useFormik({
    initialValues: {
      collectionId: '' as UUID,
      name: '',
      url: '',
      event: '' as WebhookEvent
    },
    validationSchema: yup.object().shape({
      collectionId: yup.string().required(t('createWebhookPage:Collection is a required field')),
      name: yup.string().optional(),
      url: yup.string().url(t('createWebhookPage:Must be a valid URL')).required(t('createWebhookPage:URL is a required field')),
      event: yup.string().required(t('createWebhookPage:Event is a required field'))
    }),
    onSubmit: async values => {
      try {
        await cogfy.createWebhook(values)
        toasts.success(t('Webhook created'))
        navigate(`/${workspace}/webhooks`)
      } catch {
        toasts.error(t('Error creating webhook'))
      }
    }
  })

  useBlocker(() => formik.dirty && formik.submitCount === 0 && !window.confirm(t(warningMessage)))
  useBeforeUnload(event => {
    if (formik.dirty) {
      event.returnValue = t(warningMessage)
      return t(warningMessage)
    }
  })

  return (
    <>
      <Navbar>
        <Breadcrumbs
          items={[
            { label: t('Webhooks'), to: `/${workspace}/webhooks` },
            { label: t('Create webhook') }
          ]}
        />
      </Navbar>
      <Container maxWidth="sm">
        <Heading className="mt-4 mb-2">
          {t('Create webhook')}
        </Heading>
        <Card>
          <form onSubmit={formik.handleSubmit} className="flex flex-col card-body">
            <Input
              label={t('Name')}
              disabled={formik.isSubmitting}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={(formik.touched.name && formik.errors.name) ?? ''}
              {...formik.getFieldProps('name')}
            />
            <Input
              label={t('URL')}
              disabled={formik.isSubmitting}
              error={Boolean(formik.touched.url && formik.errors.url)}
              helperText={(formik.touched.url && formik.errors.url) ?? ''}
              {...formik.getFieldProps('url')}
            />
            <Select
              label={t('Collection')}
              options={[
                { value: '', label: t('Select a collection'), disabled: true },
                ...(collectionOptions.data ?? [])
              ]}
              disabled={collectionOptions.isLoading || formik.isSubmitting}
              error={Boolean(formik.touched.collectionId && formik.errors.collectionId)}
              helperText={(formik.touched.collectionId && formik.errors.collectionId) ?? ''}
              {...formik.getFieldProps('collectionId')}
            />
            <Select
              label={t('Event')}
              options={eventOptions}
              disabled={formik.isSubmitting}
              error={Boolean(formik.touched.event && formik.errors.event)}
              helperText={(formik.touched.event && formik.errors.event) ?? ''}
              {...formik.getFieldProps('event')}
            />
            <Button type="submit" color="primary" className="mt-4">
              {t('Create webhook')}
            </Button>
          </form>
        </Card>
      </Container>
    </>
  )
}
