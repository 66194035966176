import { MessageSquareShare } from 'lucide-react'
import { WhatsAppContentProps } from './WhatsAppContent'
import { useTranslation } from 'react-i18next'

export function WhatsAppContentTemplate (props: WhatsAppContentProps) {
  const { contentData } = props

  const { t } = useTranslation('whatsAppMessageContent')
  const templateName = contentData?.whatsApp?.template?.name

  return (
    <div className="flex flex-row gap-2 p-2">
      <div className="w-fit p-2 rounded-lg bg-[#abf6db]">
        <MessageSquareShare size={20} className="text-[#125d42]" />
      </div>
      <div className="flex flex-col">
        <span className="text-sm">{t('Template message')}</span>
        <span className="text-xs text-gray-400">{templateName}</span>
      </div>
    </div>
  )
}
