import { GetScheduledMessagesResultData } from '@indigohive/cogfy-types/endpoints/getScheduledMessages'
import { Button, ConfirmDeleteDialog, FormattedDate } from '../../../../components'
import { useTranslation } from 'react-i18next'
import { TrashIcon } from 'lucide-react'
import { useCogfy } from '../../../../hooks'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { UUID } from '@indigohive/cogfy-types'

export type ScheduledMessageProps = {
  message: GetScheduledMessagesResultData
  collectionId?: UUID | null
  onDelete?: () => void
}

export function ScheduledMessage (props: ScheduledMessageProps) {
  const { message, collectionId, onDelete } = props

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const cogfy = useCogfy()
  const { t } = useTranslation('scheduledMessagesPage')

  const deleteScheduledMessage = useMutation({
    mutationFn: async () => {
      if (collectionId) {
        await cogfy.deleteScheduledMessage({ messageId: message.id, collectionId })
      }
    },
    onSuccess: () => {
      setDeleteDialogOpen(false)
      onDelete?.()
    }
  })

  return (
    <div className="group flex flex-row gap-1">
      <div className="card bg-base-100 shadow-md w-full">
        <div className="card-body">
          <p className="text-sm">{message.content}</p>
          <p className="font-light text-gray-400 text-sm">
            {t('Message scheduled for')}
            <FormattedDate
              date={message.scheduleDate}
              format={` dd/MM '${t('at')}' HH:mm`}
            />
          </p>
        </div>
      </div>
      <Button
        onClick={() => setDeleteDialogOpen(true)}
        className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover:text-error"
        ghost
        square
        size="sm"
      >
        <TrashIcon size={16} />
      </Button>

      <ConfirmDeleteDialog
        title={t('Delete scheduled message')}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => deleteScheduledMessage.mutate()}
      />
    </div>
  )
}
