import { Provider, ProviderName } from '@indigohive/cogfy-providers'
import { UUID } from '@indigohive/cogfy-types'
import { GetConnectionsListResultData } from '@indigohive/cogfy-types/endpoints/getConnectionsList'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { EllipsisIcon, PlusIcon, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Heading, Menu, OverlayContent, Toggle } from '../../../components'
import { useCogfy, usePermissions } from '../../../hooks'
import { AddAwsButton } from './AddAwsButton'
import { AddFacebookButton } from './AddFacebookButton'
import { AddHttpButton } from './AddHttpButton'
import { AddGoogleCalendarButton } from './AddGoogleCalendarButton'

const logoByProvider: Partial<Record<ProviderName, string>> = {
  anthropic: '/anthropic-avatar.webp',
  aws: '/awsbedrock-avatar.webp',
  azure: '/azure-avatar.webp',
  googleAIStudio: '/google-avatar.webp',
  googleCalendar: '/googlecalendar-avatar.webp',
  grok: '/grok-avatar.webp',
  groq: '/groq-avatar.webp',
  huggingface: '/huggingface-avatar.webp',
  http: '/http-avatar.webp',
  nuvemfiscal: '/nuvemfiscal-avatar.webp',
  openai: '/openai-avatar.webp',
  sendgrid: '/sendgrid-avatar.webp',
  serper: '/serper-avatar.webp',
  stripe: '/stripe-avatar.webp',
  typesense: '/typesense-avatar.webp',
  unstructured: '/unstructured-avatar.webp',
  vindi: '/vindi-avatar.webp',
  whatsapp: '/whatsapp-avatar.webp'
}

export type ConnectionSectionProps = {
  onConnectClick?: (provider: Provider) => void
  onDeleteClick?: (connectionId: UUID) => void
  provider: Provider
  connections: GetConnectionsListResultData[]
  onRefetch?: () => void
  disableDelete?: boolean
}

export function ConnectionSection (props: ConnectionSectionProps) {
  const { provider, connections, onRefetch, onDeleteClick, disableDelete } = props
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const permissions = usePermissions()
  const [openConnection, setOpenConnection] = useState<UUID | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [connectionToEdit, setConnectionToEdit] = useState<GetConnectionsListResultData | null>(null)

  const isAdmin = permissions.isAdmin
  const hasConnections = connections.length > 0
  const updateDefault = useMutation({
    mutationFn: async ({ _default }: { _default: boolean }) => {
      if (!connectionToEdit) return
      const connectionId = connectionToEdit.id
      await cogfy.updateConnectionDefault({ connectionId, default: _default })
      setAnchorEl(null)
      setConnectionToEdit(null)
      props.onRefetch?.()
    }
  })
  const updateEnabled = useMutation({
    mutationFn: async ({ connectionId, enabled }: { connectionId: UUID, enabled: boolean }) => {
      await cogfy.updateConnectionEnabled({ connectionId, enabled })
      props.onRefetch?.()
    }
  })

  return (
    <div className="w-full p-6 rounded-2xl bg-background-default">
      <div className="flex items-center px-2 mb-4">
        {logoByProvider[provider?.name] && (
          <img
            src={logoByProvider[provider?.name]}
            alt={`${provider?.label}-logo`}
            className="w-8 rounded-md"
          />
        )}
        <div className="grow flex flex-col gap-1">
          <Heading id={provider?.name} component="h2" className="pl-4">
            {provider?.label}
          </Heading>
          {provider.website && (
            <a
              className="link link-hover opacity-40 text-xs pl-4"
              target="_blank"
              rel="noreferrer"
              href={provider.website}
            >
              {provider.website}
            </a>
          )}
        </div>
        {provider?.name === 'aws' && (<AddAwsButton onSuccess={onRefetch} />)}
        {provider?.name === 'googleCalendar' && (<AddGoogleCalendarButton />)}
        {provider?.name === 'http' && (<AddHttpButton onSuccess={onRefetch} />)}
        {provider?.name === 'whatsapp' && (<AddFacebookButton onSuccess={onRefetch} />)}
        {props.onConnectClick && (
          <Button
            color="primary"
            size="sm"
            onClick={() => props.onConnectClick?.(provider)}
          >
            <PlusIcon size={16} />
            Connect
          </Button>
        )}
      </div>
      {!hasConnections && (
        <div className="text-sm px-2">
          {t('WorkspaceConnectionsPage:No connections set')}
        </div>
      )}
      {hasConnections && (
        <div className="flex flex-col gap-2">
          {connections.map(connection => (
            <div
              key={connection.id}
              tabIndex={0}
              className={clsx(
                'card',
                'card-bordered',
                'collapse',
                'bg-base-100',
                'border border-border-default',
                openConnection === connection.id ? 'collapse-open' : 'collapse-close h-16'
              )}
            >
              <div
                className="font-medium flex items-center cursor-pointer"
                onClick={() => setOpenConnection(openConnection === connection.id ? null : connection.id)}
              >
                <div className="flex items-center justify-between w-full px-4">
                  <div className="flex items-center gap-2">
                    <Toggle
                      color="primary"
                      checked={connection.enabled}
                      disabled={updateEnabled.isPending}
                      onChange={event => {
                        updateEnabled.mutate({ connectionId: connection.id, enabled: event.target.checked })
                      }}
                      onClick={event => event.stopPropagation()}
                    />
                    <div className={clsx('grow', !connection.enabled && 'opacity-40')}>
                      {connection.name}
                      &nbsp;
                      {connection.default && (
                        <span className="text-xs opacity-40">
                          ({t('Default')})
                        </span>
                      )}
                    </div>
                  </div>
                  {isAdmin && (
                    <div className='flex flex-row gap-1'>
                      <Button
                        ghost
                        square
                        size="sm"
                        onClick={event => {
                          event.stopPropagation()
                          setAnchorEl(event.currentTarget)
                          setConnectionToEdit(connection)
                        }}
                      >
                        <EllipsisIcon size={20} />
                      </Button>
                      <Button
                        ghost
                        square
                        size='sm'
                        className='text-red-500'
                        onClick={event => {
                          event.stopPropagation()
                          onDeleteClick?.(connection.id)
                        }}
                        disabled={disableDelete}
                      >
                        <Trash2 size={20} />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse-content">
                <ul>
                  {connection.values.map(value => (
                    <li key={value.id} className="flex items-center">
                      <span>{value.name}: </span>
                      <span>{value.plainTextValue ?? '********'}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
      <OverlayContent
        open={Boolean(connectionToEdit)}
        onClose={() => {
          setAnchorEl(null)
          setConnectionToEdit(null)
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Menu className={clsx(connectionToEdit?.default && '[&_li>*:not(ul):not(.menu-title):not(details):active]:bg-inherit')}>
          <li className={clsx(connectionToEdit?.default && 'disabled')}>
            <a
              className="font-bold"
              onClick={() => updateDefault.mutate({ _default: true })}
            >
              {t('WorkspaceConnectionsPage:Set as default')}
            </a>
          </li>
        </Menu>
      </OverlayContent>
    </div>
  )
}
