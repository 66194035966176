/* eslint-disable @typescript-eslint/unbound-method */
import {
  CollectionTableAddButton,
  CollectionTablePropertiesMenuButton,
  CollectionTableCell,
  CollectionTableFooter,
  CollectionTableHead,
  CollectionTableRow,
  CollectionTableRowCheckbox,
  CollectionTableCellProps
} from './subcomponents'
import clsx, { ClassValue } from 'clsx'
import { Ref, forwardRef, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Menu, OverlayContent, Skeleton } from '../../../../components'
import { FieldCell } from '../../../../field-types'
import { CollectionState, CollectionStateRange, CollectionStateRecord, CollectionStateViewField, useActiveViewId, useFieldsById, useLocked, useRecords, useSelectedCell, useSelectedRange, useSelectedRows, useViewFields } from '../../../../lib'
import { CollectionPageController } from '../../collection-page-controller'
import { useCogfy, useWorkspaceSlug } from '../../../../hooks'
import { GetUserCollectionPermissionsResult } from '@indigohive/cogfy-types/endpoints/getUserCollectionPermissions'
import { GetWorkspaceAssistantResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceAssistant'
import { RotateCcw } from 'lucide-react'
import { useMutation } from '@tanstack/react-query'
import { UUID } from '@indigohive/cogfy-types'

export type CollectionTableProps = {
  state: CollectionState
  controller: CollectionPageController
  className?: ClassValue
  disabledPreviousPage?: boolean
  disabledNextPage?: boolean
  loading?: boolean
  onAddFieldClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onPropertiesMenuButtonClick: () => void
  onPreviousPageClick?: () => void
  onNextPageClick?: () => void
  permissions?: GetUserCollectionPermissionsResult
  workspaceAssistant?: GetWorkspaceAssistantResult
}

function isInRange (range: CollectionStateRange, rowIndex: number, colIndex: number) {
  return (
    rowIndex >= range.start.row &&
    rowIndex <= range.end.row &&
    colIndex >= range.start.col &&
    colIndex <= range.end.col
  )
}

export type DraggedCol = {
  col: CollectionStateViewField
  change: number
}

export const DEFAULT_FIELD_WIDTH = 180
export const MIN_FIELD_WIDTH = 100

export const CollectionTable = forwardRef(
  function CollectionTable (
    props: CollectionTableProps,
    ref: Ref<HTMLDivElement>
  ) {
    const { controller, state, loading, permissions, workspaceAssistant } = props
    const { t } = useTranslation()

    const cogfy = useCogfy()
    const workspaceSlug = useWorkspaceSlug()
    const [over, setOver] = useState<CollectionStateRecord | null>(null)
    const locked = useLocked(state)
    const activeViewId = useActiveViewId(state)
    const fieldsById = useFieldsById(state)
    const viewFields = useViewFields(state)?.filter(viewField => viewField.viewId === activeViewId)
    const records = useRecords(state)
    const selectedCell = useSelectedCell(state)
    const selectedRange = useSelectedRange(state)
    const selectedRows = useSelectedRows(state)

    const [cellEl, setCellEl] = useState<HTMLDivElement | null>(null)
    const [hasOperation, setHasOperation] = useState(false)
    const [recalculateCellCommand, setRecalculateCommand] = useState<{ fieldId: UUID, recordId: UUID } | null>(null)

    const [draggedCol, setDraggedCol] = useState<DraggedCol | null>(null)

    const renderCell = useCallback<NonNullable<CollectionTableCellProps['render']>>(
      (record, field, viewField, controller, _row, _col, options) => (
        <FieldCell
          property={record.properties[field.id]}
          collectionId={state.id}
          record={record}
          field={{ id: field.id, type: field.type, data: field.data }}
          viewField={{ config: viewField.config }}
          controller={controller}
          readonly={!options?.canEdit}
          textWrap={viewField.config?.ui?.textWrap ?? undefined}
        />
      ),
      [controller]
    )

    const hasFullAccess = permissions?.type === 'full_access'
    const canEdit = hasFullAccess || permissions?.type === 'editor'

    const handleRecalculateCell = useMutation({
      mutationFn: async () => {
        if (!hasOperation) return
        if (!recalculateCellCommand || (recalculateCellCommand && Object.keys(recalculateCellCommand).length === 0)) return

        await cogfy.calculateRecords({
          fieldId: recalculateCellCommand.fieldId,
          recordIds: [recalculateCellCommand.recordId]
        })

        setCellEl(null)
        setRecalculateCommand(null)
      }
    })

    return (
      <>
        <div className='overflow-y-auto w-full flex flex-col'>
          <div
            ref={ref}
            className='overflow-auto outline-none w-full'
            tabIndex={0}
            onKeyDown={event => controller.onKeyDown(event)}
            onBlur={event => controller.onBlur(event)}
          >
            <div className="min-w-full">
              <div className="sticky top-0 z-[1] inline-flex flex-row border-y items-center text-sm min-h-9 h-9 box-border min-w-full bg-base-100">
                <label className="h-full flex items-center justify-center w-8 cursor-pointer">
                  <Checkbox
                    ref={ref => {
                      const someSelected = Object.keys(selectedRows).length > 0
                      const allSelected = Object.keys(selectedRows).length === records?.length
                      const indeterminate = someSelected && !allSelected
                      if (ref) {
                        ref.indeterminate = indeterminate
                      }
                    }}
                    size="sm"
                    color="info"
                    checked={Object.keys(selectedRows).length > 0}
                    onChange={controller.onHeadCheckboxChange}
                    disabled={loading}
                  />
                </label>
                {loading && new Array(5).fill(0).map((_, index) => (
                  <div
                    key={`collection-table-head-loading-${index}`}
                    className="h-full flex items-center gap-2 border-r border-base-200 w-32 px-2"
                  >
                    <Skeleton className="h-2 w-2" />
                    <Skeleton className="h-2 w-12" />
                  </div>
                ))}
                {!loading && viewFields?.map((viewField, index) => (
                  <CollectionTableHead
                    key={viewField.id}
                    index={index}
                    viewField={viewField}
                    field={fieldsById?.[viewField.fieldId]}
                    locked={locked}
                    draggedCol={draggedCol}
                    controller={controller}
                    state={state}
                    workspaceAssistant={workspaceAssistant?.collectionId === state.id
                      ? workspaceAssistant
                      : undefined
                  }
                    onDrag={(col, change) => setDraggedCol({ col, change })}
                    onDragStop={(col, change) => {
                      const currentWidth = col.config?.ui?.width ?? DEFAULT_FIELD_WIDTH
                      const newWidth = currentWidth + change
                      controller.onUpdateViewFieldConfig(
                        col.id,
                        { ui: { width: Math.max(MIN_FIELD_WIDTH, newWidth) } }
                      )
                      setDraggedCol(null)
                    }}
                    canDrag={hasFullAccess}
                  />
                ))}
                {!locked && hasFullAccess && <CollectionTableAddButton loading={loading} onClick={props.onAddFieldClick} />}
                {!locked && hasFullAccess && <CollectionTablePropertiesMenuButton loading={loading} onClick={props.onPropertiesMenuButtonClick} />}
              </div>
              <div>
                {loading && new Array(10).fill(0).map((_, index) => (
                  <CollectionTableRow key={`collection-table-row-loading-${index}`}>
                    {new Array(5).fill(0).map((_, index) => (
                      <div
                        key={`collection-table-row-column-loading-${index}`}
                        className={
                        clsx('flex items-center w-32 px-2', index === 0 && 'ml-8')
                      }
                      >
                        <Skeleton className="h-2 w-16" />
                      </div>
                    ))}
                  </CollectionTableRow>
                ))}
                {!loading && records?.map((record, recordIndex) => (
                  <CollectionTableRow
                    key={record.id}
                    checked={Boolean(state.selectedRows[record.id])}
                    record={record}
                    onMouseEnter={(_event, record) => setOver(record)}
                    onMouseLeave={() => setOver(null)}
                  >
                    <CollectionTableRowCheckbox
                      record={record}
                      checked={Boolean(state.selectedRows[record.id])}
                      onChange={controller.onRowCheckboxChange}
                    />
                    {fieldsById && viewFields?.map((viewField, viewFieldIndex) => (
                      <CollectionTableCell
                        key={viewFieldIndex}
                        className="border-r"
                        controller={controller}
                        record={record}
                        field={fieldsById[viewField.fieldId]}
                        viewField={viewField}
                        row={recordIndex}
                        col={viewFieldIndex}
                        defaultWidth={DEFAULT_FIELD_WIDTH}
                        selected={Boolean(selectedRange && isInRange(selectedRange, recordIndex, viewFieldIndex))}
                        active={selectedCell?.row === recordIndex && selectedCell?.col === viewFieldIndex}
                        onClick={controller.onCellClick}
                        onContextMenu={(e, ref) => {
                          e.preventDefault()
                          const field = fieldsById[viewField.fieldId]
                          setCellEl(ref.current)
                          setHasOperation(Boolean(field.operation))
                          setRecalculateCommand({ fieldId: field.id, recordId: record.id })
                        }}
                        render={renderCell}
                        isOver={viewFieldIndex === 0 && record.id === over?.id}
                        href={`/${workspaceSlug}/${state.id}/${record.id}`}
                        canEdit={canEdit}
                      />
                    ))}
                  </CollectionTableRow>
                ))}
                {records?.length === 0 && (
                <CollectionTableRow>
                  <div className='px-2 py-2'>
                    {t('No records found')}
                  </div>
                </CollectionTableRow>
                )}
              </div>
            </div>
          </div>
          <CollectionTableFooter
            loading={loading}
            canEdit={canEdit}
            onNewRowClick={controller.onNewRowClick}
            previousPageDisabled={props.disabledPreviousPage}
            nextPageDisabled={props.disabledNextPage}
            onPreviousPageClick={props.onPreviousPageClick}
            onNextPageClick={props.onNextPageClick}
          />
        </div>
        <OverlayContent
          open={Boolean(cellEl)}
          anchorEl={cellEl}
          onClose={() => {
            setCellEl(null)
            setRecalculateCommand(null)
          }}
        >
          <Menu>
            <li className={clsx(!hasOperation && 'disabled')}>
              <a onClick={() => handleRecalculateCell.mutate()}>
                <RotateCcw size={16}/>
                {t('fieldCell:Recalculate record')}
              </a>
            </li>
          </Menu>
        </OverlayContent>
      </>
    )
  }
)
