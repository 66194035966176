import { ConnectionFieldData } from '@indigohive/cogfy-types'
import { useMemo } from 'react'
import { Select } from '../../Select'
import { FormInputProps } from '../types'

export function ConnectionFormInput (props: FormInputProps) {
  const connectionOptions = useMemo(
    () => (props.connections ?? [])
      .filter(connection =>
        props.schema.type === 'connection' ||
        connection.provider === props.schema.type
      )
      .map(connection => ({
        label: `Connection: ${connection.name}`,
        value: `connection:${connection.id}`
      })),
    [props.connections]
  )
  const fieldOptions = useMemo(
    () => (props.fields ?? [])
      .filter(field => field.type === 'connection')
      .filter(field => (field.data as ConnectionFieldData)?.connection?.provider === props.schema.type)
      .map(field => ({
        label: `Field: ${field.name}`,
        value: `field:${field.id}`
      })),
    [props.fields, props.schema.type]
  )

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value.startsWith('field:')) {
      const fieldId = event.target.value.split(':')[1]
      const field = props.fields?.find(field => field.id === fieldId)

      if (field) {
        props.onChange({
          type: 'fields',
          fields: { value: [{ id: field.id }] }
        })
      } else {
        props.onChange(undefined)
      }
    } else if (event.target.value.startsWith('connection:')) {
      const connectionId = event.target.value.split(':')[1]
      const connection = props.connections?.find(connection => connection.id === connectionId)

      if (connection) {
        props.onChange({
          type: 'connection',
          connection: {
            value: {
              id: connection.id,
              provider: connection.provider
            }
          }
        })
      } else {
        props.onChange(undefined)
      }
    } else {
      props.onChange(undefined)
    }
  }

  const value = props.value?.type === 'connection'
    ? `connection:${props.value.connection.value.id}`
    : props.value?.type === 'fields' && props.value.fields.value.length > 0
      ? `field:${props.value.fields.value[0].id}`
      : ''

  return (
    <Select
      size="sm"
      value={value}
      onChange={handleChange}
      options={[
        { label: 'Select a connection', value: '' },
        ...connectionOptions,
        ...fieldOptions
      ]}
    />
  )
}
