import { CalculateRecordsCommand } from '@indigohive/cogfy-types/endpoints/calculateRecords'
import { ClearWhatsAppFieldConfigurationCommand } from '@indigohive/cogfy-types/endpoints/clearWhatsAppFieldConfiguration'
import { CompleteUploadChatMessageFilesCommand } from '@indigohive/cogfy-types/endpoints/completeUploadChatMessageFiles'
import { CompleteUploadFileCommand } from '@indigohive/cogfy-types/endpoints/completeUploadFile'
import { CreateConnectionCommand, CreateConnectionResult } from '@indigohive/cogfy-types/endpoints/createConnection'
import { CreateConnectionWhatsAppCommand } from '@indigohive/cogfy-types/endpoints/createConnectionWhatsApp'
import { CreateApiKeyCommand, CreateApiKeyResult } from '@indigohive/cogfy-types/endpoints/createApiKey'
import { CreateChatCommand, CreateChatResult } from '@indigohive/cogfy-types/endpoints/createChat'
import { CreateChatMiddlewareCommand, CreateChatMiddlewareResult } from '@indigohive/cogfy-types/endpoints/createChatMiddleware'
import { CreateCollectionAccessControlCommand, CreateCollectionAccessControlResult } from '@indigohive/cogfy-types/endpoints/createCollectionAccessControl'
import { CreateCollectionCommand, CreateCollectionResult } from '@indigohive/cogfy-types/endpoints/createCollection'
import { CreateFolderCommand, CreateFolderResult } from '@indigohive/cogfy-types/endpoints/createFolder'
import { CreateUserCommand, CreateUserResult } from '@indigohive/cogfy-types/endpoints/createUser'
import { CreateUserGroupCommand, CreateUserGroupResult } from '@indigohive/cogfy-types/endpoints/createUserGroup'
import { CreateUserGroupMemberCommand } from '@indigohive/cogfy-types/endpoints/createUserGroupMember'
import { CreateWebhookCommand } from '@indigohive/cogfy-types/endpoints/createWebhook'
import { CreateWorkspaceMemberCommand } from '@indigohive/cogfy-types/endpoints/createWorkspaceMember'
import { CreateWorkspaceUserContactCommand } from '@indigohive/cogfy-types/endpoints/createWorkspaceUserContact'
import { DeleteApiKeyCommand } from '@indigohive/cogfy-types/endpoints/deleteApiKey'
import { DeleteChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/deleteChatMiddleware'
import { DeleteCollectionAccessControlCommand } from '@indigohive/cogfy-types/endpoints/deleteCollectionAccessControl'
import { DeleteConnectionCommand } from '@indigohive/cogfy-types/endpoints/deleteConnection'
import { DeleteFileCommand } from '@indigohive/cogfy-types/endpoints/deleteFile'
import { DeleteScheduledMessageCommand } from '@indigohive/cogfy-types/endpoints/deleteScheduledMessage'
import { DeleteUserGroupCommand } from '@indigohive/cogfy-types/endpoints/deleteUserGroup'
import { DeleteUserGroupMembersCommand } from '@indigohive/cogfy-types/endpoints/deleteUserGroupMembers'
import { DeleteWebhooksCommand } from '@indigohive/cogfy-types/endpoints/deleteWebhooks'
import { DeleteWorkspaceMembersCommand } from '@indigohive/cogfy-types/endpoints/deleteWorkspaceMembers'
import { DeleteWorkspaceUserContactCommand } from '@indigohive/cogfy-types/endpoints/deleteWorkspaceUserContact'
import { DuplicateChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/duplicateChatMiddleware'
import { DuplicateCollectionCommand, DuplicateCollectionResult } from '@indigohive/cogfy-types/endpoints/duplicateCollection'
import { ForgotPasswordCommand } from '@indigohive/cogfy-types/endpoints/forgotPassword'
import { GetApiKeyByIdQuery, GetApiKeyByIdResult } from '@indigohive/cogfy-types/endpoints/getApiKeyById'
import { GetApiKeysPageQuery, GetApiKeysPageResult } from '@indigohive/cogfy-types/endpoints/getApiKeysPage'
import { GetAuthenticatedUserResult } from '@indigohive/cogfy-types/endpoints/getAuthenticatedUser'
import { GetChatByIdQuery, GetChatByIdResult } from '@indigohive/cogfy-types/endpoints/getChatById'
import { GetChatMessageFileByIdQuery, GetChatMessageFileByIdResult } from '@indigohive/cogfy-types/endpoints/getChatMessageFileById'
import { GetChatMessagesPageQuery, GetChatMessagesPageResult } from '@indigohive/cogfy-types/endpoints/getChatMessagesPage'
import { GetChatMiddlewaresQuery, GetChatMiddlewaresResult } from '@indigohive/cogfy-types/endpoints/getChatMiddlewares'
import { GetChatMiddlewareSchemasResult } from '@indigohive/cogfy-types/endpoints/getChatMiddlewareSchemas'
import { GetChatToolSchemasResult } from '@indigohive/cogfy-types/endpoints/getChatToolSchemas'
import { GetCollectionAccessControlListQuery, GetCollectionAccessControlListResult } from '@indigohive/cogfy-types/endpoints/getCollectionAccessControlList'
import { GetCollectionAncestorsQuery, GetCollectionAncestorsResult } from '@indigohive/cogfy-types/endpoints/getCollectionAncestors'
import { GetCollectionByIdQuery, GetCollectionByIdResult } from '@indigohive/cogfy-types/endpoints/getCollectionById'
import { GetCollectionFieldsCommand, GetCollectionFieldsResult } from '@indigohive/cogfy-types/endpoints/getCollectionFields'
import { GetCollectionRecordsQuery, GetCollectionRecordsResult } from '@indigohive/cogfy-types/endpoints/getCollectionRecords'
import { GetCollectionsQuery, GetCollectionsResult } from '@indigohive/cogfy-types/endpoints/getCollections'
import { GetCollectionsTreeResult } from '@indigohive/cogfy-types/endpoints/getCollectionsTree'
import { GetConnectionsListQuery, GetConnectionsListResult } from '@indigohive/cogfy-types/endpoints/getConnectionsList'
import { GetCollectionTemplatesPageQuery, GetCollectionTemplatesPageResult } from '@indigohive/cogfy-types/endpoints/getCollectionTemplatesPage'
import { GetCollectionViewsCommand, GetCollectionViewsResult } from '@indigohive/cogfy-types/endpoints/getCollectionViews'
import { GetCurrentUserRoleResult } from '@indigohive/cogfy-types/endpoints/getCurrentUserRole'
import { GetFieldPropertiesResult } from '@indigohive/cogfy-types/endpoints/getFieldProperties'
import { GetFieldsQuery, GetFieldsResult } from '@indigohive/cogfy-types/endpoints/getFields'
import { GetFileByIdCommand, GetFileByIdResult } from '@indigohive/cogfy-types/endpoints/getFileById'
import { GetGoogleAuthUrlCommand, GetGoogleAuthUrlResult } from '@indigohive/cogfy-types/endpoints/getGoogleAuthUrl'
import { GetMembersPageQuery, GetMembersPageResult } from '@indigohive/cogfy-types/endpoints/getMembersPage'
import { GetOperationsQuery, GetOperationsResult } from '@indigohive/cogfy-types/endpoints/getOperations'
import { GetRecordByIdCommand, GetRecordByIdResult } from '@indigohive/cogfy-types/endpoints/getRecordById'
import { GetRecordReferencesByIdCommand, GetRecordReferencesByIdResult } from '@indigohive/cogfy-types/endpoints/getRecordReferencesById'
import { GetScheduledMessagesQuery, GetScheduledMessagesResult } from '@indigohive/cogfy-types/endpoints/getScheduledMessages'
import { GetUserByEmailQuery, GetUserByEmailResult } from '@indigohive/cogfy-types/endpoints/getUserByEmail'
import { GetUserCollectionPermissionsQuery, GetUserCollectionPermissionsResult } from '@indigohive/cogfy-types/endpoints/getUserCollectionPermissions'
import { GetUserGroupByIdQuery, GetUserGroupByIdResult } from '@indigohive/cogfy-types/endpoints/getUserGroupById'
import { GetUserGroupMembersQuery, GetUserGroupMembersResult } from '@indigohive/cogfy-types/endpoints/getUserGroupMembers'
import { GetUserGroupsPageQuery, GetUserGroupsPageResult } from '@indigohive/cogfy-types/endpoints/getUserGroupsPage'
import { GetUsersOrUserGroupsQuery, GetUsersOrUserGroupsResult } from '@indigohive/cogfy-types/endpoints/getUsersOrUserGroups'
import { GetUsersPageQuery, GetUsersPageResult } from '@indigohive/cogfy-types/endpoints/getUsersPage'
import { GetViewFieldsQuery, GetViewFieldsResult } from '@indigohive/cogfy-types/endpoints/getViewFields'
import { GetViewRecordsQuery, GetViewRecordsResult } from '@indigohive/cogfy-types/endpoints/getViewRecords'
import { GetWebhooksPageQuery, GetWebhooksPageResult } from '@indigohive/cogfy-types/endpoints/getWebhooksPage'
import { GetWhatsAppNumbersCommand, GetWhatsAppNumbersResult } from '@indigohive/cogfy-types/endpoints/getWhatsAppNumbers'
import { GetWorkspaceAssistantResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceAssistant'
import { GetWorkspaceByIdQuery, GetWorkspaceByIdResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceById'
import { GetWorkspaceMembersQuery, GetWorkspaceMembersResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceMembers'
import { GetWorkspaceBySlugQuery, GetWorkspaceBySlugResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceBySlug'
import { GetWorkspacesResult } from '@indigohive/cogfy-types/endpoints/getWorkspaces'
import { GetWorkspaceUserContactsQuery, GetWorkspaceUserContactsResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceUserContacts'
import { MoveCollectionCommand } from '@indigohive/cogfy-types/endpoints/moveCollection'
import { RegisterWhatsAppNumberCommand } from '@indigohive/cogfy-types/endpoints/registerWhatsAppNumber'
import { ReorderChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/reorderChatMiddleware'
import { ResetPasswordCommand } from '@indigohive/cogfy-types/endpoints/resetPassword'
import { ScheduleWhatsAppMessageCommand, ScheduleWhatsAppMessageResult } from '@indigohive/cogfy-types/endpoints/scheduleWhatsAppMessage'
import { SendChatMessageCommand } from '@indigohive/cogfy-types/endpoints/sendChatMessage'
import { SendWhatsAppMessageCommand, SendWhatsAppMessageResult } from '@indigohive/cogfy-types/endpoints/sendWhatsAppMessage'
import { SetChatMessageReactionCommand } from '@indigohive/cogfy-types/endpoints/setChatMessageReaction'
import { SetWorkspaceLogoCommand } from '@indigohive/cogfy-types/endpoints/setWorkspaceLogo'
import { SetWorkspaceThemeCommand } from '@indigohive/cogfy-types/endpoints/setWorkspaceTheme'
import { UpdateChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/updateChatMiddleware'
import { UpdateCollectionAccessControlCommand } from '@indigohive/cogfy-types/endpoints/updateCollectionAccessControl'
import { UpdateCollectionAccessControlTypeCommand } from '@indigohive/cogfy-types/endpoints/updateCollectionAccessControlType'
import { UpdateConnectionDefaultCommand } from '@indigohive/cogfy-types/endpoints/updateConnectionDefault'
import { UpdateConnectionEnabledCommand } from '@indigohive/cogfy-types/endpoints/updateConnectionEnabled'
import { UpdateMemberRoleCommand } from '@indigohive/cogfy-types/endpoints/updateMemberRole'
import { UpdateUserGroupDescriptionCommand } from '@indigohive/cogfy-types/endpoints/updateUserGroupDescription'
import { UpdateUserGroupNameCommand } from '@indigohive/cogfy-types/endpoints/updateUserGroupName'
import { UpdateWhatsAppNumberWebhookCommand } from '@indigohive/cogfy-types/endpoints/updateWhatsAppNumberWebhook'
import { UpdateUserGroupIsDefaultCommand } from '@indigohive/cogfy-types/endpoints/updateUserGroupIsDefault'
import { UpdateWorkspace2FACommand } from '@indigohive/cogfy-types/endpoints/updateWorkspace2FA'
import { UpdateWorkspaceAssistantCommand } from '@indigohive/cogfy-types/endpoints/updateWorkspaceAssistant'
import { UpdateWorkspaceLocaleCommand } from '@indigohive/cogfy-types/endpoints/updateWorkspaceLocale'
import { UploadChatMessageFilesCommand, UploadChatMessageFilesResult } from '@indigohive/cogfy-types/endpoints/uploadChatMessageFiles'
import { UploadFileCommand, UploadFileResult } from '@indigohive/cogfy-types/endpoints/uploadFile'
import { UserEventCommand } from '@indigohive/cogfy-types/endpoints/userEvent'
import { AxiosInstance } from 'axios'

type Milliseconds = number

type RequestOptions = {
  signal?: AbortSignal
  timeout?: Milliseconds
}

export abstract class EndpointsClient {
  protected axios: AxiosInstance

  constructor (axios: AxiosInstance) {
    this.axios = axios
  }

  async calculateRecords (
    data: CalculateRecordsCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/calculateRecords', data, options)
  }

  async clearWhatsAppFieldConfiguration (
    data: ClearWhatsAppFieldConfigurationCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/clearWhatsAppFieldConfiguration', data, options)
  }

  async completeUploadChatMessageFiles (
    data: CompleteUploadChatMessageFilesCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/completeUploadChatMessageFiles', data, options)
  }

  async completeUploadFile (
    data: CompleteUploadFileCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/completeUploadFile', data, options)
  }

  async createConnection (
    data: CreateConnectionCommand,
    options?: RequestOptions
  ): Promise<CreateConnectionResult> {
    return (await this.axios.post('/createConnection', data, options)).data
  }

  async createConnectionWhatsApp (
    data: CreateConnectionWhatsAppCommand,
    options?: RequestOptions
  ): Promise<CreateConnectionResult> {
    return (await this.axios.post('/createConnectionWhatsApp', data, options)).data
  }

  async createApiKey (
    data: CreateApiKeyCommand,
    options?: RequestOptions
  ): Promise<CreateApiKeyResult> {
    return (await this.axios.post('/createApiKey', data, options)).data
  }

  async createChat (
    data: CreateChatCommand,
    options?: RequestOptions
  ): Promise<CreateChatResult> {
    return (await this.axios.post('/createChat', data, options)).data
  }

  async createChatMiddleware (
    data: CreateChatMiddlewareCommand,
    options?: RequestOptions
  ): Promise<CreateChatMiddlewareResult> {
    return (await this.axios.post('/createChatMiddleware', data, options)).data
  }

  async createCollectionAccessControl (
    data: CreateCollectionAccessControlCommand,
    options?: RequestOptions
  ): Promise<CreateCollectionAccessControlResult> {
    return (await this.axios.post('/createCollectionAccessControl', data, options)).data
  }

  async createCollection (
    data: CreateCollectionCommand,
    options?: RequestOptions
  ): Promise<CreateCollectionResult> {
    return (await this.axios.post('/createCollection', data, options)).data
  }

  async createFolder (
    data: CreateFolderCommand,
    options?: RequestOptions
  ): Promise<CreateFolderResult> {
    return (await this.axios.post('/createFolder', data, options)).data
  }

  async createUser (
    data: CreateUserCommand,
    options?: RequestOptions
  ): Promise<CreateUserResult> {
    return (await this.axios.post('/createUser', data, options)).data
  }

  async createUserGroup (
    data: CreateUserGroupCommand,
    options?: RequestOptions
  ): Promise<CreateUserGroupResult> {
    return (await this.axios.post('/createUserGroup', data, options)).data
  }

  async createUserGroupMember (
    data: CreateUserGroupMemberCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/createUserGroupMember', data, options)
  }

  async createWebhook (
    data: CreateWebhookCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/createWebhook', data, options)
  }

  async createWorkspaceMember (
    data: CreateWorkspaceMemberCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/createWorkspaceMember', data, options)
  }

  async createWorkspaceUserContact (
    data: CreateWorkspaceUserContactCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/createWorkspaceUserContact', data, options)
  }

  async deleteApiKey (
    data: DeleteApiKeyCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/deleteApiKey', data, options)).data
  }

  async deleteChatMiddleware (
    data: DeleteChatMiddlewareCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteChatMiddleware', data, options)
  }

  async deleteCollectionAccessControl (
    data: DeleteCollectionAccessControlCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/deleteCollectionAccessControl', data, options)).data
  }

  async deleteConnection (
    data: DeleteConnectionCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/deleteConnection', data, options)).data
  }

  async deleteFile (
    data: DeleteFileCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/deleteFile', data, options)).data
  }

  async deleteScheduledMessage (
    data: DeleteScheduledMessageCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteScheduledMessage', data, options)
  }

  async deleteUserGroup (
    data: DeleteUserGroupCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteUserGroup', data, options)
  }

  async deleteUserGroupMembers (
    data: DeleteUserGroupMembersCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteUserGroupMembers', data, options)
  }

  async deleteWebhooks (
    data: DeleteWebhooksCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteWebhooks', data, options)
  }

  async deleteWorkspaceMembers (
    data: DeleteWorkspaceMembersCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteWorkspaceMembers', data, options)
  }

  async deleteWorkspaceUserContact (
    data: DeleteWorkspaceUserContactCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteWorkspaceUserContact', data, options)
  }

  async duplicateChatMiddleware (
    data: DuplicateChatMiddlewareCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/duplicateChatMiddleware', data, options)
  }

  async duplicateCollection (
    data: DuplicateCollectionCommand,
    options?: RequestOptions
  ): Promise<DuplicateCollectionResult> {
    return (await this.axios.post('/duplicateCollection', data, options)).data
  }

  async forgotPassword (
    data: ForgotPasswordCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/forgotPassword', data, options)
  }

  async getApiKeyById (
    data: GetApiKeyByIdQuery,
    options?: RequestOptions
  ): Promise<GetApiKeyByIdResult> {
    return (await this.axios.post('/getApiKeyById', data, options)).data
  }

  async getApiKeysPage (
    data: GetApiKeysPageQuery,
    options?: RequestOptions
  ): Promise<GetApiKeysPageResult> {
    return (await this.axios.post('/getApiKeysPage', data, options)).data
  }

  async getAuthenticatedUser (
    options?: RequestOptions
  ): Promise<GetAuthenticatedUserResult> {
    return (await this.axios.post('/getAuthenticatedUser', null, options)).data
  }

  async getChatById (
    data: GetChatByIdQuery,
    options?: RequestOptions
  ): Promise<GetChatByIdResult> {
    return (await this.axios.post('/getChatById', data, options)).data
  }

  async getChatMessageFileById (
    data: GetChatMessageFileByIdQuery,
    options?: RequestOptions
  ): Promise<GetChatMessageFileByIdResult> {
    return (await this.axios.post('/getChatMessageFileById', data, options)).data
  }

  async getChatMessagesPage (
    data: GetChatMessagesPageQuery,
    options?: RequestOptions
  ): Promise<GetChatMessagesPageResult> {
    return (await this.axios.post('/getChatMessagesPage', data, options)).data
  }

  async getChatMiddlewares (
    data: GetChatMiddlewaresQuery,
    options?: RequestOptions
  ): Promise<GetChatMiddlewaresResult> {
    return (await this.axios.post('/getChatMiddlewares', data, options)).data
  }

  async getChatMiddlewareSchemas (
    options?: RequestOptions
  ): Promise<GetChatMiddlewareSchemasResult> {
    return (await this.axios.post('/getChatMiddlewareSchemas', null, options)).data
  }

  async getChatToolSchemas (
    options?: RequestOptions
  ): Promise<GetChatToolSchemasResult> {
    return (await this.axios.post('/getChatToolSchemas', null, options)).data
  }

  async getCollectionAccessControlList (
    data: GetCollectionAccessControlListQuery,
    options?: RequestOptions
  ): Promise<GetCollectionAccessControlListResult> {
    return (await this.axios.post('/getCollectionAccessControlList', data, options)).data
  }

  async getCollectionAncestors (
    data: GetCollectionAncestorsQuery,
    options?: RequestOptions
  ): Promise<GetCollectionAncestorsResult> {
    return (await this.axios.post('/getCollectionAncestors', data, options)).data
  }

  async getCollectionById (
    query: GetCollectionByIdQuery,
    options?: RequestOptions
  ): Promise<GetCollectionByIdResult> {
    return (await this.axios.post('/getCollectionById', query, options)).data
  }

  async getCollectionFields (
    data: GetCollectionFieldsCommand,
    options?: RequestOptions
  ): Promise<GetCollectionFieldsResult> {
    return (await this.axios.post('/getCollectionFields', data, options)).data
  }

  async getCollectionRecords (
    data: GetCollectionRecordsQuery,
    options?: RequestOptions
  ): Promise<GetCollectionRecordsResult> {
    return (await this.axios.post('/getCollectionRecords', data, options)).data
  }

  async getCollections (
    data: GetCollectionsQuery,
    options?: RequestOptions
  ): Promise<GetCollectionsResult> {
    return (await this.axios.post('/getCollections', data, options)).data
  }

  async getCollectionsTree (
    options?: RequestOptions
  ): Promise<GetCollectionsTreeResult> {
    return (await this.axios.post('/getCollectionsTree', null, options)).data
  }

  async getConnectionsList (
    data: GetConnectionsListQuery,
    options?: RequestOptions
  ): Promise<GetConnectionsListResult> {
    return (await this.axios.post('/getConnectionsList', data, options)).data
  }

  async getCollectionTemplatesPage (
    data: GetCollectionTemplatesPageQuery,
    options?: RequestOptions
  ): Promise<GetCollectionTemplatesPageResult> {
    return (await this.axios.post('/getCollectionTemplatesPage', data, options)).data
  }

  async getCollectionViews (
    data: GetCollectionViewsCommand,
    options?: RequestOptions
  ): Promise<GetCollectionViewsResult> {
    return (await this.axios.post('/getCollectionViews', data, options)).data
  }

  async getCurrentUserRole (
    options?: RequestOptions
  ): Promise<GetCurrentUserRoleResult> {
    return (await this.axios.post('/getCurrentUserRole', null, options)).data
  }

  async getFieldProperties (
    options?: RequestOptions
  ): Promise<GetFieldPropertiesResult> {
    return (await this.axios.post('/getFieldProperties', null, options)).data
  }

  async getFields (
    data: GetFieldsQuery,
    options?: RequestOptions
  ): Promise<GetFieldsResult> {
    return (await this.axios.post('/getFields', data, options)).data
  }

  async getFileById (
    data: GetFileByIdCommand,
    options?: RequestOptions
  ): Promise<GetFileByIdResult> {
    return (await this.axios.post('/getFileById', data, options)).data
  }

  async getGoogleAuthUrl (
    data: GetGoogleAuthUrlCommand,
    options?: RequestOptions
  ): Promise<GetGoogleAuthUrlResult> {
    return (await this.axios.post('/getGoogleAuthUrl', data, options)).data
  }

  async getMembersPage (
    data: GetMembersPageQuery,
    options?: RequestOptions
  ): Promise<GetMembersPageResult> {
    return (await this.axios.post('/getMembersPage', data, options)).data
  }

  async getOperations (
    data: GetOperationsQuery,
    options?: RequestOptions
  ): Promise<GetOperationsResult> {
    return (await this.axios.post('/getOperations', data, options)).data
  }

  async getRecordById (
    data: GetRecordByIdCommand,
    options?: RequestOptions
  ): Promise<GetRecordByIdResult> {
    return (await this.axios.post('/getRecordById', data, options)).data
  }

  async getRecordReferencesById (
    data: GetRecordReferencesByIdCommand,
    options?: RequestOptions
  ): Promise<GetRecordReferencesByIdResult> {
    return (await this.axios.post('/getRecordReferencesById', data, options)).data
  }

  async getScheduledMessages (
    data: GetScheduledMessagesQuery,
    options?: RequestOptions
  ): Promise<GetScheduledMessagesResult> {
    return (await this.axios.post('/getScheduledMessages', data, options)).data
  }

  async getUserByEmail (
    data: GetUserByEmailQuery,
    options?: RequestOptions
  ): Promise<GetUserByEmailResult> {
    return (await this.axios.post('/getUserByEmail', data, options)).data
  }

  async getUserCollectionPermissions (
    data: GetUserCollectionPermissionsQuery,
    options?: RequestOptions
  ): Promise<GetUserCollectionPermissionsResult> {
    return (await this.axios.post('/getUserCollectionPermissions', data, options)).data
  }

  async getUserGroupById (
    data: GetUserGroupByIdQuery,
    options?: RequestOptions
  ): Promise<GetUserGroupByIdResult> {
    return (await this.axios.post('/getUserGroupById', data, options)).data
  }

  async getUserGroupMembers (
    data: GetUserGroupMembersQuery,
    options?: RequestOptions
  ): Promise<GetUserGroupMembersResult> {
    return (await this.axios.post('/getUserGroupMembers', data, options)).data
  }

  async getUserGroupsPage (
    data: GetUserGroupsPageQuery,
    options?: RequestOptions
  ): Promise<GetUserGroupsPageResult> {
    return (await this.axios.post('/getUserGroupsPage', data, options)).data
  }

  async getUsersOrUserGroups (
    data: GetUsersOrUserGroupsQuery,
    options?: RequestOptions
  ): Promise<GetUsersOrUserGroupsResult> {
    return (await this.axios.post('/getUsersOrUserGroups', data, options)).data
  }

  async getUsersPage (
    data: GetUsersPageQuery,
    options?: RequestOptions
  ): Promise<GetUsersPageResult> {
    return (await this.axios.post('/getUsersPage', data, options)).data
  }

  async getViewFields (
    data: GetViewFieldsQuery,
    options?: RequestOptions
  ): Promise<GetViewFieldsResult> {
    return (await this.axios.post('/getViewFields', data, options)).data
  }

  async getViewRecords (
    data: GetViewRecordsQuery,
    options?: RequestOptions
  ): Promise<GetViewRecordsResult> {
    return (await this.axios.post('/getViewRecords', data, options)).data
  }

  async getWebhooksPage (
    data: GetWebhooksPageQuery,
    options?: RequestOptions
  ): Promise<GetWebhooksPageResult> {
    return (await this.axios.post('/getWebhooksPage', data, options)).data
  }

  async getWhatsAppNumbers (
    data: GetWhatsAppNumbersCommand,
    options?: RequestOptions
  ): Promise<GetWhatsAppNumbersResult> {
    return (await this.axios.post('/getWhatsAppNumbers', data, options)).data
  }

  async getWorkspaceAssistant (
    options?: RequestOptions
  ): Promise<GetWorkspaceAssistantResult> {
    return (await this.axios.post('/getWorkspaceAssistant', null, options)).data
  }

  async getWorkspaceById (
    data: GetWorkspaceByIdQuery,
    options?: RequestOptions
  ): Promise<GetWorkspaceByIdResult> {
    return (await this.axios.post('/getWorkspaceById', data, options)).data
  }

  async getWorkspaceMembers (
    data: GetWorkspaceMembersQuery,
    options?: RequestOptions
  ): Promise<GetWorkspaceMembersResult> {
    return (await this.axios.post('/getWorkspaceMembers', data, options)).data
  }

  async getWorkspaceBySlug (
    data: GetWorkspaceBySlugQuery,
    options?: RequestOptions
  ): Promise<GetWorkspaceBySlugResult> {
    return (await this.axios.post('/getWorkspaceBySlug', data, options)).data
  }

  async getWorkspaces (
    options?: RequestOptions
  ): Promise<GetWorkspacesResult> {
    return (await this.axios.post('/getWorkspaces', null, options)).data
  }

  async getWorkspaceUserContacts (
    data: GetWorkspaceUserContactsQuery,
    options?: RequestOptions
  ): Promise<GetWorkspaceUserContactsResult> {
    return (await this.axios.post('/getWorkspaceUserContacts', data, options)).data
  }

  async moveCollection (
    data: MoveCollectionCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/moveCollection', data, options)).data
  }

  async registerWhatsAppNumber (
    data: RegisterWhatsAppNumberCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/registerWhatsAppNumber', data, options)
  }

  async reorderChatMiddleware (
    data: ReorderChatMiddlewareCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/reorderChatMiddleware', data, options)
  }

  async resetPassword (
    data: ResetPasswordCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/resetPassword', data, options)
  }

  async scheduleWhatsAppMessage (
    data: ScheduleWhatsAppMessageCommand,
    options?: RequestOptions
  ): Promise<ScheduleWhatsAppMessageResult> {
    return (await this.axios.post('/scheduleWhatsAppMessage', data, options)).data
  }

  async sendChatMessage (
    data: SendChatMessageCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/sendChatMessage', data, options)).data
  }

  async sendWhatsAppMessage (
    data: SendWhatsAppMessageCommand,
    options?: RequestOptions
  ): Promise<SendWhatsAppMessageResult> {
    return (await this.axios.post('/sendWhatsAppMessage', data, options)).data
  }

  async setChatMessageReaction (
    data: SetChatMessageReactionCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/setChatMessageReaction', data, options)
  }

  async setWorkspaceLogo (
    data: SetWorkspaceLogoCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/setWorkspaceLogo', data, options)
  }

  async setWorkspaceTheme (
    data: SetWorkspaceThemeCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/setWorkspaceTheme', data, options)
  }

  async updateChatMiddleware (
    data: UpdateChatMiddlewareCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateChatMiddleware', data, options)
  }

  async updateCollectionAccessControl (
    data: UpdateCollectionAccessControlCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/updateCollectionAccessControl', data, options)).data
  }

  async updateCollectionAccessControlType (
    data: UpdateCollectionAccessControlTypeCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/updateCollectionAccessControlType', data, options)).data
  }

  async updateConnectionDefault (
    data: UpdateConnectionDefaultCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateConnectionDefault', data, options)
  }

  async updateConnectionEnabled (
    data: UpdateConnectionEnabledCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateConnectionEnabled', data, options)
  }

  async updateMemberRole (
    data: UpdateMemberRoleCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateMemberRole', data, options)
  }

  async updateUserGroupDescription (
    data: UpdateUserGroupDescriptionCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateUserGroupDescription', data, options)
  }

  async updateUserGroupName (
    data: UpdateUserGroupNameCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateUserGroupName', data, options)
  }

  async updateWhatsAppNumberWebhook (
    data: UpdateWhatsAppNumberWebhookCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateWhatsAppNumberWebhook', data, options)
  }

  async updateUserGroupIsDefault (
    data: UpdateUserGroupIsDefaultCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateUserGroupIsDefault', data, options)
  }

  async updateWorkspace2FA (
    data: UpdateWorkspace2FACommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateWorkspace2FA', data, options)
  }

  async updateWorkspaceAssistant (
    data: UpdateWorkspaceAssistantCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/updateWorkspaceAssistant', data, options)).data
  }

  async updateWorkspaceLocale (
    data: UpdateWorkspaceLocaleCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateWorkspaceLocale', data, options)
  }

  async uploadChatMessageFiles (
    data: UploadChatMessageFilesCommand,
    options?: RequestOptions
  ): Promise<UploadChatMessageFilesResult> {
    return (await this.axios.post('/uploadChatMessageFiles', data, options)).data
  }

  async uploadFile (
    data: UploadFileCommand,
    options?: RequestOptions
  ): Promise<UploadFileResult> {
    return (await this.axios.post('/uploadFile', data, options)).data
  }

  async userEvent (
    data: UserEventCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/userEvent', data, options)
  }
}
