import { useQuery } from '@tanstack/react-query'
import { PropsWithChildren, useEffect, useState } from 'react'
import { AuthenticationContext } from '../../contexts'
import { useCogfy } from '../../hooks'
import posthog from 'posthog-js'
import { GetAuthenticatedUserResult } from '@indigohive/cogfy-types/endpoints/getAuthenticatedUser'

export type AuthenticationProviderProps = PropsWithChildren

export function AuthenticationProvider (props: AuthenticationProviderProps) {
  const [authenticatedUser, setAuthenticatedUser] = useState<GetAuthenticatedUserResult | null>(null)
  const cogfy = useCogfy()

  const getAuthenticatedUser = useQuery({
    queryKey: ['getAuthenticatedUser'],
    queryFn: async ({ signal }) => {
      const result = await cogfy.getAuthenticatedUser({ signal })

      setAuthenticatedUser(result)

      return result
    }
  })

  useEffect(() => {
    if (authenticatedUser) {
      posthog.identify(authenticatedUser.id)
    }
  }, [authenticatedUser])

  return (
    <AuthenticationContext.Provider
      value={{
        authenticatedUser,
        onAuthenticatedUserChange: setAuthenticatedUser,
        loading: getAuthenticatedUser.isLoading
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  )
}
