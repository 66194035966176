import { SearchIcon, MinusIcon, PlusIcon, TableIcon } from 'lucide-react'
import { ReferenceFieldData, ReferenceRecordProperty, TextRecordProperty } from '@indigohive/cogfy-types'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useCogfy, useCollection } from '../../../hooks'
import { DEFAULT_FIELD_WIDTH } from '../../../pages/CollectionPageV2/components'
import { FieldOverlayProps } from '../FieldOverlay'
import { useDebouncedCallback } from 'use-debounce'
import { useState } from 'react'
import { GetCollectionRecordsQuery } from '@indigohive/cogfy-types/endpoints/getCollectionRecords'

const MIN_WIDTH = 200

export function ReferenceOverlay (props: FieldOverlayProps) {
  const { field, viewField, record, controller } = props
  const cogfy = useCogfy()
  const { t } = useTranslation()
  const [query, setQuery] = useState<Omit<GetCollectionRecordsQuery, 'collectionId'>>({
    onlyTitles: true,
    pageSize: 200
  })
  const width = Math.max(viewField.config?.ui?.width ?? DEFAULT_FIELD_WIDTH, MIN_WIDTH)
  const referencedCollectionId = (field.data as ReferenceFieldData)?.reference?.collectionId
  const limit = (field.data as ReferenceFieldData)?.reference?.limit
  const getReferencedCollection = useCollection(referencedCollectionId)
  const referencedCollectionTitleFieldId = getReferencedCollection.data?.titleFieldId

  const queryCollectionRecords = useQuery({
    queryKey: ['queryCollectionRecords', referencedCollectionId, query],
    queryFn: () => cogfy.getCollectionRecords({ collectionId: referencedCollectionId!, ...query }),
    enabled: Boolean(referencedCollectionId),
    placeholderData: prev => prev
  })

  const debouncedSetQuery = useDebouncedCallback(value => setQuery(prev => ({
    ...prev,
    search: value
  })), 300)

  const referencedCollectionRecordsById = Object.fromEntries(
    queryCollectionRecords.data?.data?.map(record => [record.id, record]) ?? []
  )

  const recordReferences = (record.properties[field.id] as ReferenceRecordProperty)?.reference?.value ?? []

  const linkedRecords = recordReferences.map(recordReference => {
    const referencedRecord = referencedCollectionRecordsById[recordReference.referencedRecordId]
    const title = referencedRecord ? (referencedRecord.properties[referencedCollectionTitleFieldId!] as TextRecordProperty)?.text?.value : ''

    if (!title) return null

    return {
      id: recordReference.referencedRecordId,
      title,
      recordReferenceId: recordReference.id
    }
  })
    .filter(Boolean)

  const recordsToLink = (queryCollectionRecords.data?.data ?? [])
    .filter(record => !linkedRecords.some(linkedRecord => linkedRecord!.id === record.id))
    .map(record => ({
      id: record.id,
      title: (record.properties[referencedCollectionTitleFieldId!] as TextRecordProperty)?.text?.value
    }))

  return (
    <div
      className="menu-sm bg-base-100 rounded-btn border shadow-lg over max-w-full"
      style={{ width }}
    >
      <div className="px-3 pt-4 pb-2">
        <label className="input input-xs input-bordered flex items-center gap-2">
          <SearchIcon size={14} />
          <input
            autoFocus
            type="input"
            className="w-full h-min"
            placeholder={t('Search')}
            onChange={event => {
              debouncedSetQuery(event.target.value)
            }}
          />
        </label>
      </div>
      <ul
        className="overflow-y-auto overflow-x-hidden w-full max-h-96"
        style={{ width, scrollbarWidth: 'thin' }}
      >
        {getReferencedCollection.data && (
        <>
          <div className="flex gap-1 items-center pl-2 pt-2">
            <span>{t('In')}</span>
            <TableIcon size={14} />
            <span>
              {getReferencedCollection.data.name}
            </span>
          </div>
          {linkedRecords.length > 0 && <li className="menu-title">{t('Linked records')}</li>}
          {linkedRecords.map(linkedRecord => (
            <li
              key={linkedRecord!.id}
              className="menu-item cursor-pointer hover:bg-base-200"
              onClick={() => controller.onDeleteRecordReferenceClick(record.id, field.id, linkedRecord!.recordReferenceId)}
            >
              <a className="flex items-center justify-between">
                <span>
                  {linkedRecord!.title}
                </span>
                <span className="tooltip" data-tip="Unlink">
                  <MinusIcon size={16} />
                </span>
              </a>
            </li>
          ))}
          {recordsToLink.length > 0 && <li className="menu-title">{linkedRecords.length > 0 ? t('Link another record') : t('Link a record')}</li>}
          {recordsToLink.map(recordToLink => (
            <li
              key={recordToLink.id}
              className="menu-item cursor-pointer hover:bg-base-200"
              onClick={() => {
                if (limit && linkedRecords.length === 1) {
                  controller.onUpdateRecordReferenceClick(record.id, field.id, recordToLink.id, recordReferences[0].id, recordToLink.title ?? null)
                } else {
                  controller.onCreateRecordReferenceClick(record.id, field.id, recordToLink.id, recordToLink.title ?? null)
                }
              }}
            >
              <a className="flex items-center justify-between">
                <span className='truncate'>
                  {recordToLink.title}
                </span>
                <span className="tooltip" data-tip="Link">
                  <PlusIcon size={16} />
                </span>
              </a>
            </li>
          ))}
        </>
        )}
      </ul>
    </div>
  )
}
