import { GetUserGroupsPageQuery } from '@indigohive/cogfy-types/endpoints/getUserGroupsPage'
import { useCogfy } from './use-cogfy'
import { useQuery } from '@tanstack/react-query'

export function useUserGroupsPage (params: GetUserGroupsPageQuery) {
  const cogfy = useCogfy()

  const getUserGroupsPage = useQuery({
    queryKey: ['getUserGroupsPage', params],
    queryFn: ({ signal }) => cogfy.getUserGroupsPage(params, { signal })
  })

  return getUserGroupsPage
}
