import { GetRecordByIdResult } from '@indigohive/cogfy-types/endpoints/getRecordById'
import { GetCollectionFieldsResultData } from '@indigohive/cogfy-types/endpoints/getCollectionFields'
import type {
  SelectRecordProperty,
  UpdateRecordPropertiesOperationData,
  UUID,
  SelectFieldDataOption
} from '@indigohive/cogfy-types'
import { Menu, OptionChip, OptionChipColorName, OverlayContent } from '../../../components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export type SelectRecordPropertyInputProps = {
  collectionId: UUID
  field: Extract<GetCollectionFieldsResultData, { type: 'select' }>
  record: GetRecordByIdResult
  onUpdateRecordProperties?: (data: UpdateRecordPropertiesOperationData) => void
  canEdit?: boolean
}

export function SelectRecordPropertyInput (props: SelectRecordPropertyInputProps) {
  const { collectionId, field, record, canEdit } = props
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const { t } = useTranslation()

  const property = record.properties[field.id] as SelectRecordProperty | undefined
  const multiple = field.data.select?.multiple ?? false
  const options = field.data.select?.options ?? []
  const optionsById = new Map(options.map(option => [option.id, option]))
  const values = (property?.select?.value ?? []).filter(value => optionsById.has(value.id))

  const onOptionClick = (option: SelectFieldDataOption) => {
    const contains = values.some(value => value.id === option.id)

    if (multiple) {
      const property: SelectRecordProperty = {
        type: 'select',
        select: {
          value: contains ? values.filter(value => value.id !== option.id) : [...values, { id: option.id }]
        }
      }

      props.onUpdateRecordProperties?.({
        recordId: record.id,
        collectionId,
        properties: {
          [field.id]: property
        }
      })
    } else {
      const property: SelectRecordProperty = {
        type: 'select',
        select: {
          value: contains ? [] : [{ id: option.id }]
        }
      }

      props.onUpdateRecordProperties?.({
        recordId: record.id,
        collectionId,
        properties: {
          [field.id]: property
        }
      })

      setAnchorEl(null)
    }
  }

  return (
    <div>
      <div
        className={clsx(
          'flex',
          'flex-wrap',
          'gap-2',
          'p-2',
          'rounded-lg',
          canEdit && [
            'hover:bg-base-200',
            'active:bg-base-300',
            'cursor-pointer'
          ]
        )}
        onClick={event => {
          if (!canEdit) return
          setAnchorEl(event.currentTarget)
        }}
      >
        {values.length === 0 && <span className="opacity-40 py-0.5">{t('RecordPage:Empty')}</span>}
        {values.map((value, index) => (
          <OptionChip
            key={index}
            label={optionsById.get(value.id)!.label ?? ''}
            color={optionsById.get(value.id)!.color as OptionChipColorName}
          />
        ))}
      </div>
      <OverlayContent
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setAnchorEl(null)}
      >
        <Menu size="sm">
          {options.map(option => (
            <li key={option.id} onClick={() => onOptionClick(option)}>
              <a>
                <OptionChip
                  label={option.label}
                  color={option.color as OptionChipColorName}
                />
              </a>
            </li>
          ))}
        </Menu>
      </OverlayContent>
    </div>
  )
}
