import { TransactionEvent } from '@indigohive/cogfy-types/events/transaction'
import { UUID } from '@indigohive/cogfy-types'
import { useBeforeUnload, useBlocker, useNavigate, useParams } from 'react-router-dom'
import { useAppManager, useCogfy, useCollection, useToasts, useWorkspaceSlug } from '../../hooks'
import { DatabaseCollectionPage } from './_DatabaseCollectionPage'
import { FolderCollectionPage } from './_FolderCollectionPage'
import { CollectionName, CollectionNavbar } from './components2'
import { CollectionState, TransactionReducer, useLocked } from '../../lib'
import { useEffect, useState, useSyncExternalStore } from 'react'
import { useCopyPaste, useUndoRedo } from './hooks'
import { CollectionPageController } from './collection-page-controller'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ConfirmDeleteDialog, OverlayContent } from '../../components'
import { CollectionAccessControlEditor } from './components/CollectionAccessControlEditor'
import { CollectionMenu } from './components2/CollectionMenu'
import { useTranslation } from 'react-i18next'

export type CollectionPageV2Props = {
  collectionId?: UUID
  currentViewFieldId?: UUID
}

const warningMessage = 'Reload site? Changes you made may not be saved.'

export function CollectionPageV2 (props: CollectionPageV2Props) {
  const cogfy = useCogfy()
  const params = useParams<{ collectionId: UUID }>()
  const collectionId = (params.collectionId ?? props.collectionId)!
  const manager = useAppManager()
  const [state, setState] = useState<CollectionState>(new CollectionState(collectionId))
  const [controller, setController] = useState(new CollectionPageController(state, manager))
  const getCollection = useCollection(collectionId, state)
  const getUserCollectionPermissions = useQuery({
    queryKey: ['getUserCollectionPermissions', { collectionId }] as const,
    queryFn: ({ queryKey, signal }) => cogfy[queryKey[0]](queryKey[1], { signal })
  })
  const [accessControlAnchorEl, setAccessControlAnchorEl] = useState<HTMLElement | null>(null)
  const [deleteCollectionModalOpen, setDeleteCollectionModalOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const workspaceSlug = useWorkspaceSlug()
  const activeViewId = state.activeViewId
  const navigate = useNavigate()
  const toasts = useToasts()
  const { t } = useTranslation()

  useEffect(() => {
    if (collectionId) {
      const reducer = new TransactionReducer()
      const handleTransaction = (event: TransactionEvent) => {
        reducer.reduce(state, event)
      }

      cogfy.transactions.onTransaction(collectionId, handleTransaction)

      return () => {
        cogfy.transactions.offTransaction(collectionId, handleTransaction)
      }
    }
  }, [state, collectionId])

  useUndoRedo()
  useCopyPaste(controller)

  useEffect(() => {
    if (state.id !== collectionId) {
      manager.clearUndoRedo()
      const newState = new CollectionState(collectionId)
      const newController = new CollectionPageController(newState, manager)
      setState(newState)
      setController(newController)
    }
  }, [collectionId])

  useBlocker(() => manager.hasUnsavedChanges && !window.confirm(warningMessage))
  useBeforeUnload(event => {
    if (manager.hasUnsavedChanges) {
      event.returnValue = warningMessage
      return warningMessage
    }
  })
  const name = useSyncExternalStore(
    callback => state.addEventListener('nameChanged', callback),
    () => state.name
  )
  const locked = useLocked(state)

  const handleLock = () => {
    manager.lockCollection(state)
    setMenuAnchorEl(null)
  }

  const handleUnlock = () => {
    manager.unlockCollection(state)
    setMenuAnchorEl(null)
  }

  const handleExportCsv = () => {
    window.open(
      cogfy.collections.exportCsvUrl(workspaceSlug, collectionId, activeViewId),
      '_blank'
    )
  }

  const handleExportXlsx = () => {
    window.open(
      cogfy.collections.exportXlsxUrl(workspaceSlug, collectionId, activeViewId),
      '_blank'
    )
  }

  const handleDeleteCollection = useMutation({
    mutationFn: async () => {
      await cogfy.transactions.create({ operations: [{ type: 'soft_delete_collection', data: { collectionId } }] })
    },
    onError: () => toasts.error(t('Failed to delete collection')),
    onSuccess: () => navigate(`/${workspaceSlug}`)
  })
  const collectionType = getCollection.data?.type

  return (
    <div className="bg-base-100">
      <CollectionNavbar
        locked={locked}
        workspace={workspaceSlug}
        collection={getCollection.data}
        loading={getCollection.isLoading}
        onMenuClick={event => setMenuAnchorEl(event.currentTarget)}
        onShareClick={event => setAccessControlAnchorEl(event.currentTarget)}
      />

      <OverlayContent
        open={Boolean(accessControlAnchorEl)}
        onClose={() => setAccessControlAnchorEl(null)}
        anchorEl={accessControlAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <CollectionAccessControlEditor collectionId={collectionId} />
      </OverlayContent>

      <OverlayContent
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        anchorEl={menuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <CollectionMenu
          onDeleteClick={() => setDeleteCollectionModalOpen(true)}
          onExportCsvClick={collectionType === 'database' ? handleExportCsv : undefined}
          onExportXlsxClick={collectionType === 'database' ? handleExportXlsx : undefined}
          onLockClick={locked ? undefined : handleLock}
          onUnlockClick={locked ? handleUnlock : undefined}
        />
      </OverlayContent>

      {getCollection.data && (
        <ConfirmDeleteDialog
          title={`${t('Delete collection')}${getCollection.data.name ? ` ${getCollection.data.name}` : ''}?`}
          open={deleteCollectionModalOpen}
          onConfirm={() => handleDeleteCollection.mutate()}
          onClose={() => setDeleteCollectionModalOpen(false)}
        />
      )}

      {getCollection.data && (
        <>
          <CollectionName
            name={name}
            onChange={name => manager.renameCollection(state, { name })}
          />
          {getCollection.data.type === 'database' && (
            <DatabaseCollectionPage
              state={state}
              controller={controller}
              collection={getCollection.data}
              currentViewFieldId={props.currentViewFieldId}
              permissions={getUserCollectionPermissions.data}
            />
          )}
          {getCollection.data.type === 'folder' && (
            <FolderCollectionPage collection={getCollection.data} />
          )}
        </>
      )}
    </div>
  )
}
