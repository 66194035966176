import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export type CollectionNameProps = {
  name: string | null
  onChange: (name: string | null) => void
}

export function CollectionName (props: CollectionNameProps) {
  const { name, onChange } = props
  const ref = useRef<HTMLHeadingElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (name) {
      ref.current!.removeAttribute('collection-name-empty')
    } else {
      ref.current!.setAttribute('collection-name-empty', t('Untitled'))
    }

    ref.current!.textContent = name
  }, [name])

  const handleBlur = (event: React.FocusEvent<HTMLHeadingElement, Element>) => {
    onChange(event.currentTarget.textContent || null)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLHeadingElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      ref.current!.blur()
    }
  }

  const handleInput = (event: React.FormEvent<HTMLHeadingElement>) => {
    if (event.currentTarget.textContent) {
      event.currentTarget.removeAttribute('collection-name-empty')
    } else {
      event.currentTarget.innerHTML = ''
      event.currentTarget.setAttribute('collection-name-empty', t('Untitled'))
    }
  }

  return (
    <h1
      ref={ref}
      className={clsx(
        'text-2xl',
        'font-bold',
        'mx-2',
        'px-4',
        'py-2',
        'my-1',
        'empty:text-gray-300',
        '[&:not(:focus)]:truncate'
      )}
      contentEditable
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onInput={handleInput}
    />
  )
}
