import { FieldOperationConfigInput, UUID } from '@indigohive/cogfy-types'
import { useMemo } from 'react'
import { Select } from '../../Select'
import { FormInputProps } from '../types'

export function DateFormInput (props: FormInputProps) {
  const options = useMemo(
    () => {
      return [
        { label: 'Select a field', value: '' },
        ...(props.fields ?? [])
          .filter(field => field.type === 'date' || field.type === 'createDate' || field.type === 'updateDate')
          .map(field => ({ label: field.name, value: field.id }))
      ]
    },
    [props.fields]
  )

  return (
    <Select
      size="sm"
      value={getValue(props.value)}
      onChange={event => {
        props.onChange({
          type: 'fields',
          fields: {
            value: event.target.value
              ? [{ id: event.target.value as UUID }]
              : []
          }
        })
      }}
      options={options}
    />
  )
}

function getValue (
  value: FieldOperationConfigInput | undefined
): string {
  return value?.type === 'fields' && value.fields.value.length > 0
    ? value.fields.value[0].id ?? ''
    : ''
}
