import { SearchIcon, PencilIcon, PlusIcon, TrashIcon, Copy } from 'lucide-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CollectionPageController } from '../../collection-page-controller'
import { Button, Menu, OverlayContent, Tabs } from '../../../../components'
import { CollectionState, CollectionStateView, useActiveViewId, useSearchRecords, useViews } from '../../../../lib'
import { useDebouncedCallback } from 'use-debounce'

export type CollectionTabsProps = {
  state: CollectionState
  controller: CollectionPageController
  loading?: boolean
}

type ClickedView = {
  element: Element
  view: CollectionStateView
}

export function CollectionTabs (props: CollectionTabsProps) {
  const { state, controller, loading } = props
  const { t } = useTranslation()
  const [clickedView, setClickedView] = useState<ClickedView | null>(null)
  const activeViewId = useActiveViewId(state)
  const views = useViews(state) ?? []
  const searchRecords = useSearchRecords(state)
  const [currentSearchRecords, setCurrentSearchRecords] = useState(searchRecords ?? '')

  useEffect(() => {
    setCurrentSearchRecords(searchRecords ?? '')
  }, [searchRecords])

  const debouncedSetSearchRecords = useDebouncedCallback(() => controller.onSearchRecordsChanged(currentSearchRecords), 500)

  return (
    <>
      <div className="flex items-center group border-b sticky top-12 bg-base-100 z-30">
        <Tabs
          tabs={views?.map(view => ({ label: view.name ?? t('Untitled'), value: view.id }))}
          activeTab={activeViewId ?? views[0]?.id}
          onTabClick={tab => controller.onTabClick(tab)}
          onContextMenu={(event, tab) => {
            setClickedView({
              element: event.currentTarget,
              view: views.find(view => view.id === tab.value)!
            })
            event.preventDefault()
          }}
          variant="bordered"
          className="mr-1"
          loading={loading}
          controller={controller}
        />
        <div className="grow">
          <Button
            square
            ghost
            className={clsx(
              'opacity-0',
              'group-hover:opacity-100',
              'transition-opacity',
              'duration-300',
              'mx-1'
            )}
            size="xs"
            onClick={() => controller.onNewViewClick()}
            disabled={loading}
          >
            <PlusIcon size={16} />
          </Button>
        </div>
        <div>
          <label className="input input-xs input-bordered flex items-center gap-2 mr-4">
            <SearchIcon size={14} />
            <input
              type="text"
              className="grow"
              placeholder={t('Search')}
              value={currentSearchRecords}
              onChange={event => {
                setCurrentSearchRecords(event.target.value)
                debouncedSetSearchRecords()
              }}
              disabled={loading}
            />
          </label>
        </div>
      </div>
      <OverlayContent
        open={Boolean(clickedView)}
        anchorEl={clickedView?.element}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setClickedView(null)}
      >
        <Menu>
          <li>
            <a
              onClick={() => {
                controller.onDuplicateViewClick(clickedView!.view.id)
                setClickedView(null)
              }}
            >
              <Copy size={16} className='mr-2' />
              {t('Duplicate')}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                controller.onRenameViewClick(clickedView!.view)
                setClickedView(null)
              }}
            >
              <PencilIcon size={16} className="mr-2" />
              {t('Rename')}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                controller.onDeleteTabClick(clickedView!.view)
                setClickedView(null)
              }}
            >
              <TrashIcon size={16} className="mr-2" />
              {t('Delete')}
            </a>
          </li>
        </Menu>
      </OverlayContent>
    </>
  )
}
