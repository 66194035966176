import { UUID } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { ReactNode, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { CollectionPageController } from '../../../pages/CollectionPageV2/collection-page-controller'
import { TabType } from '../Tabs'

export type TabProps<T> = {
  tab: {
    value?: T
    label: ReactNode
    disabled?: boolean
  }
  activeTab: T
  onTabClick?: (tab: T) => void
  onContextMenu?: (event: React.MouseEvent, tab: { value?: T, label: ReactNode, disabled?: boolean }) => void
  canDrag?: boolean
  controller: CollectionPageController
}

export function Tab<T extends string> (props: TabProps<T>) {
  const {
    tab,
    activeTab,
    onTabClick,
    onContextMenu,
    canDrag = true,
    controller
  } = props
  const ref = useRef<HTMLAnchorElement>(null)

  const [{ isDragging }, drag] = useDrag({
    type: 'Tab',
    canDrag,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    item: { view: tab, change: 0 }
  }, [tab])

  const [{ isOver }, drop] = useDrop({
    accept: 'Tab',
    collect: monitor => ({
      isOver: monitor.isOver()
    }),
    drop: (item: { view: TabType<UUID>, change: number }) => {
      const view = { id: item.view.value!, name: item.view.label as string }
      const droppedOn = { id: tab.value as UUID, name: tab.label as string }
      controller.onDropView(view, droppedOn)
    }
  }, [tab])

  drag(drop(ref))

  return (
    <a
      ref={ref}
      style={{ borderBottomWidth: activeTab === tab.value ? undefined : 0 }}
      key={tab.value}
      role="tab"
      className={clsx(
        'tab',
        'flex-shrink-0',
        'whitespace-nowrap',
        activeTab === tab.value && 'tab-active',
        tab.disabled && 'tab-disabled',
        isDragging && 'opacity-10',
        isOver && 'bg-base-200'
      )}
      onClick={() => {
        if (!tab.disabled && tab.value) {
          onTabClick?.(tab.value)
        }
      }}
      onContextMenu={event => {
        if (!tab.disabled) {
          onContextMenu?.(event, tab)
        }
      }}
    >
      {tab.label}
    </a>
  )
}
