import clsx from 'clsx'
import { Button } from '../../../components'
import { ChatMessageReactionData, ChatMessageReactionType, UUID } from '@indigohive/cogfy-types'
import { ThumbsDownIcon, ThumbsUpIcon } from 'lucide-react'
import { useMutation } from '@tanstack/react-query'
import { useCogfy } from '../../../hooks'

export type ChatMessageReactionProps = {
  chatId: UUID
  messageId: UUID
  reaction?: ChatMessageReactionType | null
  onMessageReaction?: () => void
}

export function ChatMessageReaction (props: ChatMessageReactionProps) {
  const { chatId, messageId, reaction, onMessageReaction } = props

  const cogfy = useCogfy()
  const setChatMessageReaction = useMutation({
    mutationFn: async (params: { type: ChatMessageReactionType | null, data: ChatMessageReactionData | null }) => {
      await cogfy.setChatMessageReaction({ chatId, chatMessageId: messageId, ...params })
    },
    onSuccess: () => {
      onMessageReaction?.()
    }
  })

  return (
    <div
      className={clsx(
        'ml-12',
        !reaction && [
          'opacity-0',
          'group-hover:opacity-100',
          'transition-opacity'
        ]
      )}
    >
      <Button
        circle
        ghost
        color="success"
        size="sm"
        onClick={() => {
          setChatMessageReaction.mutate({ type: reaction === 'upvote' ? null : 'upvote', data: null })
        }}
      >
        <ThumbsUpIcon
          size={16}
          className={clsx(reaction === 'upvote' && 'text-success')}
        />
      </Button>
      <Button
        circle
        ghost
        size="sm"
        onClick={() => {
          setChatMessageReaction.mutate({ type: reaction === 'downvote' ? null : 'downvote', data: null })
        }}
      >
        <ThumbsDownIcon
          size={16}
          className={clsx(reaction === 'downvote' && 'text-error')}
        />
      </Button>
    </div>
  )
}
