import { PlusIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components'
import { useCogfy } from '../../../hooks'
import { useQuery } from '@tanstack/react-query'

export function AddGoogleCalendarButton () {
  const cogfy = useCogfy()
  const { t } = useTranslation()

  const data = {
    provider: 'googleCalendar' as const
  }
  const getGoogleCalendarAuthUrl = useQuery({
    queryKey: ['getGoogleAuthUrl', data],
    queryFn: ({ signal }) => cogfy.getGoogleAuthUrl(data, { signal })
  })

  return (
    <Button
      color="primary"
      size="sm"
      disabled={getGoogleCalendarAuthUrl.isLoading}
      to={getGoogleCalendarAuthUrl.data?.authUrl}
    >
      <PlusIcon size={16} />
      {t('WorkspaceConnectionsPage:Connect')}
    </Button>
  )
}
