import { UUID } from '@indigohive/cogfy-types'
import { useQuery } from '@tanstack/react-query'
import { useCogfy } from '../../hooks'
import { Mentions, MentionsValue } from '../Mentions'

export type FieldPropertyMentionValue = {
  strings: string[]
  args: { fieldId: UUID, property?: string }[]
}

export type FieldPropertyMentionField = {
  id: UUID
  name: string
  type: string
}

export type FieldPropertyMentionProps = {
  value?: FieldPropertyMentionValue
  onChange: (value: FieldPropertyMentionValue) => void
  fields: FieldPropertyMentionField[]
}

const DEFAULT_VALUE: FieldPropertyMentionValue = {
  strings: [''],
  args: []
}

export function FieldPropertyMention (props: FieldPropertyMentionProps) {
  const { value = DEFAULT_VALUE, onChange } = props

  const cogfy = useCogfy()
  const getFieldProperties = useQuery({
    queryKey: ['getFieldProperties'],
    queryFn: ({ signal }) => cogfy.getFieldProperties({ signal })
  })

  const fields = props.fields
  const properties = getFieldProperties.data

  const options = fields
    .map(field => {
      return properties?.data[field.type].map(property => {
        if (property.name === 'value') {
          return {
            id: field.id,
            name: field.name
          }
        } else {
          return {
            id: field.id + ':' + property.name,
            name: field.name + ': ' + property.name
          }
        }
      }) ?? []
    })
    .flat()

  return (
    <Mentions
      value={mapToMentions(value, fields)}
      onChange={value => onChange({
        strings: value.parts,
        args: value.options.map(option => {
          const [fieldId, property] = option.id.split(':')

          return { fieldId: fieldId as UUID, property }
        })
      })}
      options={options}
    />
  )
}

function mapToMentions (
  value: FieldPropertyMentionValue,
  fields: FieldPropertyMentionField[]
): MentionsValue {
  return {
    parts: value.strings,
    options: value.args.map(arg => {
      const field = fields?.find(field => field.id === arg.fieldId)

      if (arg.property) {
        if (arg.property === 'value') {
          return {
            id: arg.fieldId,
            name: field ? field.name : '???'
          }
        } else {
          return {
            id: arg.fieldId + ':' + arg.property,
            name: field ? `${field.name}: ${arg.property}` : '???'
          }
        }
      } else {
        return {
          id: arg.fieldId,
          name: field ? field.name : '???'
        }
      }
    })
  }
}
