import { useQuery } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'
import { CollectionStateField } from '../lib'

export function useOperationSchemas (
  field: CollectionStateField | null
) {
  const cogfy = useCogfy()

  const getOperations = useQuery({
    queryKey: ['getOperationSchemas', field?.id],
    queryFn: ({ signal }) => cogfy.getOperations({ outputType: field!.type }, { signal }),
    enabled: field !== null
  })

  return getOperations
}
