import { useFormik } from 'formik'
import { useCogfy } from '../../../../hooks'
import { useState } from 'react'
import { UUID } from '@indigohive/cogfy-types'
import { GetWorkspaceBySlugResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceBySlug'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import * as yup from 'yup'
import { Button, Dialog, Input } from '../../../../components'
import { useTranslation } from 'react-i18next'

export type AddMemberDialogProps = {
  onSuccess: () => void
  onClose: () => void
  open: boolean
  workspace: GetWorkspaceBySlugResult
}

export function AddMemberDialog (props: AddMemberDialogProps) {
  const { onSuccess, onClose, open, workspace } = props
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const cogfy = useCogfy()
  const { t } = useTranslation()

  const userNotFoundMessage = t('User not found')
  const userAlreadyAddedToWorkspaceMessage = t('User already added')

  const formik = useFormik({
    initialValues: {
      email: '',
      name: ''
    },
    validationSchema: yup.object().shape({
      email: yup.string().email(t('Field must be an email')).required(t('Email is required'))
    }),
    onSubmit: async values => {
      try {
        const user = await cogfy.getUserByEmail({ email: values.email })
        createMember.mutate(user.id)
        setErrorMessage(null)
        formik.resetForm()
        onSuccess()
      } catch (error) {
        if (cogfy.isNotFound(error)) {
          setErrorMessage(userNotFoundMessage)
        }
      }
    }
  })
  const createMember = useMutation({
    mutationFn: async (userId: UUID) => {
      try {
        await cogfy.createWorkspaceMember({ userId })
        setErrorMessage(null)
        formik.resetForm()
        onSuccess()
        onClose()
      } catch (error) {
        if (cogfy.isConflict(error)) {
          setErrorMessage(userAlreadyAddedToWorkspaceMessage)
        }
      }
    }
  })
  const createUser = useMutation({
    mutationFn: async () => {
      try {
        await cogfy.createUser({ email: formik.values.email, name: formik.values.name })
        setErrorMessage(null)
        formik.resetForm()
        onSuccess()
        onClose()
      } catch { }
    }
  })

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setErrorMessage(null)
          formik.resetForm()
          onClose()
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <h2 className="text-md font-bold mb-10">{t('membersPage:Invite for Workspace')} &quot;{workspace.name}&quot;</h2>
          <div>
            <Input
              className="bg-background-default"
              label={t('User e-mail')}
              size="sm"
              type="text"
              placeholder="email@indigohive.com.br"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              disabled={errorMessage !== userAlreadyAddedToWorkspaceMessage && Boolean(errorMessage)}
            />
            <div className="flex flex-col mt-4">
              {errorMessage && (
                <span className="text-gray-400 text-xs mt-1">{errorMessage}</span>
              )}
              {(formik.errors.email && formik.touched.email) && (
                <span className="text-rose-600 text-xs mt-1">{formik.errors.email}</span>
              )}
              {errorMessage && errorMessage !== userAlreadyAddedToWorkspaceMessage && (
                <>
                  <Input
                    size="sm"
                    type="text"
                    placeholder={t('membersPage:New user name')}
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className={clsx('bg-background-default mt-1')}
                  />
                  <div className='flex gap-2 place-self-end mt-4'>
                    <Button
                      size="md"
                      onClick={onClose}
                    >
                      {t('Cancel')}
                    </Button>
                    <Button
                      type="button"
                      size="md"
                      className="bg-black text-white hover:text-black"
                      disabled={createUser.isPending}
                      onClick={() => createUser.mutate()}
                    >
                      {t('Create user')}
                    </Button>
                  </div>
                </>
              )}
              {(!errorMessage || errorMessage === userAlreadyAddedToWorkspaceMessage) && (
                <div className='flex gap-2 place-self-end'>
                  <Button
                    size="md"
                    onClick={onClose}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    size="md"
                    disabled={formik.isSubmitting}
                    className="bg-black text-white hover:text-black"
                  >
                    {t('Add to workspace')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </form>
      </Dialog>
    </>
  )
}
