import { WorkspaceLogoUpdatedEvent } from '@indigohive/cogfy-types/events/workspaceLogoUpdatedEvent'
import { WorkspaceThemeUpdatedEvent } from '@indigohive/cogfy-types/events/workspaceThemeUpdatedEvent'
import { SocketEvent } from '../socket'
import { BaseClient, BaseClientOptions } from './base-client'

export class WorkspacesClient extends BaseClient {
  onWorkspaceLogoUpdated: SocketEvent<WorkspaceLogoUpdatedEvent>
  onWorkspaceThemeUpdated: SocketEvent<WorkspaceThemeUpdatedEvent>

  constructor (options: BaseClientOptions) {
    super(options)

    this.onWorkspaceLogoUpdated = new SocketEvent(this.socket, 'workspace_logo_updated')
    this.onWorkspaceThemeUpdated = new SocketEvent(this.socket, 'workspace_theme_updated')
  }
}
