import { CollectionType, UUID } from '@indigohive/cogfy-types'
import { HomeIcon, FolderIcon, TableIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Breadcrumbs, Skeleton } from '../../../../components'

export type ColletionBreadcrumbData = {
  id: UUID
  name: string | null
  type: CollectionType
  parent: ColletionBreadcrumbData | null
}

export type CollectionBreadcrumbProps = {
  collection?: ColletionBreadcrumbData
  loading?: boolean
  workspace: string
}

export function CollectionBreadcrumb (props: CollectionBreadcrumbProps) {
  const { collection, loading, workspace } = props
  const { t } = useTranslation()

  const items = []
  let parent = collection?.parent ?? null

  while ((parent ?? null) !== null) {
    items.unshift({
      label: parent?.name ?? <span className="text-gray-300">{t('Untitled')}</span>,
      to: `/${workspace}/${parent?.id}`,
      icon: parent?.type === 'folder'
        ? <FolderIcon size={16} />
        : <TableIcon size={16} />
    })
    parent = parent?.parent ?? null
  }

  return (
    <Breadcrumbs
      items={[
        { label: t('Home'), to: `/${workspace}`, icon: <HomeIcon className="w-4 h-4" /> },
        ...items,
        {
          label: loading
            ? <Skeleton className="w-32 h-4" />
            : collection?.name ?? <span className="text-gray-300">{t('Untitled')}</span>,
          icon: collection?.type === 'folder'
            ? <FolderIcon size={16} />
            : <TableIcon size={16} />
        }
      ]}
    />
  )
}
