import { GetRecordByIdResult } from '@indigohive/cogfy-types/endpoints/getRecordById'
import { GetCollectionFieldsResultData } from '@indigohive/cogfy-types/endpoints/getCollectionFields'
import { TextRecordProperty, UpdateRecordPropertiesOperationData, UUID } from '@indigohive/cogfy-types'
import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export type TextRecordPropertyInputProps = {
  collectionId: UUID
  field: Extract<GetCollectionFieldsResultData, { type: 'text' }>
  record: GetRecordByIdResult
  onUpdateRecordProperties?: (data: UpdateRecordPropertiesOperationData) => void
  canEdit?: boolean
}

export function TextRecordPropertyInput (props: TextRecordPropertyInputProps) {
  const { collectionId, field, record, canEdit } = props

  const property = record.properties[field.id] as TextRecordProperty | undefined

  const [value, setValue] = useState(property?.text?.value ?? '')

  const onChange = useDebouncedCallback(
    (value: string) => {
      props.onUpdateRecordProperties?.({
        recordId: record.id,
        collectionId,
        properties: {
          [field.id]: {
            type: 'text',
            text: {
              value: value || null
            }
          }
        }
      })
    },
    300
  )

  return (
    <div>
      <textarea
        disabled={!canEdit}
        className="textarea textarea-bordered w-full"
        value={value}
        onChange={event => {
          setValue(event.target.value)
          onChange(event.target.value)
        }}
      />
    </div>
  )
}
