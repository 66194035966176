import { UUID } from '@indigohive/cogfy-types'
import clsx, { ClassValue } from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'
import { avatarInitials } from '../../../helpers'
import { FormattedDate } from '../../../components'
import { useTranslation } from 'react-i18next'

export type ChatBubbleColor =
  'neutral' |
  'primary' |
  'secondary' |
  'accent' |
  'info' |
  'success' |
  'warning' |
  'error'

export type ChatBubbleProps = PropsWithChildren<{
  className?: ClassValue
  position?: 'start' | 'end'
  color?: ChatBubbleColor
  avatarImageSrc?: string
  sendDate?: string
  user?: {
    id: UUID
    name: string
  }
  buttons?: ReactNode
  hideAvatar?: boolean
}>

export function ChatBubble (props: ChatBubbleProps) {
  const { className, children, color, position = 'start', avatarImageSrc, user, sendDate, buttons, hideAvatar } = props
  const { t } = useTranslation('common')

  return (
    <div
      className={clsx(
        className,
        'chat',
        'break-words',
        {
          'chat-start': position === 'start',
          'chat-end': position === 'end'
        }
      )}
    >
      {avatarImageSrc && (
        <div className={clsx('chat-image avatar', hideAvatar && 'invisible')}>
          <div className="w-10 rounded-full">
            <img src={avatarImageSrc} />
          </div>
        </div>
      )}
      {user && (
        <div className={clsx('chat-image avatar placeholder', hideAvatar && 'invisible')} title={user.name}>
          <div className="w-10 rounded-full bg-neutral text-neutral-content">
            {avatarInitials(user.name)}
          </div>
        </div>
      )}
      {(position === 'start') && !user && !avatarImageSrc && (
        <div className={clsx('chat-image avatar placeholder', hideAvatar && 'invisible')} title={t('Unknown user')}>
          <div className="w-10 rounded-full bg-neutral text-neutral-content">
            ?
          </div>
        </div>
      )}
      <div
        className={clsx(
          {
            'bg-[#E5F2FB]': color === 'primary',
            'chat-bubble-secondary': color === 'secondary',
            'chat-bubble-accent': color === 'accent',
            'chat-bubble-info': color === 'info',
            'chat-bubble-success': color === 'success',
            'chat-bubble-warning': color === 'warning',
            'chat-bubble-error': color === 'error'
          },
          color === 'neutral' && [
            'bg-base-100',
            'text-base-content'
          ],
          'px-3',
          'py-2',
          'rounded-t-lg',
          position === 'end' ? 'rounded-bl-lg' : 'rounded-br-lg'
        )}
      >
        <div className="flex items-end gap-2">
          {children}
          {sendDate && (
            <p
              className={clsx(
                'text-xs',
                'text-slate-400'
              )}
            >
              <FormattedDate date={sendDate} format="p" />
            </p>
          )}
        </div>
        {buttons && (
          <div className="flex items-end gap-2">
            {buttons}
          </div>
        )}
      </div>
    </div>
  )
}
