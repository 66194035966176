import { useParams } from 'react-router-dom'
import { useWorkspace } from '../../../../hooks'
import { Skeleton } from '../../../Skeleton'
import { WorkspaceLogo } from '../../../WorkspaceLogo'
import { Button } from '../../../Button'
import { useEffect, useState } from 'react'
import { OverlayContent } from '../../../OverlayContent'
import { ChevronDownIcon } from 'lucide-react'
import { GetWorkspacesResult } from '@indigohive/cogfy-types/endpoints/getWorkspaces'
import { DrawerWorkspacesMenu } from '../DrawerWorkspacesMenu'

export type DrawerWorkspacesSectionProps = {
  drawerWidth: number
  workspaces?: GetWorkspacesResult
  loading?: boolean
  onSettingsClick?: () => void
}

export function DrawerWorkspacesSection (props: DrawerWorkspacesSectionProps) {
  const workspace = useParams().workspace!
  const getWorkspace = useWorkspace(workspace)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (anchorEl) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [anchorEl])

  return (
    <div>
      <Button
        className="w-full px-0 hover:bg-white"
        ghost
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <div className="flex items-center gap-2 justify-between w-full">
          <div className="flex items-center">
            <WorkspaceLogo
              className="w-8 rounded-lg mr-2"
              name={getWorkspace.data?.name ?? 'Workspace'}
              logoUrl={getWorkspace.data?.logoUrl}
            />
            {getWorkspace.isLoading
              ? <Skeleton className="w-16 h-4" />
              : getWorkspace.data?.name ?? 'Workspace'}
          </div>
          <span className="pr-1.5">
            <ChevronDownIcon size={24} />
          </span>
        </div>
      </Button>

      <OverlayContent
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorPosition={{ top: 140, left: 10 }}
        onClose={() => setAnchorEl(null)}
      >
        <DrawerWorkspacesMenu
          drawerWidth={props.drawerWidth}
          workspaces={props.workspaces}
          currentWorkspace={getWorkspace.data}
          onSettingsClick={props.onSettingsClick}
          onClosePicker={() => setAnchorEl(null)}
        />
      </OverlayContent>
    </div>
  )
}
