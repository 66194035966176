import { VindiBillRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../../FieldCell'
import { useTranslation } from 'react-i18next'
import { FormattedDate, OptionChip, OptionChipColorName } from '../../../../components'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

const STATUS_TO_COLOR: Record<string, OptionChipColorName> = {
  paid: 'green',
  pending: 'yellow'
}

export type VindiBillCellProps = Pick<FieldCellProps<VindiBillRecordProperty>, 'property' | 'textWrap'>

export function VindiBillCell (props: VindiBillCellProps) {
  const { property, textWrap } = props

  const { t } = useTranslation('vindiBillCell')

  const bill = property?.['vindi.bill']
  const price = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: bill?.currency ?? 'BRL' }).format(bill?.amount ?? 0)

  return (
    <>
      {bill && (
        <Link
          to={`https://app.vindi.com.br/admin/bills/${bill.externalId}`}
          target="_blank"
          className={clsx(
            'h-full',
            'w-full',
            'px-2',
            'py-1',
            'hover:bg-base-200',
            'active:bg-base-300',
            'text-gray-600',
            textWrap && 'whitespace-pre-wrap',
            !textWrap && 'truncate'
          )}
        >
          <div className="flex flex-col gap-1">
            <OptionChip
              color={STATUS_TO_COLOR[bill.status]}
              label={t(bill.status)}
            />
            <span>
              {price} - {t('Due date')} <FormattedDate date={bill.dueDate} />
            </span>
          </div>
        </Link>
      )}
    </>
  )
}
