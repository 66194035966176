import { FieldType } from '@indigohive/cogfy-types'
import { createElement, Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FIELD_ICONS_BY_TYPE } from '../../../../../../field-types'
import clsx from 'clsx'

export type FieldsMenuProps = {
  onClick?: (fieldType: FieldType) => void
  FIELD_TYPES_BY_CATEGORY: FieldType[][]
  searchFieldName: string
}

export function FieldsMenu (props: FieldsMenuProps) {
  const fieldTypesByCategory = props.FIELD_TYPES_BY_CATEGORY
  const searchFieldName = props.searchFieldName
  const { t } = useTranslation()

  const filteredFields = useMemo(
    () => filterFieldsByNameRecursive(fieldTypesByCategory, searchFieldName, t),
    [fieldTypesByCategory, searchFieldName]
  )

  const noContent = filteredFields.every(fieldArray => fieldArray.length === 0)

  return (
    <div>
      {noContent && (
        <li>
          <a>
            {t('No options found')}
          </a>
        </li>
      )}
      {filteredFields.map((field, index) => (
        <Fragment key={index}>
          {field.map(fieldType =>
            <li key={fieldType}>
              <a onClick={() => props.onClick?.(fieldType)}>
                {createElement(
                  FIELD_ICONS_BY_TYPE[fieldType],
                  { size: 16 }
                )}
                {t(`fieldType:${fieldType}`, fieldType)}
              </a>
            </li>
          )}
          {index < fieldTypesByCategory.length - 1 && (
            <div
              className={clsx(
                'divider h-1 my-1',
                searchFieldName && 'hidden'
              )}
            />
          )}
        </Fragment>
      ))}
    </div>
  )
}

function filterFieldsByNameRecursive (
  fieldTypesByCategory: FieldType[][],
  searchFieldName: string,
  t: ReturnType<typeof useTranslation>['t']
): FieldType[][] {
  if (!searchFieldName) {
    return fieldTypesByCategory
  }

  return fieldTypesByCategory.map(fieldTypes =>
    fieldTypes.filter(fieldType =>
      t(`fieldType:${fieldType}`).toLowerCase().includes(searchFieldName.toLowerCase())
    )
  )
}
