import clsx, { ClassValue } from 'clsx'
import { PropsWithChildren } from 'react'

export type MenuProps = PropsWithChildren & {
  className?: ClassValue
  size?: 'sm' | 'md'
  style?: React.CSSProperties
  maxWidth?: false
}

export function Menu (props: MenuProps) {
  const { className, size, style } = props

  return (
    <menu
      className={clsx(
        'menu',
        'border',
        'bg-base-100',
        'shadow',
        'min-w-64',
        props.maxWidth !== false && 'max-w-96',
        'rounded-lg',
        'inline-block',
        'overflow-y-auto',
        size === 'sm' && 'menu-sm',
        className
      )}
      style={style}
    >
      {props.children}
    </menu>
  )
}
