import { RunTransactionCommand } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState } from '../collection-state'

export type RenameCollectionCommandData = {
  name: string | null
}

export class RenameCollectionCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: RenameCollectionCommandData
  private readonly _previousName: string | null

  constructor (
    state: CollectionState,
    data: RenameCollectionCommandData
  ) {
    this._state = state
    this._data = data

    this._previousName = state.name
  }

  run (): RunTransactionCommand {
    this._state.setName(this._data.name)

    return {
      operations: [
        {
          type: 'rename_collection',
          data: {
            collectionId: this._state.id,
            name: this._data.name
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    this._state.setName(this._previousName)

    return {
      operations: [
        {
          type: 'rename_collection',
          data: {
            collectionId: this._state.id,
            name: this._previousName
          }
        }
      ]
    }
  }
}
