import { FieldData, FieldType, RecordProperty, UUID, ViewFieldConfig } from '@indigohive/cogfy-types'
import { createElement } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { CollectionStateRecord } from '../../lib'
import { CollectionPageController } from '../../pages/CollectionPageV2/collection-page-controller'
import {
  AuthorCell,
  BooleanCell,
  ChatCell,
  ChatMessageCell,
  ConnectionCell,
  CreateDateCell,
  DateCell,
  FileCell,
  HttpResponseCell,
  JsonCell,
  NumberCell,
  NuvemFiscalNfseCell,
  ReferenceCell,
  ScheduleCell,
  SelectCell,
  SendgridInboundEmailCell,
  StopwatchCell,
  StripeCustomerCell,
  TextCell,
  UpdateDateCell,
  VectorCell,
  VindiBillCell,
  VindiCustomerCell,
  VindiSubscriptionCell
} from './components'
import { ErrorCell, PendingCell, StaleIndicator } from './states'

export const FIELD_CELL_BY_TYPE: Record<FieldType, ((props: FieldCellProps<any, any, any>) => JSX.Element) | undefined> = {
  author: AuthorCell,
  boolean: BooleanCell,
  chat: ChatCell,
  chatMessage: ChatMessageCell,
  connection: ConnectionCell,
  createDate: CreateDateCell,
  date: DateCell,
  file: FileCell,
  json: JsonCell,
  httpResponse: HttpResponseCell,
  number: NumberCell,
  'nuvemFiscal.nfse': NuvemFiscalNfseCell,
  reference: ReferenceCell,
  schedule: ScheduleCell,
  'sendgrid.inboundEmail': SendgridInboundEmailCell,
  select: SelectCell,
  stopwatch: StopwatchCell,
  'stripe.customer': StripeCustomerCell,
  text: TextCell,
  updateDate: UpdateDateCell,
  vector: VectorCell,
  'vindi.bill': VindiBillCell,
  'vindi.customer': VindiCustomerCell,
  'vindi.subscription': VindiSubscriptionCell
}

export type FieldCellProps<T1 = RecordProperty, T2 = FieldData, T3 = ViewFieldConfig> = {
  property: T1 | undefined
  collectionId: UUID
  record: CollectionStateRecord
  field: {
    id: UUID
    type: FieldType
    data?: T2 | null
  }
  viewField: { config?: T3 | null }
  controller: CollectionPageController
  textWrap?: boolean
  readonly?: boolean
}

export function FieldCell (props: FieldCellProps) {
  const { field, record } = props

  const fieldCellComponent = FIELD_CELL_BY_TYPE[field.type]

  const hasError = Boolean(record.properties[field.id]?.error?.message) || Boolean(record.properties[field.id]?.error?.code)
  const isLoading = Boolean(record.properties[field.id]?.pending)
  const isStale = Boolean(record.properties[field.id]?.stale)

  return (
    <ErrorBoundary
      fallback={
        <span className="text-error text-xs opacity-70 mx-2 my-1">
          Error rendering cell
        </span>
      }
    >
      {fieldCellComponent && !hasError && !isLoading && createElement(fieldCellComponent, props)}
      {isLoading && !hasError && <PendingCell />}
      {isStale && <StaleIndicator {...props} />}
      {hasError && <ErrorCell {...props} />}
    </ErrorBoundary>
  )
}
