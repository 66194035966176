import { ArrowRightIcon, CopyIcon, FolderIcon, PencilIcon, TableIcon, TrashIcon } from 'lucide-react'
import { GetCollectionsTreeResultData } from '@indigohive/cogfy-types/endpoints/getCollectionsTree'
import { usePermissions, useWorkspaceSlug } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export type CollectionContextMenuProps = {
  collection?: GetCollectionsTreeResultData | null
  onOpenClick?: (collection: GetCollectionsTreeResultData) => void
  onCreateFolderClick?: (collection: GetCollectionsTreeResultData) => void
  onCreateDatabaseClick?: (collection: GetCollectionsTreeResultData) => void
  onDeleteClick?: (collection: GetCollectionsTreeResultData) => void
  onDuplicateCollectionClick?: (collection: GetCollectionsTreeResultData) => void
  onRenameClick?: (collection: GetCollectionsTreeResultData) => void
}

export function CollectionContextMenu (props: CollectionContextMenuProps) {
  const {
    collection,
    onOpenClick,
    onCreateFolderClick,
    onCreateDatabaseClick,
    onDeleteClick,
    onDuplicateCollectionClick,
    onRenameClick
  } = props
  const workspaceSlug = useWorkspaceSlug()
  const permissions = usePermissions()
  const { t } = useTranslation()

  const canCreateCollection = permissions.isAdmin
  const canDeleteCollection = permissions.isAdmin

  const isFolder = collection?.type === 'folder'

  return (
    <ul className="menu bg-base-100 w-56 rounded-box border shadow">
      <li>
        <Link
          to={`/${workspaceSlug}/${collection?.id}`}
          onClick={() => collection && onOpenClick?.(collection)}
        >
          <ArrowRightIcon size={16} />
          {t('Open')}
        </Link>
      </li>
      {canCreateCollection && isFolder && (
        <li>
          <a onClick={() => collection && onCreateFolderClick?.(collection)}>
            <FolderIcon size={16} />
            {t('collectionContextMenu:Create folder')}
          </a>
        </li>
      )}
      {canCreateCollection && isFolder && (
        <li>
          <a onClick={() => collection && onCreateDatabaseClick?.(collection)}>
            <TableIcon size={16} />
            {t('collectionContextMenu:Create database')}
          </a>
        </li>
      )}
      <li>
        <a onClick={() => collection && onRenameClick?.(collection)}>
          <PencilIcon size={16} />
          {t('Rename')}
        </a>
      </li>
      {canCreateCollection && !isFolder && (
        <li>
          <a onClick={() => collection && onDuplicateCollectionClick?.(collection)}>
            <CopyIcon size={16} />
            {t('Duplicate')}
          </a>
        </li>
      )}
      {canDeleteCollection && (
        <li>
          <a onClick={() => collection && onDeleteClick?.(collection)}>
            <TrashIcon size={16} />
            {t('Delete')}
          </a>
        </li>
      )}
    </ul>
  )
}
