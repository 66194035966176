import type { CollectionParentUpdatedEvent } from '@indigohive/cogfy-types/events/collectionParentUpdated'
import { CollectionCreatedEvent } from '@indigohive/cogfy-types/events/collectionCreated'
import { BaseClient, BaseClientOptions } from './base-client'
import { SocketEvent } from '../socket'

export class CollectionsClient extends BaseClient {
  onCollectionCreated: SocketEvent<CollectionCreatedEvent>
  onCollectionParentUpdated: SocketEvent<CollectionParentUpdatedEvent>

  constructor (options: BaseClientOptions) {
    super(options)

    this.onCollectionCreated = new SocketEvent(this.socket, 'collection_created')
    this.onCollectionParentUpdated = new SocketEvent(this.socket, 'collection_parent_updated')
  }

  exportCsvUrl (
    workspaceSlug: string,
    collectionId: string,
    viewId?: string | null
  ): string {
    return this.axios.defaults.baseURL + `/collections/${collectionId}/export-csv?workspace=${workspaceSlug}&viewId=${viewId}`
  }

  exportXlsxUrl (
    workspaceSlug: string,
    collectionId: string,
    viewId?: string | null
  ): string {
    return this.axios.defaults.baseURL + `/collections/${collectionId}/export-xlsx?workspace=${workspaceSlug}&viewId=${viewId}`
  }
}
