import { FormInputProps } from '../../types'
import { MultipleOptionsTextFormInput } from './_MultipleOptionsTextFormInput'
import { MultipleTextFormInput } from './_MultipleTextFormInput'
import { OptionsTextFormInput } from './_OptionsTextFormInput'
import { SingleTextFormInput } from './_SingleTextFormInput'

export function TextFormInput (props: FormInputProps) {
  const { schema } = props
  const { multiple, options } = schema

  return (
    <>
      {options && !multiple && <OptionsTextFormInput {...props} />}
      {options && multiple && <MultipleOptionsTextFormInput {...props} />}
      {!options && !multiple && <SingleTextFormInput {...props} />}
      {!options && multiple && <MultipleTextFormInput {...props} />}
    </>
  )
}
