import { FormInputProps } from '../../types'
import { FieldPropertyMention } from '../../../FieldPropertyMention'

export function SingleTextFormInput (props: FormInputProps) {
  return (
    <FieldPropertyMention
      value={props.value?.type === 'template' ? props.value.template : undefined}
      onChange={value => props.onChange({ type: 'template', template: value })}
      fields={props.fields ?? []}
    />
  )
}
