import { DownloadIcon, LockIcon, LockOpenIcon, TrashIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Menu } from '../../../../components'

export type CollectionMenuProps = {
  onDeleteClick?: () => void
  onExportCsvClick?: () => void
  onExportXlsxClick?: () => void
  onLockClick?: () => void
  onUnlockClick?: () => void
}

export function CollectionMenu (props: CollectionMenuProps) {
  const {
    onDeleteClick,
    onExportCsvClick,
    onExportXlsxClick,
    onLockClick,
    onUnlockClick
  } = props

  const { t } = useTranslation()

  return (
    <Menu>
      {onUnlockClick && (
        <li>
          <a onClick={onUnlockClick}>
            <LockOpenIcon size={16} />
            {t('Unlock')}
          </a>
        </li>
      )}
      {onLockClick && (
        <li>
          <a onClick={onLockClick}>
            <LockIcon size={16} />
            {t('Lock')}
          </a>
        </li>
      )}
      {onExportCsvClick && (
        <li>
          <a onClick={onExportCsvClick}>
            <DownloadIcon size={16} />
            {t('Export CSV')}
          </a>
        </li>
      )}
      {onExportXlsxClick && (
        <li>
          <a onClick={onExportXlsxClick}>
            <DownloadIcon size={16} />
            {t('Export XLSX')}
          </a>
        </li>
      )}
      <li>
        <a onClick={onDeleteClick}>
          <TrashIcon size={16} />
          {t('Delete collection')}
        </a>
      </li>
    </Menu>
  )
}
