import { UUID } from '@indigohive/cogfy-types'
import { FormInputProps } from '../../types'
import { MultiSelect } from '../../../MultiSelect'
import { Select } from '../../../Select'

export function CollectionsFormInput (props: FormInputProps) {
  const multiple = Boolean(props.schema.multiple)

  const values = props.value?.type === 'collections'
    ? props.value.collections.value
    : []

  const handleChange = (id: string) => {
    const newValues = values.some(value => value.id === id)
      ? values.filter(item => item.id !== id)
      : [...values, { id: id as UUID }]

    props.onChange({ type: 'collections', collections: { value: newValues } })
  }

  const handleClear = () => {
    props.onChange({ type: 'collections', collections: { value: [] } })
  }

  const handleSelectAll = () => {
    props.onChange({
      type: 'collections',
      collections: {
        value: props.collections?.map(collection => ({ id: collection.id })) ?? []
      }
    })
  }

  const options = (props.collections ?? []).map(collection => ({
    value: collection.id,
    label: collection.name ?? 'Untitled'
  }))

  return (
    <>
      {multiple && (
        <MultiSelect
          labels={{
            selectItems: 'Select options',
            allItems: 'All selected',
            noResultsFound: 'No results found'
          }}
          options={options}
          selected={values.map(value => value.id)}
          onChange={value => { handleChange(value) }}
          onClearSelections={handleClear}
          onSelectAll={handleSelectAll}
        />
      )}

      {!multiple && (
        <Select
          size="sm"
          value={values[0]?.id ?? ''}
          options={[
            { value: '', label: 'Select a collection' },
            ...options
          ]}
          onChange={event => {
            props.onChange({ type: 'collections', collections: { value: [{ id: event.target.value as UUID }] } })
          }}
        />
      )}
    </>
  )
}
