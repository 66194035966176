import { useEffect, useRef, useState } from 'react'
import { Button, Input, Menu, OverlayContent } from '../../../components'
import { CircleAlertIcon, ClockIcon, SendHorizontalIcon } from 'lucide-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useCogfy } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { GetChatMessagesPageResultData, UUID } from '@indigohive/cogfy-types'
import { formatDate } from 'date-fns'
import { useNavigate } from 'react-router-dom'

const TWENTY_FOUR_HOURS_IN_MILLISECONDS = 24 * 60 * 60 * 1000

export type ChatWhatsAppInputProps = {
  messages: GetChatMessagesPageResultData[]
  chatId: UUID
  onSubmitWhatsAppMessage?: () => void
  onScheduleWhatsAppMessage?: () => void
}

export function ChatWhatsAppInput (props: ChatWhatsAppInputProps) {
  const { messages, chatId } = props

  const [value, setValue] = useState('')
  const [scheduleBtnEl, setScheduleBtnEl] = useState<HTMLButtonElement | null>(null)
  const [scheduleDate, setScheduleDate] = useState<Date | null>(null)

  const ref = useRef<HTMLTextAreaElement>(null)
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const navigate = useNavigate()

  const sendWhatsAppMessage = useMutation({
    mutationFn: async () => {
      await cogfy.sendWhatsAppMessage({ chatId, content: value })
    },
    onSuccess: () => {
      props.onSubmitWhatsAppMessage?.()
      setValue('')
    }
  })
  const scheduleWhatsAppMessage = useMutation({
    mutationFn: async () => {
      if (scheduleDate) {
        await cogfy.scheduleWhatsAppMessage({ chatId, content: value, date: scheduleDate })
      }
    },
    onSuccess: () => {
      setValue('')
      setScheduleDate(null)
      setScheduleBtnEl(null)
      navigate('scheduled')
    }
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault()
    sendWhatsAppMessage.mutate()
  }

  useEffect(() => {
    const handler = () => {
      if (ref.current) {
        ref.current.rows = ref.current.value.split('\n').length
      }
    }

    ref.current?.addEventListener('keyup', handler)

    return () => {
      ref.current?.removeEventListener('keyup', handler)
    }
  }, [ref.current])

  const now = new Date()
  const lastUserMessage = messages.find(message => message.role === 'user') // TODO: Improve availabity check
  const whatsAppConversationAvailable = lastUserMessage && (now.getTime() - new Date(lastUserMessage.sendDate).getTime() < TWENTY_FOUR_HOURS_IN_MILLISECONDS)
  const isExceedingConversationWindow = Boolean(lastUserMessage && scheduleDate && (scheduleDate.getTime() > new Date(lastUserMessage.sendDate).getTime() + TWENTY_FOUR_HOURS_IN_MILLISECONDS))
  const submitDisabled = sendWhatsAppMessage.isPending || !value.trim()

  return (
    <div className="flex flex-col w-full max-w-screen-md">
      {whatsAppConversationAvailable && (
        <form
          onSubmit={handleSubmit}
          className="flex items-center w-full rounded-[30px] p-1 border border-neutral-content max-w-screen-md bg-base-100 max-h-[25dvh]"
        >
          <textarea
            ref={ref}
            className="textarea resize-none flex-grow px-6 text-gray-700 bg-transparent border-none focus:outline-none h-full"
            value={value}
            placeholder={t('chatInput:Ask to chat')}
            onChange={event => setValue(event.target.value)}
            rows={1}
            onKeyDown={event => {
              if (event.key === 'Enter' && !event.shiftKey && value.trim()) {
                handleSubmit(event)
              }
            }}
          />
          <Button
            type="submit"
            className="self-end"
            disabled={submitDisabled}
            circle
            ghost
            color="primary"
          >
            <SendHorizontalIcon
              size={24}
              className={clsx(submitDisabled ? 'text-neutral-content' : 'text-primary')}
            />
          </Button>
          <Button
            type="button"
            disabled={submitDisabled}
            circle
            ghost
            color="primary"
            onClick={event => setScheduleBtnEl(event.currentTarget)}
          >
            <ClockIcon
              size={24}
              className={clsx(submitDisabled ? 'text-neutral-content' : 'text-primary')}
            />
          </Button>
        </form>
      )}

      {!whatsAppConversationAvailable && lastUserMessage && (
        <div className="flex flex-row gap-4 items-center bg-[#E2E8F0] rounded-lg p-4">
          <span>
            <CircleAlertIcon size={16} />
          </span>
          <span className="text-[#475569]">
            {t(`chatPage:You can send a message manually up to 24 hours after the recipient's last interaction.
                After this period, the sending window expires, and it will be necessary to wait for a new response to reopen the conversation.`
            )}
          </span>
        </div>
      )}

      <OverlayContent
        open={Boolean(scheduleBtnEl)}
        anchorEl={scheduleBtnEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setScheduleBtnEl(null)}
      >
        <Menu className="p-4 w-96">
          <Input
            size="sm"
            type="datetime-local"
            min={new Date().toISOString().slice(0, 16)}
            value={scheduleDate ? formatDate(scheduleDate, "yyyy-MM-dd'T'HH:mm") : ''}
            helperText={isExceedingConversationWindow ? t('chatPage:Exceeds the 24-hour message sending window') : ''}
            error={isExceedingConversationWindow}
            onChange={event => setScheduleDate(new Date(event.target.value))}
          />
          <Button
            type="button"
            size="sm"
            className="w-full mt-1"
            disabled={isExceedingConversationWindow || !scheduleDate}
            onClick={() => scheduleWhatsAppMessage.mutate()}
          >
            {t('chatPage:Schedule')}
          </Button>
        </Menu>
      </OverlayContent>
    </div>
  )
}
