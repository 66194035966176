import { SignInResult } from '@indigohive/cogfy-types'
import { GetAuthenticatedUserResult } from '@indigohive/cogfy-types/endpoints/getAuthenticatedUser'
import { createContext } from 'react'

export type AuthenticationContextValue = {
  authenticatedUser?: GetAuthenticatedUserResult | null
  onAuthenticatedUserChange?: (user: SignInResult | null) => void
  loading?: boolean
}

export const AuthenticationContext = createContext<AuthenticationContextValue>({})
