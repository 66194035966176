import { GetMembersPageResultData } from '@indigohive/cogfy-types/endpoints/getMembersPage'
import { UUID } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { Dispatch, SetStateAction } from 'react'

export type IndeterminateCheckboxProps = {
  members: GetMembersPageResultData[]
  selections: UUID[]
  setSelections?: Dispatch<SetStateAction<UUID[]>>
}

export function IndeterminateCheckbox (props: IndeterminateCheckboxProps) {
  const { members, selections, setSelections } = props

  return (
    <div className="flex align-center">
      <input
        type="checkbox"
        checked={selections.length > 0}
        className={clsx(
          'checkbox checkbox-sm',
          'indeterminate:checkbox-info',
          'checked:checkbox-info'
        )}
        ref={input => {
          const totalSelected = selections.length
          const total = members.length

          if (input) {
            if (
              totalSelected > 0 &&
              totalSelected !== total
            ) {
              input.indeterminate = true
            } else {
              input.indeterminate = false
            }
          }
        }}
        onChange={() => {
          setSelections?.(prev => {
            const newSelections: UUID[] = []

            if (prev.length === 0) {
              for (const member of members) {
                newSelections.push(member.userId)
              }
            }

            return newSelections
          })
        }}
      />
    </div>
  )
}
