import type { RecalculateStrategy } from '@indigohive/cogfy-types'
import { useQuery } from '@tanstack/react-query'
import { InfoIcon } from 'lucide-react'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { Menu, OverlayContent, Select } from '../../../../components'
import { AutogeneratedForm } from '../../../../components/AutogeneratedForm'
import { useCogfy, useCollections, useOperationSchemas } from '../../../../hooks'
import { CollectionState, useFields, useSelectedFieldToUpdate } from '../../../../lib'
import { CollectionPageController } from '../../collection-page-controller'
import { SearchInput } from '../SearchInput'
import { FilteredMenu } from './subcomponents/OperationsMenu'

const recalculateStrategyOptions: { label: string, value: RecalculateStrategy }[] = [
  { label: 'Always recalculate', value: 'always' },
  { label: 'Only when cell is empty', value: 'empty_only' },
  { label: 'Only manually', value: 'manually' }
]

export type OperationFormProps = {
  controller: CollectionPageController
  state: CollectionState
}

export function OperationForm (props: OperationFormProps) {
  const { controller, state } = props
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const getConnections = useQuery({
    queryKey: ['getConnectionsList', {}] as const,
    queryFn: ({ queryKey, signal }) => cogfy.getConnectionsList(queryKey[1], { signal })
  })
  const getCollections = useCollections({ type: 'database' })
  const connections = getConnections.data?.data ?? []
  const collections = getCollections.data?.data ?? []
  const fields = useFields(state)
  const selectedField = useSelectedFieldToUpdate(state)
  const getOperations = useOperationSchemas(selectedField)
  const operations = getOperations.data?.data
  const operation = operations?.find(op => op.name === selectedField?.operation) ?? null
  const options = useMemo(() => {
    const result: { label: ReactNode, value: string }[] = [
      { label: t('operationForm:Select an operation'), value: '' }
    ]

    if (operations) {
      result.push(...operations?.map(op => ({ label: op.name, value: op.name })))
    }

    return result
  }, [operations])
  const [operationConfig, setOperationConfig] = useState(selectedField?.operationConfig ?? {})
  const [selectOperationEl, setSelectOperationEl] = useState<HTMLDivElement | null>(null)
  const [searchOperationName, setSearchOperationName] = useState('')
  const [menuWidth, setMenuWidth] = useState(0)

  useEffect(() => {
    if (selectOperationEl) {
      setMenuWidth(selectOperationEl.getBoundingClientRect().width)
    }
  }, [selectOperationEl])

  useEffect(() => {
    setOperationConfig(selectedField?.operationConfig ?? {})
  }, [selectedField?.operationConfig])

  const updateOperationConfig = useDebouncedCallback(newOperationConfig => {
    controller.onOperationChange(
      selectedField!.id,
      selectedField?.operation ?? null,
      selectedField!.recalculateStrategy,
      newOperationConfig
    )
  }, 300)

  return (
    <div className="flex flex-col gap-6 text-sm">
      {options.length > 1 && (
        <div>
          <div className="py-1">
            {t('Operation')}
          </div>
          <div className='select w-full border-6 border-black border-opacity-20 btn-sm font-normal justify-between' onClick={(event) => {
            setSelectOperationEl(event.currentTarget)
            setSearchOperationName('')
          }}
          >
            {t(operation?.name ?? 'operationForm:Select an operation')}
          </div>
          <OverlayContent
            open={Boolean(selectOperationEl)}
            anchorEl={selectOperationEl}
            onClose={() => setSelectOperationEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Menu className="max-h-[400px]" style={{ width: `${menuWidth}px` }} maxWidth={false}>
              <SearchInput
                searchName={searchOperationName}
                onNameChange={(name) => { setSearchOperationName(name) }}
              />
              <FilteredMenu
                onChange={event => {
                  controller.onOperationChange(
                    selectedField!.id,
                    event.target.value || null,
                    selectedField!.recalculateStrategy,
                    null
                  )
                }}
                options={options}
                setSelectEl={setSelectOperationEl}
                searchName={searchOperationName}
              />
            </Menu>
          </OverlayContent>
        </div>
      )}
      {operation && (
        <div>
          <div className="flex flex-row items-center gap-1 py-1">
            <span>{t('operationForm:Recalculate operation method')}</span>
            <span
              className="tooltip before:w-[200px] before:content-[attr(data-tip)]"
              data-tip={t('operationForm:How cell recalculates when field source data changes')}
            >
              <InfoIcon size={16} className="text-slate-400" />
            </span>
          </div>
          <Select
            size="sm"
            value={selectedField?.recalculateStrategy ?? recalculateStrategyOptions[0].value}
            onChange={event => {
              controller.onOperationChange(
                selectedField!.id,
                selectedField!.operation ?? null,
                event.target.value as RecalculateStrategy,
                selectedField!.operationConfig ?? null
              )
            }}
            options={recalculateStrategyOptions.map(({ label, value }) => ({ label: t(`operationForm:${label}`), value }))}
          />
        </div>
      )}

      {operation && Object.entries(operation.schema.inputs).length > 0 && (
        <div key={operation.name}>
          <AutogeneratedForm
            schema={operation.schema.inputs}
            fields={fields ?? []}
            connections={connections}
            collections={collections}
            value={operationConfig}
            onChange={value => {
              setOperationConfig(value)
              updateOperationConfig(value)
            }}
          />
        </div>
      )}
    </div>
  )
}
