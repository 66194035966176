import { ChatMiddlewareType } from '@indigohive/cogfy-types'
import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu, OverlayContent } from '../../../../../../components'
import { ChatMiddlewareIcon } from '../../../../../../components/ChatMiddlewareIcon'

export type AddMiddlewareMenuProps = {
  anchorEl?: HTMLElement | null
  onClose: () => void
  onAddMiddlewareClick: (type: ChatMiddlewareType) => void
}

const MIDDLEWARE_OPTIONS: Partial<Record<ChatMiddlewareType, { label: ReactNode }>>[] = [
  {
    reply: { label: 'Reply' },
    replyTranscription: { label: 'Reply transcription' },
    http: { label: 'HTTP' },
    group: { label: 'Group' },
    stop: { label: 'Stop' },
    errorHandler: { label: 'Error handler' }
  },
  {
    generateSpeech: { label: 'Generate speech' },
    transcribeAudio: { label: 'Transcribe audio' }
  },
  {
    instructions: { label: 'Instructions' },
    history: { label: 'History' },
    retrieval: { label: 'Retrieval' },
    scheduleMessage: { label: 'Schedule message' },
    tool: { label: 'Tool' },
    azure: { label: 'Azure' },
    bedrock: { label: 'AWS Bedrock' },
    googleAIStudio: { label: 'Google AI Studio' },
    openai: { label: 'OpenAI' },
    grok: { label: 'Grok' },
    groq: { label: 'Groq' }
  },
  {
    action: { label: 'Action' },
    consensus: { label: 'Consensus' }
  },
  {
    whatsapp: { label: 'WhatsApp' }
  }
]

export function AddChatMiddlewareContextMenu (props: AddMiddlewareMenuProps) {
  const { anchorEl, onClose, onAddMiddlewareClick } = props
  const { t } = useTranslation()

  return (
    <OverlayContent
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ horizontal: 'right' }}
      onClose={onClose}
    >
      <Menu className="max-h-96">
        <li className="menu-title">
          {t('Add middleware')}
        </li>
        {MIDDLEWARE_OPTIONS.map((options, index) => (
          <Fragment key={index}>
            {Object.entries(options).map(([type, option]) => (
              <li key={type} onClick={() => onAddMiddlewareClick(type as ChatMiddlewareType)}>
                <a>
                  <ChatMiddlewareIcon type={type as ChatMiddlewareType} size={16} />
                  {option.label}
                </a>
              </li>
            ))}
            {index < MIDDLEWARE_OPTIONS.length - 1 && (
              <div className="divider my-0 py-0" />
            )}
          </Fragment>
        ))}
      </Menu>
    </OverlayContent>
  )
}
