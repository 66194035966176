import {
  EllipsisIcon,
  LockIcon,
  LockOpenIcon
} from 'lucide-react'
import { Button, Navbar } from '../../../../components'
import { CollectionBreadcrumb, ColletionBreadcrumbData } from '../../components2'

export type CollectionNavbarProps = {
  collection?: ColletionBreadcrumbData
  loading?: boolean
  locked?: boolean | null
  workspace: string
  onMenuClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onShareClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export function CollectionNavbar (props: CollectionNavbarProps) {
  const { collection, locked, loading } = props

  return (
    <Navbar>
      <div className="flex items-center w-full">
        <div className="grow">
          <CollectionBreadcrumb
            workspace={props.workspace}
            collection={collection}
            loading={loading}
          />
        </div>
        <div className="flex items-center gap-2">
          {!loading && (
            <div className="flex items-center gap-2 text-gray-600">
              {locked ? <LockIcon size={16} /> : <LockOpenIcon size={16} />}
            </div>
          )}
          <Button
            ghost
            size="sm"
            onClick={event => props.onShareClick?.(event)}
          >
            Share
          </Button>
          <Button
            ghost
            square
            size="sm"
            disabled={loading}
            onClick={event => props.onMenuClick?.(event)}
          >
            <EllipsisIcon size={16} />
          </Button>
        </div>
      </div>
    </Navbar>
  )
}
