import { UUID } from '@indigohive/cogfy-types'
import { GetWorkspaceBySlugResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceBySlug'
import { GetWorkspacesResult } from '@indigohive/cogfy-types/endpoints/getWorkspaces'
import { Link } from 'react-router-dom'
import { WorkspaceLogo } from '../../../WorkspaceLogo'
import clsx from 'clsx'
import { useMutation } from '@tanstack/react-query'
import { useCogfy } from '../../../../hooks'

export type DrawerWorkspacesPickerProps = {
  workspaces?: GetWorkspacesResult
  currentWorkspace?: GetWorkspaceBySlugResult
  onClose?: () => void
}

export function DrawerWorkspacesPicker (props: DrawerWorkspacesPickerProps) {
  const { workspaces, currentWorkspace, onClose } = props

  const cogfy = useCogfy()
  const registerWorkspaceSelected = useMutation({
    mutationFn: (workspaceId: UUID) => cogfy.userEvent({
      type: 'workspace_selected',
      data: {
        workspaceId,
        from: 'drawer'
      }
    })
  })

  return (
    <div>
      <div className="font-semibold p-5 text-base">
        <span>Workspaces</span>
      </div>
      <div className="overflow-y-auto max-h-[216px]">
        {workspaces?.data.map(workspace => (
          <li key={workspace.id}>
            <Link
              to={`/${workspace.slug}`}
              className="gap-4"
              onClick={() => {
                registerWorkspaceSelected.mutate(workspace.id)
                window.localStorage.setItem('last_workspace_visited', workspace.slug)
                onClose?.()
              }}
            >
              <WorkspaceLogo
                className={clsx(
                  workspace.slug === currentWorkspace?.slug && 'ring-2 ring-primary',
                  'w-8 rounded-lg'
                )}
                name={workspace.name}
                logoUrl={workspace.logoUrl}
              />
              {workspace.name}
            </Link>
          </li>
        ))}
      </div>
    </div>
  )
}
