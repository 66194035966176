import { GetApiKeysPageQuery } from '@indigohive/cogfy-types/endpoints/getApiKeysPage'
import { useQuery } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'

export function useApiKeysPage (params: GetApiKeysPageQuery = {}) {
  const cogfy = useCogfy()

  const getApiKeysPage = useQuery({
    queryKey: ['getApiKeysPage', params],
    queryFn: ({ signal }) => cogfy.getApiKeysPage(params, { signal })
  })

  return getApiKeysPage
}
