import { GetChatMessagesPageResultData } from '@indigohive/cogfy-types'
import { GetAuthenticatedUserResult } from '@indigohive/cogfy-types/endpoints/getAuthenticatedUser'
import { GetChatByIdResult } from '@indigohive/cogfy-types/endpoints/getChatById'
import { isSameDay, parseISO } from 'date-fns'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { WhatsAppBubble } from './WhatsAppBubble'
import { WhatsAppDate } from './WhatsAppDate'

export type WhatsAppMessagesContainerProps = {
  messages: GetChatMessagesPageResultData[]
  chat?: GetChatByIdResult
  authenticatedUser?: GetAuthenticatedUserResult | null
}

export function WhatsAppMessagesContainer (props: WhatsAppMessagesContainerProps) {
  const { messages, chat, authenticatedUser } = props
  const { t } = useTranslation()

  if (!chat) return <></>

  return (
    <>
      {messages.map((message, index) => {
        const prevMessage = messages[index - 1]
        const nextMessage = messages[index + 1]
        const messageSendDate = parseISO(message.sendDate)
        const showDate = !nextMessage || !isSameDay(nextMessage.sendDate, messageSendDate)
        const senderName = getSenderName(t, message)
        const hideAvatar =
          prevMessage &&
          senderName === getSenderName(t, prevMessage) &&
          isSameDay(parseISO(prevMessage.sendDate), messageSendDate)
        const fromAuthenticatedUser = message.user?.id === authenticatedUser?.id

        return (
          <div key={message.id}>
            {showDate && <WhatsAppDate sendDate={message.sendDate} />}

            <WhatsAppBubble
              senderName={senderName ?? ''}
              displayPhoneNumber={message.role === 'user'
                ? chat.data.whatsApp?.contactPhoneNumber
                : undefined
              }
              avatarImageSrc={message.role === 'assistant'
                ? '/cogfy-avatar.png'
                : undefined
              }
              message={message}
              fromAuthenticatedUser={fromAuthenticatedUser}
              hideAvatar={hideAvatar}
            />
          </div>
        )
      })}
    </>
  )
}

function getSenderName (
  translation: TFunction<'translation', undefined>,
  message: GetChatMessagesPageResultData
) {
  if (message.role === 'assistant') {
    return translation('Assistant')
  }

  if (message.role === 'user') {
    return message.user?.name
  }

  return message.user?.name
}
