import { ScheduleRecordProperty } from '@indigohive/cogfy-types'
import { formatDate } from 'date-fns/format'
import { isPast } from 'date-fns/isPast'
import { useEffect, useState } from 'react'
import { Button, Input, Select } from '../../../../components'
import { FieldOverlayProps } from '../../FieldOverlay'
import { isValid } from 'date-fns'

type Unit = 'hours' | 'days' | 'weeks' | 'months'

export function ScheduleOverlay (props: FieldOverlayProps) {
  const { record, field } = props
  const property = record.properties[field.id] as ScheduleRecordProperty | undefined
  const value = property?.schedule?.value

  const [startDateInput, setStartDateInput] = useState<string | null>(
    value?.startDate ? formatDate(new Date(value.startDate), "yyyy-MM-dd'T'HH:mm") : null
  )
  const startDateInputDate = startDateInput ? new Date(startDateInput) : null

  const [interval, setInterval] = useState<number | null>(value?.interval ?? null)
  const [unit, setUnit] = useState<Unit | null>(value?.unit as Unit ?? null)

  const startDateError = startDateInputDate
    ? (isValid(startDateInputDate) && isPast(startDateInputDate))
    : false
  const intervalError = interval !== null && (interval < 1 || interval > 99)

  useEffect(() => {
    if (startDateInput && interval && unit && !startDateError && !intervalError) {
      const startDate = new Date(startDateInput)

      if (isValid(startDate)) {
        props.controller.onUpdateRecordProperties(
          props.record.id,
          {
            [props.field.id]: {
              type: 'schedule',
              schedule: {
                value: {
                  startDate: startDate.toISOString(),
                  nextDate: startDate.toISOString(),
                  interval,
                  unit
                }
              }
            }
          }
        )
      }
    }
  }, [startDateInput, interval, unit, startDateError, intervalError])

  const handleClear = () => {
    setStartDateInput(null)
    setInterval(null)
    setUnit(null)
    props.controller.onUpdateRecordProperties(
      props.record.id,
      {
        [props.field.id]: {
          type: 'schedule',
          schedule: {
            value: null
          }
        }
      }
    )
  }

  return (
    <div
      className="menu-sm bg-base-100 rounded-btn border shadow-lg max-w-full flex flex-col gap-2 px-2 py-2"
      style={{ width: 200 }}
    >
      <Input
        size="sm"
        error={startDateError}
        helperText={startDateError ? 'Start date must be in the future' : ''}
        value={startDateInput ?? ''}
        onChange={event => {
          setStartDateInput(event.target.value)
        }}
        label="Start date"
        type="datetime-local"
      />
      <Input
        size="sm"
        value={interval ?? ''}
        error={intervalError}
        helperText={intervalError ? 'Interval must be between 1 and 99' : undefined}
        onChange={event => {
          setInterval(Number(event.target.value) || null)
        }}
        label="Interval"
        type="number"
        min={1}
        max={99}
      />
      <Select
        size="sm"
        value={unit || ''}
        onChange={event => {
          setUnit(event.target.value as Unit)
        }}
        options={[
          { label: '-', value: '' },
          { label: 'Hours', value: 'hours' },
          { label: 'Days', value: 'days' },
          { label: 'Weeks', value: 'weeks' },
          { label: 'Months', value: 'months' }
        ]}
      />
      <Button
        size="xs"
        onClick={() => handleClear()}
      >
        Clear
      </Button>
    </div>
  )
}
