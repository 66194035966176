import { CogfyClient } from '@indigohive/cogfy-api-client'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import './i18n'
import {
  CollectionPageV2,
  CollectionsIndexPage,
  CollectionTemplatesPage,
  CreateWebhookPage,
  ForgotPasswordPage,
  RecordPage,
  ResetPasswordPage,
  RootPage,
  ScheduledMessagesPage,
  SignInPage,
  TotpSetupPage,
  TotpSignInPage,
  UserGroupsIndexPage,
  WebhooksIndexPage,
  WorkspaceAssistantPage,
  WorkspaceConnectionsPage,
  WorkspaceMembersPage,
  WorkspaceSettingsPage
} from './pages'
import { Layout } from './components/Layout'
import { ChatPage } from './pages/ChatPage'
import { AuthenticationProvider, AuthorizationProvider, ToastsProvider, DrawerProvider, PostHogPageViewTracker } from './components'
import { ApiKeysIndexPage } from './pages/ApiKeysIndexPage'
import { CreateApiKeyPage } from './pages/CreateApiKeyPage'
import { AppManagerProvider, CogfyClientProvider, OverlayProvider } from './contexts'
import { AppManager } from './lib'
import { PropsWithChildren } from 'react'
import { SettingsLayout } from './components/SettingsLayout/SettingsLayout'

if (import.meta.env.VITE_POSTHOG_API_KEY) {
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY,
    {
      api_host: import.meta.env.VITE_POSTHOG_API_URL,
      capture_pageview: false,
      loaded: posthog => {
        if (!import.meta.env.PROD) {
          posthog.opt_out_capturing()
        }
      }
    }
  )
}

const cogfyClient = new CogfyClient({ baseURL: import.meta.env.VITE_COGFY_INTERNAL_API_URL })
const appManager = new AppManager({
  cogfyApiClient: cogfyClient,
  debug: import.meta.env.DEV
})

function Providers (props: PropsWithChildren) {
  return (
    <CogfyClientProvider client={cogfyClient}>
      <AuthenticationProvider>
        <AuthorizationProvider>
          <AppManagerProvider manager={appManager}>
            <OverlayProvider>
              <DrawerProvider>
                <PostHogPageViewTracker />
                {props.children}
              </DrawerProvider>
            </OverlayProvider>
          </AppManagerProvider>
        </AuthorizationProvider>
      </AuthenticationProvider>
    </CogfyClientProvider>
  )
}

export function App () {
  return (
    <PostHogProvider client={posthog}>
      <DndProvider backend={HTML5Backend}>
        <ToastsProvider>
          <RouterProvider
            router={createBrowserRouter([
              {
                path: '/',
                element: (
                  <Providers>
                    <Outlet />
                  </Providers>
                ),
                children: [
                  {
                    path: ':workspace/*',
                    element: <Layout />,
                    children: [
                      { path: '', element: <CollectionsIndexPage /> },
                      { path: ':collectionId', element: <CollectionPageV2 /> },
                      { path: ':collectionId/:recordId', element: <RecordPage /> },
                      { path: 'chats/:chatId', element: <ChatPage /> },
                      { path: 'chats/:chatId/scheduled', element: <ScheduledMessagesPage /> },
                      {
                        path: '',
                        element: <SettingsLayout />,
                        children: [
                          { path: 'api-keys', element: <ApiKeysIndexPage /> },
                          { path: 'api-keys/create', element: <CreateApiKeyPage /> },
                          { path: 'connections', element: <WorkspaceConnectionsPage /> },
                          { path: 'groups', element: <UserGroupsIndexPage /> },
                          { path: 'assistant', element: <WorkspaceAssistantPage /> },
                          { path: 'members', element: <WorkspaceMembersPage /> },
                          { path: 'settings', element: <WorkspaceSettingsPage /> },
                          { path: 'webhooks', element: <WebhooksIndexPage /> },
                          { path: 'webhooks/create', element: <CreateWebhookPage /> }
                        ]
                      },
                      { path: 'templates', element: <CollectionTemplatesPage /> }
                    ]
                  },
                  { path: '/', element: <RootPage /> },
                  { path: '/sign-in', element: <SignInPage /> },
                  { path: '/forgot-password', element: <ForgotPasswordPage /> },
                  { path: '/reset-password', element: <ResetPasswordPage /> },
                  { path: '/totp-setup', element: <TotpSetupPage /> },
                  { path: '/totp-sign-in', element: <TotpSignInPage /> }
                ]
              }
            ])}
          />
        </ToastsProvider>
      </DndProvider>
    </PostHogProvider>
  )
}
