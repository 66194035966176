import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatMiddlewareDataFormProps } from '../../ChatMiddlewareDataFormProps'
import { Input } from '../../../Input'
import { Select } from '../../../Select'
import { ToolFormCreateCogfyRecord } from './_ToolFormCreateCogfyRecord'
import { ToolFormGetGoogleAuthUrl } from './_ToolFormGetGoogleAuthUrl'
import { ToolFormHttpRequest } from './_ToolFormHttpRequest'
import { ToolFormSearchCogfyRecords } from './_ToolFormSearchCogfyRecords'
import { ToolFormSendCogfyRecordFile } from './_ToolFormSendCogfyRecordFile'
import { ToolFormUpdateCogfyRecord } from './_ToolFormUpdateCogfyRecord'
import { useCogfy, useCollections } from '../../../../hooks'
import { AutogeneratedForm } from '../../../AutogeneratedForm'

export const nameValidation = yup
  .string()
  .matches(/^[a-zA-Z0-9_-]+$/)

const TOOL_FORMS_BY_TYPE: Record<string, typeof ToolFormCreateCogfyRecord> = {
  createCogfyRecord: ToolFormCreateCogfyRecord,
  getGoogleCalendarAuthUrl: ToolFormGetGoogleAuthUrl,
  httpRequest: ToolFormHttpRequest,
  searchCogfyRecords: ToolFormSearchCogfyRecords,
  sendCogfyRecordFile: ToolFormSendCogfyRecordFile,
  updateCogfyRecord: ToolFormUpdateCogfyRecord
}

export function ToolForm (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props

  const { t } = useTranslation()
  const cogfy = useCogfy()
  const [name, setName] = useState(data.name ?? '')
  const [description, setDescription] = useState(data.description ?? '')

  const getChatToolSchemas = useQuery({
    queryKey: ['getChatToolSchemas'],
    queryFn: ({ signal }) => cogfy.getChatToolSchemas({ signal })
  })
  const getConnections = useQuery({
    queryKey: ['getConnectionsList', {}] as const,
    queryFn: ({ queryKey, signal }) => cogfy.getConnectionsList(queryKey[1], { signal })
  })
  const getCollections = useCollections({ type: 'database' })
  const connections = getConnections.data?.data ?? []
  const collections = getCollections.data?.data ?? []
  const isNameValid = nameValidation.isValidSync(name)

  const toolNameOptions = [
    { label: 'Select a tool', value: '' },
    ...(getChatToolSchemas.data?.data.map(tool => ({ label: tool.name, value: tool.name })) ?? [])
  ]

  // v2
  const [toolName, setToolName] = useState<string | null>(data.method?.name ?? null)
  const [toolData, setToolData] = useState<Record<string, any> | null>(data.method?.data ?? null)
  const toolSchema = getChatToolSchemas.data?.data.find(schema => schema.name === toolName)

  const ToolFormComponent = toolName ? TOOL_FORMS_BY_TYPE[toolName] : undefined

  return (
    <div className="flex flex-col gap-2">
      <Input
        label={t('editFieldDrawer:Tool name')}
        size="sm"
        value={name}
        onChange={event => {
          const newName = event.target.value
          setName(newName)
          if (nameValidation.isValidSync(newName)) onChange({ ...props.data, name: newName })
        }}
        error={!isNameValid && name.length > 0}
        helperText={!isNameValid && name.length > 0 ? t('editFieldDrawer:Name must contain only letters, numbers, _ or -') : ''}
      />

      <Input
        label={t('editFieldDrawer:Tool description')}
        size="sm"
        value={description}
        onChange={event => {
          setDescription(event.target.value)
          onChange({ ...props.data, description: event.target.value })
        }}
      />

      <Select
        label={t('editFieldDrawer:Tool method')}
        size="sm"
        options={toolNameOptions}
        value={toolName ?? ''}
        onChange={event => {
          const name = event.target.value

          if (name) {
            setToolName(name)
            onChange({ ...props.data, method: { name, data: {} } })
          } else {
            setToolName(null)
            onChange({ ...props.data, method: {} })
          }
        }}
      />

      {toolData && toolSchema?.schema && (
        <AutogeneratedForm
          schema={toolSchema.schema.inputs ?? {}}
          value={toolData}
          onChange={value => {
            setToolData(value)
            onChange({
              ...props.data,
              method: {
                name: toolName,
                data: value
              }
            })
          }}
          connections={connections}
          collections={collections}
          fields={props.fields ?? []}
        />
      )}
      {(!toolSchema?.schema) && ToolFormComponent && (
        <ToolFormComponent {...props} />
      )}
    </div>
  )
}
