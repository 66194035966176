import { ChatMessageRole, UUID } from '@indigohive/cogfy-types'
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'
import { useRoom } from './use-room'

const getChatMessagesPageQuery = {
  pageSize: 100,
  roles: ['user', 'assistant', 'tool'] as ChatMessageRole[]
}

export function useChatMessagesPage (
  chatId: string
) {
  const cogfy = useCogfy()
  useRoom(`chat:${chatId}`)

  const queryKey = ['getChatMessagesPage', chatId, getChatMessagesPageQuery]

  const getChatMessagesPage = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam, signal }) => cogfy.getChatMessagesPage(
      { ...getChatMessagesPageQuery, pageNumber: pageParam, chatId: chatId as UUID },
      { signal }
    ),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.pageNumber + 1

      return nextPage * lastPage.pageSize < lastPage.totalSize
        ? nextPage
        : undefined
    },
    initialPageParam: 0,
    enabled: Boolean(chatId),
    placeholderData: keepPreviousData
  })

  return getChatMessagesPage
}
