import { GetMembersPageResult, GetMembersPageResultData } from '@indigohive/cogfy-types/endpoints/getMembersPage'
import { GetAuthenticatedUserResult } from '@indigohive/cogfy-types/endpoints/getAuthenticatedUser'
import { MemberRole, UUID } from '@indigohive/cogfy-types'
import { Skeleton } from '../Skeleton'
import { Dispatch, Fragment, SetStateAction } from 'react'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'
import clsx from 'clsx'
import { MemberView } from '../MemberView'
import { MemberRoleSelect } from './MemberRoleSelect'
import { useTranslation } from 'react-i18next'
import { useWorkspaceSlug } from '../../hooks'
import { FileUser, Mail, Trash2, User, Users } from 'lucide-react'
import { Button } from '../Button'

const headers = [
  {
    title: 'User',
    icon: User
  },
  {
    title: 'Email',
    icon: Mail
  },
  {
    title: 'Role',
    icon: FileUser
  },
  {
    title: 'Groups',
    icon: Users
  }
]

export type MembersTableViewProps = {
  getMembers: GetMembersPageResult | undefined
  loading: boolean
  selections: UUID[]
  user?: GetAuthenticatedUserResult | null
  memberIsAdmin?: boolean
  setSelections?: Dispatch<SetStateAction<UUID[]>>
  onDeleteRow: (member: GetMembersPageResultData) => void
  onUpdateMemberRole?: (userId: UUID, role: MemberRole) => void
}

export function MembersTableView (props: MembersTableViewProps) {
  const { getMembers, loading, selections, user, memberIsAdmin = false, setSelections, onDeleteRow, onUpdateMemberRole } = props

  const handleSelectMember = (userId: UUID, checked: boolean) => {
    if (checked) {
      setSelections?.(prev => [...prev, userId])
    } else {
      setSelections?.(prev => prev.filter(id => id !== userId))
    }
  }
  const workspace = useWorkspaceSlug()

  const userMember = getMembers?.data.find(member => member.userId === user?.id)

  const { t } = useTranslation()

  const selectionsLength = selections.length

  return (
    <div className="overflow-x-auto rounded-lg my-4">
      <table
        className="table"
        style={{ borderCollapse: 'separate', borderSpacing: 0 }}
      >
        <thead className='bg-background-default'>
          <tr>
            {getMembers?.data && setSelections && memberIsAdmin && (
              <th className="w-4 group/checkbox-th">
                <IndeterminateCheckbox
                  members={getMembers.data.filter(member => member.userId !== user?.id)}
                  selections={selections}
                  setSelections={setSelections}
                />
              </th>
            )}
            {headers.map(header => (
              <th key={header.title} className="text-left">
                <div className='settings-table-header-element'>
                  <header.icon size={16} />
                  {t(header.title)}
                </div>
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {loading &&
            new Array(5).fill(null).map((_, index) => (
              <tr key={index}>
                {new Array(5).fill(null).map((_, index) => (
                  <td key={index}>
                    <Skeleton className="w-full h-4" />
                  </td>
                ))}
              </tr>
            ))}
          {!loading && !getMembers && (
            <tr>
              <td colSpan={1000}>{t('Error when searching members')}</td>
            </tr>
          )}
          {!loading && getMembers && getMembers.data.length === 0 && (
            <tr>
              <td colSpan={1000}>{t('No members found')}</td>
            </tr>
          )}
          {!loading && (
            <>
              {userMember &&
                <>
                  <tr>
                    {memberIsAdmin &&
                      <td className="group/item cursor-not-allowed">
                        <div className="flex align-center">
                          <input
                            disabled
                            type="checkbox"
                            className={clsx(
                              'checkbox',
                              'checkbox-sm',
                              'checked:checkbox-info',
                              'h-full'
                            )}
                          />
                        </div>
                      </td>}

                    <MemberView name={`${userMember.name} (${t('You')})`} email={userMember.email} />
                    <MemberRoleValue role={userMember.role} />

                    <td>
                      {(!userMember.userGroups || (userMember.userGroups?.length === 0)) && '-'}
                      <div className="flex gap-1">
                        {userMember.userGroups?.slice(0, 2).map(userGroup => (
                          <Button
                            key={userGroup.id}
                            size='xs'
                            color='neutral'
                            className="whitespace-nowrap"
                            to={`/${workspace}/groups/${userGroup.id}`}
                          >
                            {userGroup.name?.trim() || t('Unnamed')}
                          </Button>
                        ))}
                        {userMember.userGroups && userMember.userGroups.length > 2 && (
                          <div className='bg-background-default border border-border-default rounded-lg min-w-8 h-6 flex items-center justify-center'>
                            <span>+{userMember.userGroups.length - 2}</span>
                          </div>
                        )}
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td colSpan={1000} className='p-0'>
                      <div className='divider my-0' />
                    </td>
                  </tr>
                </>
              }
              {getMembers?.data.filter(member => member.userId !== user?.id).map(member => (
                <Fragment key={member.userId}>
                  <tr key={member.userId}>
                    {memberIsAdmin && (
                      <td className="group/item h-16 w-full flex p-0">
                        <label className="cursor-pointer h-full w-full flex justify-center items-center">
                          <input
                            type="checkbox"
                            className={clsx(
                              'checkbox',
                              'checkbox-sm',
                              'checked:checkbox-info',
                              'h-full'
                            )}
                            onChange={event => handleSelectMember(member.userId, event.target.checked)}
                            checked={selections.some(userId => userId === member.userId)}
                          />
                        </label>
                      </td>
                    )}

                    <MemberView name={member.name} email={member.email} />

                    {memberIsAdmin && (
                      <td>
                        <MemberRoleSelect
                          onChange={(role) => onUpdateMemberRole?.(member.userId, role)}
                          selectedRole={member.role}
                        />
                      </td>
                    )}
                    {!memberIsAdmin && (
                      <MemberRoleValue role={member.role} />
                    )}

                    <td>
                      {(!member.userGroups || (member.userGroups?.length === 0)) && '-'}
                      <div className="flex gap-1">
                        {member.userGroups?.slice(0, 2).map(userGroup => (
                          <Button
                            key={userGroup.id}
                            size='xs'
                            color='neutral'
                            className="whitespace-nowrap"
                            to={`/${workspace}/groups/${userGroup.id}`}
                          >
                            {userGroup.name?.trim() || t('Unnamed')}
                          </Button>
                        ))}
                        {member.userGroups && member.userGroups.length > 2 && (
                          <div className='bg-background-default border border-border-default rounded-lg min-w-8 h-6 flex items-center justify-center'>
                            <span>+{member.userGroups.length - 2}</span>
                          </div>
                        )}
                      </div>
                    </td>
                    {memberIsAdmin && (
                      <td className="w-[80px]">
                        <button
                          className={`${!(selectionsLength > 1)
                            ? 'hover:border hover:rounded-lg hover:bg-background-default'
                            : 'cursor-not-allowed'}
                     w-7 h-7 flex items-center justify-center`}
                          disabled={selectionsLength > 1}
                          onClick={() => onDeleteRow(member)}
                        >
                          <Trash2 size={16} />
                        </button>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td colSpan={1000} className='p-0'>
                      <div className='divider my-0' />
                    </td>
                  </tr>
                </Fragment>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}

const MemberRoleValue = (props: { role: string }) => {
  return (
    <td>
      <span className="text-xs">{props.role.charAt(0).toUpperCase() + props.role.slice(1)}</span>
    </td>
  )
}
