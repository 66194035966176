import { useQuery } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'
import { GetCollectionTemplatesPageQuery } from '@indigohive/cogfy-types/endpoints/getCollectionTemplatesPage'

export function useCollectionTemplatesPage (query: GetCollectionTemplatesPageQuery) {
  const cogfy = useCogfy()

  const getCollectionTemplatesPage = useQuery({
    queryKey: ['getCollectionTemplatesPage', query],
    queryFn: ({ signal }) => cogfy.getCollectionTemplatesPage(query, { signal })
  })

  return getCollectionTemplatesPage
}
